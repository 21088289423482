var lang_es = lang_es || {};
lang_es['Content.Btn.AcceptSelected'] = 'Aceptar Seleccionados';
lang_es['Content.Btn.ActivateFreeAccount'] = 'Activar Mi Cuenta Gratuita';
lang_es['Content.Btn.Add'] = 'Agregar';
lang_es['Content.Btn.Allow'] = 'Permitir';
lang_es['Content.Btn.AllowAndApprove'] = 'Permitir y Aprobar';
lang_es['Content.Btn.AllowDenyDisabledAutoClockOut'] = 'Los botones de Permitir y Denegar se encuentran deshabilitados debido a la salida marcada automáticamente';
lang_es['Content.Btn.AllowDenyDisabledInEdit'] = 'Los botones de Permitir y Denegar permanecen deshabilitados durante una edición';
lang_es['Content.Btn.AllowDenyDisabledPermission'] = 'Permiso para botones de Permitir y Denegar deshabilitados para esta cuenta';
lang_es['Content.Btn.AllowSelected'] = 'Permitir Seleccionados';
lang_es['Content.Btn.Annotate'] = 'Anotar';
lang_es['Content.Btn.Apply'] = 'Aplicar';
lang_es['Content.Btn.ApplyRates'] = 'Aplicar Tarifas';
lang_es['Content.Btn.Approve'] = 'Aprobar';
lang_es['Content.Btn.ApproveSelected'] = 'Aprobar Seleccionados';
lang_es['Content.Btn.Archive'] = 'Archivar';
lang_es['Content.Btn.ArchiveSelected'] = 'Archivar Seleccionados';
lang_es['Content.Btn.ArchiveUser'] = 'Archivar Usuario';
lang_es['Content.Btn.ArchiveUsers'] = 'Archivar Usuarios';
lang_es['Content.Btn.BackToAccount'] = 'Volver a la Cuenta';
lang_es['Content.Btn.BackToMenu'] = 'Volver al Menú';
lang_es['Content.Btn.Browse'] = 'Navegar';
lang_es['Content.Btn.Cancel'] = 'Cancelar';
lang_es['Content.Btn.CancelAccount'] = 'Cancelar Cuenta';
lang_es['Content.Btn.CancelDeleteAccount'] = 'Cancelar y Eliminar Cuenta';
lang_es['Content.Btn.Clear'] = 'Despejar';
lang_es['Content.Btn.ClearRecentlyModified'] = 'Limpiar Recientemente Modificados';
lang_es['Content.Btn.ClockIn'] = 'Marcar Entrada';
lang_es['Content.Btn.ClockInOnlyBetween'] = 'Marcar Entrada solo se permite entre';
lang_es['Content.Btn.ClockInOutNotAtLocation'] = 'Marcar Entrada/Salida no está permitido en esta ubicación';
lang_es['Content.Btn.ClockInOutNotWithPhoto'] = 'Marcar Entrada/Salida no está permitido porque la seguridad de foto está habilitada para su cuenta';
lang_es['Content.Btn.ClockOut'] = 'Marcar Salida';
lang_es['Content.Btn.Close'] = 'Cerrar';
lang_es['Content.Btn.CompanyWall'] = 'Muro de la Compañia';
lang_es['Content.Btn.CompanyWallUpdate'] = 'Actualizar';
lang_es['Content.Btn.ConfirmAndPay'] = 'Confirmar y Pagar';
lang_es['Content.Btn.Continue'] = 'Continuar';
lang_es['Content.Btn.ContinueAndReadLater'] = 'Continuar y Leer Más Tarde';
lang_es['Content.Btn.ContinueSelectOptions'] = 'Continuar para Seleccionar Opciones';
lang_es['Content.Btn.ContinueToCheckout'] = 'Continuar a Pago';
lang_es['Content.Btn.Copy'] = 'Copiar';
lang_es['Content.Btn.CopyRateToAll'] = 'Copiar Tarifa a Todos';
lang_es['Content.Btn.CopyThisWeekToNextWeek'] = 'Copiar Esta Semana a la Próxima Semana';
lang_es['Content.Btn.CopyWithDefaultValues'] = 'Copiar Con Valores Predeterminados';
lang_es['Content.Btn.CreateNewPassword'] = 'Crear Nueva Contraseña';
lang_es['Content.Btn.Crop'] = 'Recortar';
lang_es['Content.Btn.DeclineSelected'] = 'Rechazar Seleccionados';
lang_es['Content.Btn.DefaultRates'] = 'Tarifas Predeterminadas';
lang_es['Content.Btn.Delete'] = 'Eliminar';
lang_es['Content.Btn.DeleteCurrentPhoto'] = 'Borrar Foto Actual';
lang_es['Content.Btn.DeleteKeys'] = 'Eliminar Claves';
lang_es['Content.Btn.DeleteSelected'] = 'Eliminar Seleccionados';
lang_es['Content.Btn.Deny'] = 'Denegar';
lang_es['Content.Btn.DenySelected'] = 'Denegar Seleccionados';
lang_es['Content.Btn.DisableCutOff'] = 'Deshabilitar Corte';
lang_es['Content.Btn.DisableLogin'] = 'Deshabilitar Inicio de Sesión';
lang_es['Content.Btn.DisconnectQBO'] = 'Desconectarse de QuickBooks Online';
lang_es['Content.Btn.DismissAnnouncement'] = 'Descartar Anuncio';
lang_es['Content.Btn.DoNotCopy'] = 'No Copiar';
lang_es['Content.Btn.Download'] = 'Descargar';
lang_es['Content.Btn.EnableCutOff'] = 'Habilitar Corte';
lang_es['Content.Btn.EnableLogin'] = 'Habilitar Inicio de Sesión';
lang_es['Content.Btn.ExpenseEntry'] = 'Ingreso de Gastos';
lang_es['Content.Btn.ExportSelected'] = 'Exportar Seleccionados';
lang_es['Content.Btn.FilterByDate'] = 'Filtrar por Fecha';
lang_es['Content.Btn.FinishRegistration'] = 'Finalizar Registro';
lang_es['Content.Btn.GenerateAPIKeys'] = 'Generar Clave de API';
lang_es['Content.Btn.GenerateAuthToken'] = 'Generar Token de Autenticación';
lang_es['Content.Btn.GenerateCredentials'] = 'Generar Credenciales';
lang_es['Content.Btn.GetStarted'] = 'Iniciar';
lang_es['Content.Btn.Go'] = 'Ir';
lang_es['Content.Btn.HideWall'] = 'Ocultar Muro';
lang_es['Content.Btn.History'] = 'Historial';
lang_es['Content.Btn.HourlyEntry'] = 'Ingreso de Tiempo por Hora';
lang_es['Content.Btn.HourlyTime'] = 'Tiempo por Hora';
lang_es['Content.Btn.Import'] = 'Importar';
lang_es['Content.Btn.Landscape'] = 'Paisaje';
lang_es['Content.Btn.Login'] = 'Iniciar Sesión';
lang_es['Content.Btn.LoginAgain'] = 'Iniciar Sesión nuevamente';
lang_es['Content.Btn.LoginAsX'] = 'Iniciar Sesión como {1}';
lang_es['Content.Btn.Logout'] = 'Cerrar Sesión';
lang_es['Content.Btn.LogoutOnAllDevices'] = 'Cerrar Sesión en Todos los Dispositivos';
lang_es['Content.Btn.MarkAllAsRead'] = 'Marcar Todo como Leido';
lang_es['Content.Btn.MarkAsBillable'] = 'Marcar como Facturable';
lang_es['Content.Btn.MarkAsUnbillable'] = 'Marcar como No Facturable';
lang_es['Content.Btn.MarkSelectedAsBillable'] = 'Marcar Seleccionados como Facturables';
lang_es['Content.Btn.MarkSelectedAsUnbillable'] = 'Marcar Seleccionados como No Facturables';
lang_es['Content.Btn.Merge'] = 'Combinar';
lang_es['Content.Btn.Next'] = 'Siguiente';
lang_es['Content.Btn.No'] = 'No';
lang_es['Content.Btn.NotifySupervisorOfClosedPayrollPeriod'] = 'Notificar a mi Supervisor que He Cerrado un Periodo de Nómina';
lang_es['Content.Btn.NotifySupervisorOfRecordsApproved'] = 'Notificar a mi Supervisor que He Aprobado Horas';
lang_es['Content.Btn.NotifySupervisorOfRecordsArchived'] = 'Notificar a mi Supervisor que he Archivado Horas';
lang_es['Content.Btn.NotifySupervisorOfRecordsOpened'] = 'Notificar a mi Supervisor que He Abierto Gastos';
lang_es['Content.Btn.NotifySupervisorOfRecordsReconciled'] = 'Notificar a mi Supervisor que He Conciliado Gastos';
lang_es['Content.Btn.NotifySupervisorTimesheetIsReady'] = 'Notificar a mi Supervisor que Mi Hoja de Tiempo Está Lista';
lang_es['Content.Btn.Okay'] = 'Ok';
lang_es['Content.Btn.Open'] = 'Abrir';
lang_es['Content.Btn.OpenFile'] = 'Abrir documento';
lang_es['Content.Btn.OpenSelected'] = 'Abrir Seleccionados';
lang_es['Content.Btn.PartnerLogin'] = 'Inicio de Sesión de Socio';
lang_es['Content.Btn.Pause'] = 'Pausa';
lang_es['Content.Btn.PauseAllTimers'] = 'Pausar Todos los Temporizadores';
lang_es['Content.Btn.PermanentlyDeleteSelected'] = 'Borrar Elementos Seleccionados Permanentemente';
lang_es['Content.Btn.Portrait'] = 'Retrato';
lang_es['Content.Btn.Previous'] = 'Anterior';
lang_es['Content.Btn.Print'] = 'Imprimir';
lang_es['Content.Btn.Print.ExtendedData'] = 'Información extendida';
lang_es['Content.Btn.Print.Legend'] = 'Leyenda';
lang_es['Content.Btn.Print.Notes'] = 'Notas';
lang_es['Content.Btn.Print.PageBreaks'] = 'Saltos de Página';
lang_es['Content.Btn.Print.PrintPDF'] = 'Imprimir / PDF';
lang_es['Content.Btn.Print.PrintPDFOptions'] = 'Impresión / Opciones de PDF';
lang_es['Content.Btn.Print.PrintSize'] = 'Tamaño de impresión';
lang_es['Content.Btn.Print.SignatureBlocks'] = 'Espacios de Firma';
lang_es['Content.Btn.ProjectEntry'] = 'Ingreso de Proyecto';
lang_es['Content.Btn.ProjectTimers'] = 'Temporizadores de Proyecto';
lang_es['Content.Btn.Publish'] = 'Publicar';
lang_es['Content.Btn.Reconcile'] = 'Conciliar';
lang_es['Content.Btn.ReconcileSelected'] = 'Conciliar Seleccionados';
lang_es['Content.Btn.Refresh'] = 'Actualizar';
lang_es['Content.Btn.RemoveCode'] = 'Remover Código';
lang_es['Content.Btn.Reset'] = 'Restablecer';
lang_es['Content.Btn.ResetPWsResendEmail'] = 'Reenviar Correo Electrónico';
lang_es['Content.Btn.Restart'] = 'Reiniciar';
lang_es['Content.Btn.Restore'] = 'Restaurar';
lang_es['Content.Btn.RestoreSelected'] = 'Restaurar Seleccionados';
lang_es['Content.Btn.ReturnToHomepage'] = 'Regresar a la Página Principal';
lang_es['Content.Btn.ReturnToLogin'] = 'Volver a Inicio de Sesión';
lang_es['Content.Btn.Save'] = 'Guardar';
lang_es['Content.Btn.SaveAndContinue'] = 'Guardar y Continuar';
lang_es['Content.Btn.SaveChanges'] = 'Guardar Cambios';
lang_es['Content.Btn.SaveClosePayrollReport'] = 'Guardar y Cerrar el Reporte de Nómina';
lang_es['Content.Btn.SaveNote'] = 'Guardar Nota';
lang_es['Content.Btn.Schedules'] = 'Horarios';
lang_es['Content.Btn.Search'] = 'Buscar';
lang_es['Content.Btn.SelectAll'] = 'Seleccionar Todos';
lang_es['Content.Btn.Send'] = 'Enviar';
lang_es['Content.Btn.SendReferral'] = 'Enviar Referencia';
lang_es['Content.Btn.SetTimeEntryCutoffToX'] = 'Establecer el Corte de Ingreso a {1}';
lang_es['Content.Btn.SignSelectedRecords'] = 'Firmar Registros Seleccionados';
lang_es['Content.Btn.StartAllTimers'] = 'Iniciar Todos los Temporizadores';
lang_es['Content.Btn.StartNewTimer'] = 'Iniciar Nuevo Temporizador';
lang_es['Content.Btn.Stop'] = 'Detener';
lang_es['Content.Btn.StopAllTimers'] = 'Detener Todos los Temporizadores';
lang_es['Content.Btn.Submit'] = 'Enviar';
lang_es['Content.Btn.TakePhoto'] = 'Tomar Foto';
lang_es['Content.Btn.TimetoDecimal'] = 'Alternar Formato de Hora';
lang_es['Content.Btn.Unapprove'] = 'Desaprobar';
lang_es['Content.Btn.UnapproveSelected'] = 'Desaprobar Seleccionados';
lang_es['Content.Btn.Unarchive'] = 'Desarchivar';
lang_es['Content.Btn.UnarchiveSelected'] = 'Desarchivar Seleccionados';
lang_es['Content.Btn.Unpublish'] = 'Anular Publicación';
lang_es['Content.Btn.Update'] = 'Actualizar';
lang_es['Content.Btn.UpdateContinueAccount'] = 'Actualizar y Continuar a Mi Cuenta';
lang_es['Content.Btn.UpdatePayrollReport'] = 'Actualizar Reporte de Nómina';
lang_es['Content.Btn.UpdateView'] = 'Actualizar Vista';
lang_es['Content.Btn.Upload'] = 'Subir';
lang_es['Content.Btn.UserRatesIndividualFiles'] = 'Tarifas de Usuario (Archivos Individuales)';
lang_es['Content.Btn.UserRatesSingleFile'] = 'Tarifas de Usuario (Único Archivo)';
lang_es['Content.Btn.View'] = 'Ver';
lang_es['Content.Btn.XMinBreak'] = 'Descanso de {1} mins.';
lang_es['Content.Btn.Yes'] = 'Sí';
lang_es['Content.Checkbox.AddNew'] = 'Agregar Nuevo';
lang_es['Content.Checkbox.AllUsersWhereApplicable'] = 'Aplicar la configuración seleccionada a todos los usuarios donde corresponda';
lang_es['Content.Checkbox.AllowAccountCodeToBeUsedByAnyEmployee'] = 'Permitir que este Código de Cuenta sea utilizado por cualquier Usuario';
lang_es['Content.Checkbox.AllowAllUserToPostToCompanyMessageBoard'] = 'Permitir que todos los usuarios publiquen en el tablero de mensajes';
lang_es['Content.Checkbox.AllowCustomerToBeUsedByAnyEmployee'] = 'Permitir que este Cliente sea utilizado por cualquier Usuario';
lang_es['Content.Checkbox.AllowCustomerToBeUsedWithAnyProject'] = 'Permitir que este Cliente sea utilizado con cualquier Proyecto';
lang_es['Content.Checkbox.AllowProjectToBeUsedByAnyEmployee'] = 'Permitir que este Proyecto sea utilizado por cualquier Usuario';
lang_es['Content.Checkbox.AllowProjectToBeUsedWithAnyAssignedCustomer'] = 'Permitir que este Proyecto sea utilizado con cualquier Cliente asignado a mi';
lang_es['Content.Checkbox.AllowProjectToBeUsedWithAnyCustomer'] = 'Permitir que este Proyecto sea utilizado con cualquier Cliente';
lang_es['Content.Checkbox.ApprovedRecords'] = 'Registros Aprobados';
lang_es['Content.Checkbox.ArchivedRecords'] = 'Registros Archivados';
lang_es['Content.Checkbox.ArchivedTime'] = 'Tiempo Archivado';
lang_es['Content.Checkbox.BillRates'] = 'Tarifas de Facturación';
lang_es['Content.Checkbox.BillableTime'] = 'Horas Facturables';
lang_es['Content.Checkbox.CostRates'] = 'Tarifas de Costo';
lang_es['Content.Checkbox.Delete'] = 'Eliminar';
lang_es['Content.Checkbox.DeleteReceipt'] = 'Eliminar Recibo';
lang_es['Content.Checkbox.Detailed'] = 'Detallado';
lang_es['Content.Checkbox.HourlyTimeForPayroll'] = 'Tiempo por Hora para Nómina';
lang_es['Content.Checkbox.IAcceptThe'] = 'Acepto el';
lang_es['Content.Checkbox.IncludeAllDatesEvenWhenNoShiftsWereWorked'] = 'Incluir todas las fechas incluso cuando no hayan turnos trabajados';
lang_es['Content.Checkbox.IncludeApprovedRecords'] = 'Incluir Registros Aprobados';
lang_es['Content.Checkbox.IncludeArchivedRecords'] = 'Incluir Registros Archivados';
lang_es['Content.Checkbox.IncludeArchivedTime'] = 'Incluir Horas Archivadas';
lang_es['Content.Checkbox.IncludeBillRates'] = 'Incluir Tarifas de Facturacion';
lang_es['Content.Checkbox.IncludeBillableTime'] = 'Incluir Tiempo Facturable';
lang_es['Content.Checkbox.IncludeCostRates'] = 'Incluir Tarifas de Costo';
lang_es['Content.Checkbox.IncludeGeoLocationInfo'] = 'Incluir información de geolocalización';
lang_es['Content.Checkbox.IncludeLocationAndDistanceInfo'] = 'Incluir información de ubicación y distancia';
lang_es['Content.Checkbox.IncludeNewRecords'] = 'Incluir Registros Nuevos';
lang_es['Content.Checkbox.IncludeNewTime'] = 'Incluir Horas Nuevas';
lang_es['Content.Checkbox.IncludeOnlyRecordsWithAlerts'] = 'Incluir solamente registros con alertas';
lang_es['Content.Checkbox.IncludeOpenExpenses'] = 'Incluir Gastos Abiertos';
lang_es['Content.Checkbox.IncludeOpenRecords'] = 'Incluir Registros Abiertos';
lang_es['Content.Checkbox.IncludePaidRecords'] = 'Incluir Registros Pagados';
lang_es['Content.Checkbox.IncludePayRates'] = 'Incluir Tarifas de Pago';
lang_es['Content.Checkbox.IncludePreviouslyProcessedRecords'] = 'Incluir registros previamente procesados';
lang_es['Content.Checkbox.IncludeReconciledExpenses'] = 'Incluir Gastos Conciliados';
lang_es['Content.Checkbox.IncludeRecordsWithPendingAlerts'] = 'Incluir Registros con Alertas Pendientes';
lang_es['Content.Checkbox.IncludeSignedRecords'] = 'Incluir Registros Firmados';
lang_es['Content.Checkbox.IncludeUnapprovedRecords'] = 'Incluir Registros No Aprobados';
lang_es['Content.Checkbox.IncludeUnbillableTime'] = 'Incluir Tiempo No Facturable';
lang_es['Content.Checkbox.IncludeUnsignedRecords'] = 'Incluir Registros No Firmados';
lang_es['Content.Checkbox.Items.CheckForDuplicates'] = 'Compruebe si hay elementos duplicados antes de agregarlos.';
lang_es['Content.Checkbox.Items.CheckForDuplicatesSub'] = 'Si busca duplicados y existe un duplicado, no se importará ninguno de los valores.';
lang_es['Content.Checkbox.Items.CheckForHeaderRow'] = 'Compruebe si el archivo incluye una fila de encabezado. Si está marcada, la primera línea del archivo se omitirá de la importación.';
lang_es['Content.Checkbox.Items.CheckForHeaderRowSub'] = 'Deje esto seleccionado si usa el archivo de plantilla.';
lang_es['Content.Checkbox.MakeCalendarOnlyVisibleToMe'] = 'Hacer que este calendario sea visible solo para mí';
lang_es['Content.Checkbox.MakeEventsOnCalendarOnlyEditableByMe'] = 'Hacer eventos en este calendario editables solo por mí';
lang_es['Content.Checkbox.MileageAndExpenseTracking'] = 'Registro de Millaje y Gastos';
lang_es['Content.Checkbox.NewRecords'] = 'Registros Nuevos';
lang_es['Content.Checkbox.NewTime'] = 'Nuevo Tiempo';
lang_es['Content.Checkbox.OpenExpenses'] = 'Gastos Abiertos';
lang_es['Content.Checkbox.OpenRecords'] = 'Registros Abiertos';
lang_es['Content.Checkbox.PaidRecords'] = 'Registros Pagados';
lang_es['Content.Checkbox.PauseRunningTimers'] = '¿Pausar los temporizadores activos?';
lang_es['Content.Checkbox.PayRates'] = 'Tarifas de Pago';
lang_es['Content.Checkbox.ProjectTimeForBilling'] = 'Tiempo de Proyecto para Facturación o Costeo de Trabajo';
lang_es['Content.Checkbox.RatesAppliedFromFollowingDate'] = 'Al actualizarse las tarifas de usuario, esta herramienta puede aplicar las nuevas tarifas a partir de la siguiente fecha (esta función no está disponible para las tarifas por defecto de proyectos).';
lang_es['Content.Checkbox.ReconciledExpenses'] = 'Gastos Conciliados';
lang_es['Content.Checkbox.RecordsExceedingXContinuous'] = 'Identificar registros que excedan {1} horas continuas trabajadas';
lang_es['Content.Checkbox.RecordsWithPendingAlerts'] = 'Registros con Alertas Pendientes';
lang_es['Content.Checkbox.SelectAll'] = 'Seleccionar Todo';
lang_es['Content.Checkbox.SelectCustomersAllowedToUseProject'] = 'Seleccione Clientes autorizados para utilizar este Proyecto.';
lang_es['Content.Checkbox.SelectDeselectAll'] = 'Seleccionar/Deseleccionar Todo';
lang_es['Content.Checkbox.SelectEmployeesAllowedToUseAccountCode'] = 'Seleccione Usarios autorizados para utilizar este Código de Cuenta.';
lang_es['Content.Checkbox.SelectEmployeesAllowedToUseCustomer'] = 'Seleccione Usuarios autorizados para utilizar este Cliente.';
lang_es['Content.Checkbox.SelectEmployeesAllowedToUseProject'] = 'Seleccione Usarios autorizados para utilizar este Proyecto';
lang_es['Content.Checkbox.SelectProjectsAllowedToUseCustomer'] = 'Seleccione Proyectos autorizados para usar este Cliente.';
lang_es['Content.Checkbox.SelectUsersToUpdateUpdateRecords'] = 'Seleccione los usuarios cuyos registros deban actualizarse.';
lang_es['Content.Checkbox.SelectWhichUsersAreAllowedToPostToCompanyMessageBoard'] = 'Seleccionar cuales usuarios tienen permitido publicar al tablero de mensajes';
lang_es['Content.Checkbox.ShareCalendarAndAllowEventsToBeEditedBy'] = 'Compartir este calendario y permitir que los siguientes usuarios editen eventos';
lang_es['Content.Checkbox.ShareThisCalendarWithTheFollowingUsers'] = 'Compartir este calendario con los siguientes usuarios';
lang_es['Content.Checkbox.ShowGroupsEvenIfNoRecords'] = 'Mostrar grupos incluso si no hay registros (0 horas) dentro del rango seleccionado';
lang_es['Content.Checkbox.ShowPreviouslyApprovedRecords'] = 'Mostrar registros previamente aprobados al ver registros para aprobar';
lang_es['Content.Checkbox.SignedRecords'] = 'Registros Firmados';
lang_es['Content.Checkbox.Summary'] = 'Resumen';
lang_es['Content.Checkbox.TZPreserveTimestamp'] = 'Conservar los valores de registro de tiempo relativos de los registros de tiempo estándar y asalariados';
lang_es['Content.Checkbox.TZUpdateUserSettings'] = 'Actualizar la configuración del usuario a la nueva zona horaria';
lang_es['Content.Checkbox.UnapprovedRecords'] = 'Registros No Aprobados';
lang_es['Content.Checkbox.UnbillableTime'] = 'Horas No Facturables';
lang_es['Content.Checkbox.UnsignedRecords'] = 'Registros Sin Firmar';
lang_es['Content.Checkbox.UseIndividualEmployeeSchedule'] = 'Utilizar el calendario individual de los empleados';
lang_es['Content.Checkbox.UseIndividualEmployeeShiftSettings'] = 'Utilizar el horario individual de los empleados';
lang_es['Content.Checkbox.UseThisCompanyWideShiftSetting'] = 'Utilizar la configuración de turnos para toda la empresa';
lang_es['Content.Checkbox.UserPWRequirement'] = 'Requerir que los usuarios cambien su contraseña cada 90 días.';
lang_es['Content.Checkbox.Users.SendNewUserListToAdmin'] = 'Envíe por correo electrónico una copia de las credenciales de inicio de sesión a:';
lang_es['Content.Checkbox.Users.SendWelcomeEmailToNewUsers'] = 'Enviar correos electrónicos de bienvenida a nuevos usuarios.';
lang_es['Content.Checkbox.Users.ValidateImportFileOnly'] = 'Validar formato de archivo solamente. No importe nuevos usuarios.';
lang_es['Content.Checkbox.ViewSelectCustomersAllowedToUseProject'] = 'Vea y/o seleccione qué Clientes pueden usar este Proyecto.';
lang_es['Content.Checkbox.ViewSelectEmployeesAllowedToUseAccountCode'] = 'Vea y/o seleccione qué Usuarios pueden usar este Código de Cuenta.';
lang_es['Content.Checkbox.ViewSelectEmployeesAllowedToUseCustomer'] = 'Vea y/o seleccione qué Usuarios pueden utilizar este Cliente.';
lang_es['Content.Checkbox.ViewSelectEmployeesAllowedToUseProject'] = 'Vea y/o seleccione qué Usuarios pueden usar este Proyecto.';
lang_es['Content.Checkbox.ViewSelectProjectsAllowedToUseCustomer'] = 'Ver y/o seleccionar qué Proyectos están autorizados para utilizar este Cliente.';
lang_es['Content.Clock.AutoClockOutMsg'] = 'SE VA A MARCAR SU SALIDA AUTOMÁTICAMENTE A LAS {1} HOY Y NO ESTA AUTORIZADO PARA TRABAJAR DESPUES DE ESTA HORA. PREPÁRESE PARA SALIR A LA HORA DESIGNADA. SI NO PUEDE SALIR A TIEMPO, UTILICE EL FORMULARIO DE INGRESO MANUAL PARA AGREGAR LAS HORAS NO AUTORIZADAS ANTES DE SALIR.';
lang_es['Content.Clock.AutoClockOutTitle'] = 'MARCACIÓN AUTOMÁTICA DE SALIDA A LAS {1}';
lang_es['Content.Clock.NewTimeWorked'] = 'Nuevo Tiempo Trabajado';
lang_es['Content.Clock.ProjectTimersPaused'] = 'Temporizadores de Proyecto Pausados';
lang_es['Content.Clock.ProjectTimersStopped'] = 'Temporizadores de Proyecto Detenidos';
lang_es['Content.Clock.TimeWorked'] = 'Tiempo Trabajado';
lang_es['Content.Clock.XClockedInOnXAtZ'] = '{1} registró su entrada el {2} a las {3}';
lang_es['Content.Clock.XClockedOutOnXAtZ'] = '{1} registró su salida el {2} a las {3}';
lang_es['Content.Day.Full.Friday'] = 'viernes';
lang_es['Content.Day.Full.Monday'] = 'lunes';
lang_es['Content.Day.Full.Saturday'] = 'sábado';
lang_es['Content.Day.Full.Sunday'] = 'domingo';
lang_es['Content.Day.Full.Thursday'] = 'jueves';
lang_es['Content.Day.Full.Tuesday'] = 'martes';
lang_es['Content.Day.Full.Wednesday'] = 'miércoles';
lang_es['Content.Day.Short.Friday'] = 'fri';
lang_es['Content.Day.Short.Monday'] = 'lun';
lang_es['Content.Day.Short.Saturday'] = 'sáb';
lang_es['Content.Day.Short.Sunday'] = 'dom';
lang_es['Content.Day.Short.Thursday'] = 'jue';
lang_es['Content.Day.Short.Tuesday'] = 'mar';
lang_es['Content.Day.Short.Wednesday'] = 'mie';
lang_es['Content.Desc.Page'] = 'Página';
lang_es['Content.Desc.ResultsPerPage'] = 'Resultados por Página';
lang_es['Content.File.Download.BillingBreakdown'] = 'Desglose de Facturación';
lang_es['Content.File.Download.PDFExpenseReceipts'] = 'Recibos (PDF)';
lang_es['Content.File.Download.PDFExpenseReceipts.AdditionalInfo'] = 'Deben haber uno o más recibos en los reportes.';
lang_es['Content.File.Download.Paychex'] = 'Paychex Payroll';
lang_es['Content.File.Download.PayrollByAccountCode'] = 'Nómina por Código de Cuenta';
lang_es['Content.File.Download.QuickBooksCSV'] = 'QuickBooks CSV';
lang_es['Content.File.Download.QuickBooksIIF'] = 'QuickBooks IIF';
lang_es['Content.File.Download.TextCSV'] = 'Texto/CSV';
lang_es['Content.Input.BragsPlaceholder'] = 'Comparta algo con su equipo';
lang_es['Content.Input.Everyone'] = 'Todos';
lang_es['Content.Input.SigInstruction'] = 'Dejar en blanco para texto predeterminado. Hasta 255 caracteres.';
lang_es['Content.Label.1pt0Hours'] = '1.0 Horas';
lang_es['Content.Label.1pt5Hours'] = '1.5 Horas';
lang_es['Content.Label.2pt0Hours'] = '2.0 Horas';
lang_es['Content.Label.AIChat'] = 'Chat en AI';
lang_es['Content.Label.APIHeader'] = 'Encabezado del API';
lang_es['Content.Label.APIKey'] = 'Clave API';
lang_es['Content.Label.Aborted'] = 'Abortado';
lang_es['Content.Label.Absent'] = 'Ausente';
lang_es['Content.Label.AccessLevel'] = 'Nivel de Acceso';
lang_es['Content.Label.AccessLimit'] = 'Límite de Acceso';
lang_es['Content.Label.AccessPeriod'] = 'Periodo de Acceso';
lang_es['Content.Label.AccountAlerts'] = 'Alertas de la Cuenta';
lang_es['Content.Label.AccountCode'] = 'Código de Cuenta';
lang_es['Content.Label.AccountCodes'] = 'Códigos de Cuenta';
lang_es['Content.Label.AccountNumber'] = 'Número de Cuenta';
lang_es['Content.Label.AccountType'] = 'Tipo de Cuenta';
lang_es['Content.Label.Accounts'] = 'Cuentas';
lang_es['Content.Label.AccrualDate'] = 'Fecha de Acumulación';
lang_es['Content.Label.AccrualRate'] = 'Tasa de Acumulación';
lang_es['Content.Label.AccrualRate.ByHoursWorked'] = 'Por Horas Trabajadas';
lang_es['Content.Label.AccrualRate.Daily'] = 'Diario';
lang_es['Content.Label.AccrualRate.EveryTwoWeeks'] = 'Cada Dos Semanas';
lang_es['Content.Label.AccrualRate.EveryWeek'] = 'Cada Semana';
lang_es['Content.Label.AccrualRate.FirstOfTheMonth'] = 'Primero del Mes';
lang_es['Content.Label.AccrualRate.None'] = 'Ninguno';
lang_es['Content.Label.AccrualRate.OnceYearly'] = 'Una Vez al Año';
lang_es['Content.Label.AccrualRate.TwiceAMonth'] = 'Dos Veces al Mes (1º y 15º)';
lang_es['Content.Label.AccrualRate.Unknown'] = 'Desconocido';
lang_es['Content.Label.AccrualSettings'] = 'Ajustes de Acumulación';
lang_es['Content.Label.Accrued'] = 'Acumulado';
lang_es['Content.Label.AccruedPerYear'] = 'Acumulado/Año';
lang_es['Content.Label.AccruedX'] = '{1}';
lang_es['Content.Label.Accuracy'] = 'Precisión';
lang_es['Content.Label.AccuracyInMeters'] = 'Precisión';
lang_es['Content.Label.Action'] = 'Acción';
lang_es['Content.Label.Active'] = 'Activo';
lang_es['Content.Label.ActiveUsers'] = 'Usuarios Activos';
lang_es['Content.Label.AddNewCalendar'] = 'Añadir Nuevo Calendario';
lang_es['Content.Label.AddNewListItem'] = 'Añadir Nuevo Elemento de Lista';
lang_es['Content.Label.AdditionalDetails'] = 'Detalles Adicionales';
lang_es['Content.Label.AdditionalInformation'] = 'Información Adicional';
lang_es['Content.Label.Address'] = 'Dirección';
lang_es['Content.Label.Address1'] = 'Dirección 1';
lang_es['Content.Label.Address2'] = 'Dirección 2';
lang_es['Content.Label.Admin'] = 'Admin';
lang_es['Content.Label.Administrator'] = 'Administrador';
lang_es['Content.Label.Administrators'] = 'Administradors';
lang_es['Content.Label.Affiliate'] = 'Afiliado';
lang_es['Content.Label.AffiliateStatus.Active'] = 'Activo';
lang_es['Content.Label.AffiliateStatus.Archived'] = 'Archivado';
lang_es['Content.Label.AffiliateStatus.Deleted'] = 'Eliminado';
lang_es['Content.Label.Alert'] = 'Alerta';
lang_es['Content.Label.AlertsFor'] = 'Alertas para';
lang_es['Content.Label.AllSelectedTotal'] = 'Todo seleccionado Total';
lang_es['Content.Label.AllowAccess'] = 'Permitir Acceso';
lang_es['Content.Label.Amount'] = 'Monto';
lang_es['Content.Label.AmountBilled'] = 'Monto Facturado';
lang_es['Content.Label.AndXMore'] = 'y {1} más ...';
lang_es['Content.Label.Announcement'] = 'Anuncio';
lang_es['Content.Label.AnnualReview'] = 'Evaluación Anual';
lang_es['Content.Label.AnnualXtime'] = 'Tiempo {1} Anual';
lang_es['Content.Label.Answer'] = 'Respuesta';
lang_es['Content.Label.Any'] = 'Cualquier';
lang_es['Content.Label.Applied'] = 'Aplicado';
lang_es['Content.Label.ApproveArchive'] = 'Aprobar y Archivar';
lang_es['Content.Label.ApproveOnly'] = 'Aprobar Solamente';
lang_es['Content.Label.ApproveRecord'] = 'Aprobar Registro';
lang_es['Content.Label.Approved'] = 'Aprobado';
lang_es['Content.Label.ApprovedAbbrv'] = 'Apr';
lang_es['Content.Label.ApproxUsers'] = 'Usuarios Aproximados';
lang_es['Content.Label.ApproxUsers.Freelancer'] = 'Independiente (cuenta gratuita)';
lang_es['Content.Label.ApproxUsers.SixToFifteen'] = '6 - 15';
lang_es['Content.Label.ApproxUsers.UpToFive'] = 'Hasta 5 Usuarios';
lang_es['Content.Label.Archive'] = 'Archivo';
lang_es['Content.Label.Archived'] = 'Archivado';
lang_es['Content.Label.ArchivedUsers'] = 'Usuarios Archivados';
lang_es['Content.Label.At'] = 'a las';
lang_es['Content.Label.AtLocation'] = 'En la Ubicación';
lang_es['Content.Label.AuditTrail'] = 'Pistas de Auditorí­a {1,number,#}';
lang_es['Content.Label.AutoDeduct.Break'] = 'Descanso';
lang_es['Content.Label.AutoDeduct.Meal'] = 'Almuerzo';
lang_es['Content.Label.AutoDeduct.None'] = 'Ninguno';
lang_es['Content.Label.AutoDeduct.Unknown'] = 'Desconocido';
lang_es['Content.Label.AutoDeductType'] = 'Tipo de Deducción Automática';
lang_es['Content.Label.Available'] = 'Disponible';
lang_es['Content.Label.AvailableDownloads'] = 'Descargas Disponibles';
lang_es['Content.Label.AvailableItems'] = 'Elementos Disponibles';
lang_es['Content.Label.AvailableUntil'] = 'Disponible hasta';
lang_es['Content.Label.Balances'] = 'Balances';
lang_es['Content.Label.BaseLocation'] = 'Ubicación Base';
lang_es['Content.Label.Bill'] = 'Factura';
lang_es['Content.Label.BillRate'] = 'Tarifa de Facturación';
lang_es['Content.Label.BillRateHour'] = 'Tarifa de Facturación/Hora';
lang_es['Content.Label.BillRateOverride'] = 'Sobrescribir Tarifa de Facturación';
lang_es['Content.Label.BillTotal'] = 'Total a Facturar';
lang_es['Content.Label.Billable'] = 'Facturable';
lang_es['Content.Label.BillingAddress1'] = 'Dirección de Facturación 1';
lang_es['Content.Label.BillingAddress2'] = 'Dirección de Facturación 2';
lang_es['Content.Label.BillingMethod'] = 'Método de Facturación';
lang_es['Content.Label.BillingNameAddress'] = 'Nombre y Dirección de Facturación';
lang_es['Content.Label.BillingReceiptForDate'] = 'Recibo de Facturación por {1}';
lang_es['Content.Label.BillingType.Annual'] = 'Anual';
lang_es['Content.Label.BillingType.Free'] = 'Gratuito';
lang_es['Content.Label.BillingType.Monthly'] = 'Mensual';
lang_es['Content.Label.BillingType.None'] = 'Ninguno';
lang_es['Content.Label.BillingType.Unknown'] = 'Desconocido';
lang_es['Content.Label.Break'] = 'Descanso';
lang_es['Content.Label.BreakDuration'] = 'Duración del Descanso';
lang_es['Content.Label.BreakStart'] = 'Comienzo de Descanso';
lang_es['Content.Label.BreakStartsAt'] = 'El Descanso Comienza a las';
lang_es['Content.Label.BreakTakenAt'] = 'Descanso tomado a las';
lang_es['Content.Label.BusinessEmail'] = 'Correo Electrónico de Negocios';
lang_es['Content.Label.BusinessEmailAddress'] = 'Dirección de Correo Electrónico Comercial';
lang_es['Content.Label.BusinessFax'] = 'Fax de Negocios';
lang_es['Content.Label.BusinessPhone'] = 'Teléfono de Negocios';
lang_es['Content.Label.Calendar'] = 'Calendario';
lang_es['Content.Label.Calendar.OtherStaff'] = 'Otro Personal';
lang_es['Content.Label.Calendar.YourSchedule'] = 'Tu Calendario';
lang_es['Content.Label.CalendarCategory.CompanyCalendars'] = 'Calendarios de la Compañía';
lang_es['Content.Label.CalendarCategory.CustomCalendars'] = 'Calendarios Personalizados';
lang_es['Content.Label.CalendarCategory.HumanResources'] = 'Recursos Humanos';
lang_es['Content.Label.CalendarCategory.Reports'] = 'Reportes';
lang_es['Content.Label.CalendarCategory.Schedules'] = 'Horarios';
lang_es['Content.Label.CalendarCategory.Unknown'] = 'Desconocido';
lang_es['Content.Label.CalendarCategory.YourCalendars'] = 'Tus Calendarios';
lang_es['Content.Label.CalendarType.Private'] = 'Privado';
lang_es['Content.Label.CalendarType.Public'] = 'Público';
lang_es['Content.Label.CalendarType.Shared'] = 'Compartido';
lang_es['Content.Label.CalendarType.Unknown'] = 'Desconocido';
lang_es['Content.Label.Calendars'] = 'Calendarios';
lang_es['Content.Label.CallUs'] = 'Llámenos';
lang_es['Content.Label.CancellationReason.Complexity'] = 'El servicio es demasiado complicado.';
lang_es['Content.Label.CancellationReason.DoesntMeedNeeds'] = 'El servicio no cumple nuestras necesidades.';
lang_es['Content.Label.CancellationReason.NoNeed'] = 'Ya no tenemos empleados que necesiten utilizar el servicio.';
lang_es['Content.Label.CancellationReason.Other'] = 'Por un motivo no mencionado aquí (incluya sus comentarios a continuación).';
lang_es['Content.Label.CancellationReason.OutOfBusiness'] = 'El negocio ya no esta en operacion.';
lang_es['Content.Label.CancellationReason.Price'] = 'El servicio es demasiado caro.';
lang_es['Content.Label.CancellationReason.SitePerformance'] = 'El servicio funciona demasiado lento.';
lang_es['Content.Label.CancellationReason.SwitchServices'] = 'Hemos decidido usar un proveedor diferente.';
lang_es['Content.Label.CancellationReason.Unknown'] = 'Desconocido';
lang_es['Content.Label.CancellationType.Immediate'] = 'Inmediato';
lang_es['Content.Label.CancellationType.Standard'] = 'Estándar';
lang_es['Content.Label.CancellationType.Unknown'] = 'Desconocido';
lang_es['Content.Label.Capable'] = 'Acumulable';
lang_es['Content.Label.CapableNot'] = 'No Acumulable';
lang_es['Content.Label.CapsLockEnabled'] = 'Bloq. Mayús. activado';
lang_es['Content.Label.Change'] = 'Cambiar';
lang_es['Content.Label.ChangeAccountPassword'] = 'Cambiar la Contraseña de la Cuenta';
lang_es['Content.Label.CheckForMealBreaks'] = 'Comprobar Horas de Almuerzo';
lang_es['Content.Label.ChooseReportCriteria'] = 'Elija Criterios del Reporte';
lang_es['Content.Label.City'] = 'Ciudad';
lang_es['Content.Label.Classes'] = 'Clasificaciones';
lang_es['Content.Label.Classification'] = 'Clasificación';
lang_es['Content.Label.ClearedOn'] = 'Despejado el';
lang_es['Content.Label.Click-Throughs'] = 'Clicks al sito';
lang_es['Content.Label.ClockIn'] = 'Marcar Entrada';
lang_es['Content.Label.ClockInAccuracy'] = 'Precisión de Entrada';
lang_es['Content.Label.ClockInDate'] = 'Fecha de Entrada';
lang_es['Content.Label.ClockInIPAddress'] = 'Dirección IP de Entrada';
lang_es['Content.Label.ClockInLatitude'] = 'Latitud de Entrada';
lang_es['Content.Label.ClockInLongitude'] = 'Longitud de Entrada';
lang_es['Content.Label.ClockInOutMap'] = 'Mapa de Entrada/Salida';
lang_es['Content.Label.ClockInTime'] = 'Hora de Entrada';
lang_es['Content.Label.ClockOut'] = 'Marcar Salida';
lang_es['Content.Label.ClockOutAccuracy'] = 'Precisión de Salida';
lang_es['Content.Label.ClockOutDate'] = 'Fecha de Salida';
lang_es['Content.Label.ClockOutIPAddress'] = 'Dirección IP de Salida';
lang_es['Content.Label.ClockOutLatitude'] = 'Latitud de Salida';
lang_es['Content.Label.ClockOutLink'] = 'Enlace de Salida';
lang_es['Content.Label.ClockOutLongitude'] = 'Longitud de Salida';
lang_es['Content.Label.ClockOutTime'] = 'Hora de Salida';
lang_es['Content.Label.ClockPhoto.Blurred'] = 'Borroso';
lang_es['Content.Label.ClockPhoto.CameraDisabled'] = 'Cámara desactivada';
lang_es['Content.Label.ClockPhoto.Confidence'] = 'Confianza';
lang_es['Content.Label.ClockPhoto.Emotion'] = 'Emoción';
lang_es['Content.Label.ClockPhoto.Face'] = 'Rostro';
lang_es['Content.Label.ClockPhoto.FacesFound'] = 'Rostros Encontrados';
lang_es['Content.Label.ClockPhoto.Headwear'] = 'Accesorios en la Cabeza';
lang_es['Content.Label.ClockPhoto.NoData'] = 'Sin datos';
lang_es['Content.Label.ClockPhoto.NoPhoto'] = 'Sin fotografía';
lang_es['Content.Label.ClockPhoto.Underexposed'] = 'Subexpuesto';
lang_es['Content.Label.ClockTimeDate'] = 'Hora de Salida';
lang_es['Content.Label.ClockedIn'] = 'Fichado';
lang_es['Content.Label.ClockedOut'] = 'Salida Registrada';
lang_es['Content.Label.ClosedBy'] = 'Cerrado Por';
lang_es['Content.Label.ClosedOn'] = 'Fecha de Cierre';
lang_es['Content.Label.Color'] = 'Color';
lang_es['Content.Label.Commendation'] = 'Recomendación';
lang_es['Content.Label.Comments'] = 'Comentarios';
lang_es['Content.Label.Commission'] = 'Comisión';
lang_es['Content.Label.Commission.PaymentAmount'] = 'Monto del Pago de la Comisión';
lang_es['Content.Label.Commission.PaymentDate'] = 'Fecha de Pago de la Comisión';
lang_es['Content.Label.Commission.PaymentDate.Approx'] = 'Fecha de pago aproximada';
lang_es['Content.Label.Commission.Pending'] = 'Pendiente';
lang_es['Content.Label.Commission.Suspended'] = 'Suspendido';
lang_es['Content.Label.Company'] = 'Compañía';
lang_es['Content.Label.CompanyID'] = 'ID de la Compañía';
lang_es['Content.Label.CompanyName'] = 'Nombre de la Compañía';
lang_es['Content.Label.CompanyName.Placeholder'] = 'Su Empresa, S.A.';
lang_es['Content.Label.CompanyStatus.Active'] = 'Activo';
lang_es['Content.Label.CompanyStatus.Cancelled'] = 'Cancelado';
lang_es['Content.Label.CompanyStatus.Delinquent'] = 'Delincuente';
lang_es['Content.Label.CompanyStatus.Expired'] = 'Expirado';
lang_es['Content.Label.CompanyStatus.InTrial'] = 'En Periodo de Prueba';
lang_es['Content.Label.CompanyStatus.Other'] = 'Otro';
lang_es['Content.Label.CompanyStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.Complete'] = 'Completo';
lang_es['Content.Label.Completed'] = 'Completado';
lang_es['Content.Label.ConfirmPassword'] = 'Confirmar Contraseña';
lang_es['Content.Label.Contact'] = 'Contacto';
lang_es['Content.Label.ContactEmail'] = 'Correo de Contacto';
lang_es['Content.Label.ContactName'] = 'Nombre de Contacto';
lang_es['Content.Label.ContactPhone'] = 'Teléfono de Contacto';
lang_es['Content.Label.ContactUs'] = 'Contáctenos';
lang_es['Content.Label.ConversionPercent'] = '% de Conversión';
lang_es['Content.Label.ConversionType'] = 'Tipo de Conversión';
lang_es['Content.Label.CostRate'] = 'Tarifa de Costo';
lang_es['Content.Label.CostRateHour'] = 'Tarifa de Costo/Hora';
lang_es['Content.Label.CostRateOverride'] = 'Sobrescribir Tarifa de Costo';
lang_es['Content.Label.CostTotal'] = 'Costo Total';
lang_es['Content.Label.Country'] = 'País';
lang_es['Content.Label.CreateANewDocument'] = 'Crea un Documento Nuevo';
lang_es['Content.Label.CreateAsPreset'] = 'Crear como Preestablecido';
lang_es['Content.Label.CreatePassword'] = 'Crear Nueva Contraseña';
lang_es['Content.Label.CreatedBy'] = 'Creado por';
lang_es['Content.Label.CreatedOn'] = 'Creado el';
lang_es['Content.Label.CroppedPhoto'] = 'Foto Recortada';
lang_es['Content.Label.Current'] = 'Actual';
lang_es['Content.Label.CurrentBillRate'] = 'Tarifa de Facturación Actual';
lang_es['Content.Label.CurrentJobTitle'] = 'Posición Laboral Actual';
lang_es['Content.Label.CurrentLogo'] = 'Logo Actual';
lang_es['Content.Label.CurrentMinimumTimeIncrement'] = 'Incremento de Tiempo Mínimo Actual';
lang_es['Content.Label.CurrentPassword'] = 'Contraseña Actual';
lang_es['Content.Label.CurrentPayRate'] = 'Tarifa de Pago Actual';
lang_es['Content.Label.CurrentSupervisor'] = 'Supervisor Actual';
lang_es['Content.Label.CurrentlyAvailable'] = 'Horas Disponibles';
lang_es['Content.Label.CustomMessage'] = 'Mensaje personalizado';
lang_es['Content.Label.CustomSigningStatementForSuper1'] = 'Declaración de Firma Personalizada para el Supervisor Uno';
lang_es['Content.Label.CustomSigningStatementForSuper2'] = 'Declaración de Firma Personalizada para el Supervisor Dos';
lang_es['Content.Label.CustomSigningStatementForUser'] = 'Declaración de Firma Personalizada para el Usuario';
lang_es['Content.Label.Customer'] = 'Cliente';
lang_es['Content.Label.CustomerNumber'] = 'Número de Cliente';
lang_es['Content.Label.CustomerService'] = 'Servicio al Cliente';
lang_es['Content.Label.Customers'] = 'Clientes';
lang_es['Content.Label.Date'] = 'Fecha';
lang_es['Content.Label.DateApplied'] = 'Fecha Aplicada';
lang_es['Content.Label.DateOfBirth'] = 'Fecha de Nacimiento';
lang_es['Content.Label.DateOfHire'] = 'Fecha de Contratación';
lang_es['Content.Label.DateRangeCustom'] = 'Intervalo de Fechas Personalizado';
lang_es['Content.Label.DateSpan'] = 'Intervalo de Fechas';
lang_es['Content.Label.DateTimeControls.Date'] = 'Fecha';
lang_es['Content.Label.DateTimeControls.EndDate'] = 'Fecha Final';
lang_es['Content.Label.DateTimeControls.EndTime'] = 'Hora de Finalización';
lang_es['Content.Label.DateTimeControls.HoursAndMinutes'] = 'Horas y Minutos';
lang_es['Content.Label.DateTimeControls.SameAsX'] = 'Igual a la {1}';
lang_es['Content.Label.DateTimeControls.StartDate'] = 'Fecha de Inicio';
lang_es['Content.Label.DateTimeControls.StartTime'] = 'Hora de Inicio';
lang_es['Content.Label.DateTimeControls.UseX'] = 'Usa {1}';
lang_es['Content.Label.Day'] = 'Día';
lang_es['Content.Label.DayName.Eighteenth'] = 'Decimoctavo';
lang_es['Content.Label.DayName.Eighth'] = 'Octavo';
lang_es['Content.Label.DayName.Eleventh'] = 'Undécimo';
lang_es['Content.Label.DayName.Fifteenth'] = 'Decimoquinto';
lang_es['Content.Label.DayName.Fifth'] = 'Quinto';
lang_es['Content.Label.DayName.First'] = 'Primero';
lang_es['Content.Label.DayName.Fourteenth'] = 'Decimocuarto';
lang_es['Content.Label.DayName.Fourth'] = 'Cuarto';
lang_es['Content.Label.DayName.Nineteenth'] = 'Decimonoveno';
lang_es['Content.Label.DayName.Ninth'] = 'Noveno';
lang_es['Content.Label.DayName.Second'] = 'Segundo';
lang_es['Content.Label.DayName.Seventeenth'] = 'Decimoséptimo';
lang_es['Content.Label.DayName.Seventh'] = 'Séptimo';
lang_es['Content.Label.DayName.Sixteenth'] = 'Decimosexto';
lang_es['Content.Label.DayName.Sixth'] = 'Sexto';
lang_es['Content.Label.DayName.Tenth'] = 'Décimo';
lang_es['Content.Label.DayName.Third'] = 'Tercero';
lang_es['Content.Label.DayName.Thirteenth'] = 'Decimotercero';
lang_es['Content.Label.DayName.Thirtieth'] = 'Trigésimo';
lang_es['Content.Label.DayName.ThirtyFirst'] = 'Trigésimo Primero';
lang_es['Content.Label.DayName.Twelveth'] = 'Duodécimo';
lang_es['Content.Label.DayName.Twentieth'] = 'Vigésimo';
lang_es['Content.Label.DayName.TwentyEighth'] = 'Vigésimo Octavo';
lang_es['Content.Label.DayName.TwentyFifth'] = 'Vigésimo Quinto';
lang_es['Content.Label.DayName.TwentyFirst'] = 'Vigésimo Primero';
lang_es['Content.Label.DayName.TwentyFourth'] = 'Vigésimo Cuarto';
lang_es['Content.Label.DayName.TwentyNinth'] = 'Vigésimo Noveno';
lang_es['Content.Label.DayName.TwentySecond'] = 'Vigesimo Segundo';
lang_es['Content.Label.DayName.TwentySeventh'] = 'Vigésimo Séptimo';
lang_es['Content.Label.DayName.TwentySixth'] = 'Vigésimo Sexto';
lang_es['Content.Label.DayName.TwentyThird'] = 'Vigésimo Tercero';
lang_es['Content.Label.DeductMinutes'] = 'Deducir Minutos';
lang_es['Content.Label.DeductedHours'] = 'Horas Deducidas';
lang_es['Content.Label.DeductionThreshold'] = 'Umbral de Deducción';
lang_es['Content.Label.Deductions'] = 'Deducciones';
lang_es['Content.Label.DeductionsAbbrev'] = 'Deducc';
lang_es['Content.Label.Default'] = 'Predeterminado';
lang_es['Content.Label.DefaultBillRate'] = 'Tarifa de Facturación Predeterminada';
lang_es['Content.Label.DefaultBillRateHour'] = 'Tarifa de Facturación Predeterminada/Hora';
lang_es['Content.Label.DefaultCostRate'] = 'Tarifa de Costo Predeterminada';
lang_es['Content.Label.DefaultCostRateHour'] = 'Tarifa de Costo Predeterminada/Hora';
lang_es['Content.Label.DefaultCustomer'] = 'Cliente Predeterminado';
lang_es['Content.Label.DefaultExpenseCode'] = 'Código de Gastos Predeterminado';
lang_es['Content.Label.DefaultHourlyCode'] = 'Código de Tiempo por Hora Predeterminado';
lang_es['Content.Label.DefaultProject'] = 'Proyecto Predeterminado';
lang_es['Content.Label.DefaultProjectCode'] = 'Código de Proyecto Predeterminado';
lang_es['Content.Label.DefaultRate'] = 'Tarifa Predeterminada';
lang_es['Content.Label.DefaultStatement'] = 'Declaración Predeterminada';
lang_es['Content.Label.DeletePhoto'] = 'Borrar Foto';
lang_es['Content.Label.Department'] = 'Departamento';
lang_es['Content.Label.Dependability'] = 'Confiabilidad';
lang_es['Content.Label.Description'] = 'Descripción';
lang_es['Content.Label.DescriptionOfIncident'] = 'Descripción de Incidentes y Testimonios';
lang_es['Content.Label.Disabled'] = 'Deshabilitado';
lang_es['Content.Label.DisplayingXofYUsers'] = 'Mostrando {1} de {2,number,#} Usuarios';
lang_es['Content.Label.DistanceInMeters'] = 'Distancia (m)';
lang_es['Content.Label.Division'] = 'División';
lang_es['Content.Label.Doubletime'] = 'Doble Tiempo';
lang_es['Content.Label.Download'] = 'Descargar';
lang_es['Content.Label.DownloadImportFile'] = 'Descargar Archivo de Importación';
lang_es['Content.Label.EarningCodes'] = 'Códigos de Ganancias';
lang_es['Content.Label.Edit'] = 'Editar';
lang_es['Content.Label.EditCalendar'] = 'Editar Calendario';
lang_es['Content.Label.EditExpense'] = 'Editar Gasto';
lang_es['Content.Label.EditHourlyTime'] = 'Editar Tiempo por Hora';
lang_es['Content.Label.EditProjectTime'] = 'Editar Tiempo de Proyecto';
lang_es['Content.Label.EditRequest'] = 'Editar Solicitud';
lang_es['Content.Label.EmailAddress'] = 'Correo Electrónico';
lang_es['Content.Label.EmailAddress.Placeholder'] = 'nombre@empresa.com';
lang_es['Content.Label.EmailOrUserID'] = 'Correo Electrónico o ID de Usuario';
lang_es['Content.Label.Employee'] = 'Empleado';
lang_es['Content.Label.EmployeeComments'] = 'Comentarios del Empleado';
lang_es['Content.Label.EmployeeNumber'] = 'Número de Empleado';
lang_es['Content.Label.EmployeeType.Contractor'] = 'Contratista';
lang_es['Content.Label.EmployeeType.Employee'] = 'Empleado';
lang_es['Content.Label.EmployeeType.Other'] = 'Otro';
lang_es['Content.Label.EmployeeType.Student'] = 'Estudiante';
lang_es['Content.Label.EmployeeType.Unknown'] = 'Desconocido';
lang_es['Content.Label.EmployeeType.Volunteer'] = 'Voluntario';
lang_es['Content.Label.Employees'] = 'Empleados';
lang_es['Content.Label.Enabled'] = 'Habilitado';
lang_es['Content.Label.End'] = 'Fin';
lang_es['Content.Label.EndDate'] = 'Fecha Final';
lang_es['Content.Label.EndTime'] = 'Hora de Finalización';
lang_es['Content.Label.Enter'] = 'Ingresar';
lang_es['Content.Label.EnterExpense'] = 'Ingrese Gasto';
lang_es['Content.Label.EnterHourlyTime'] = 'Ingrese Tiempo por Hora';
lang_es['Content.Label.EnterProjectTime'] = 'Ingrese Horas de Proyecto';
lang_es['Content.Label.EnterReasonForRequest'] = 'Ingrese un motivo para esta solicitud.';
lang_es['Content.Label.EstimatedTotalsForPayroll'] = 'Totales estimados para el periodo';
lang_es['Content.Label.EstimatedTotalsForPayrollPeriod'] = 'Totales estimados para el periodo de nómina';
lang_es['Content.Label.EstimatedTotalsForPayrollPeriodWithDates'] = 'Totales estimados para el periodo de nómina desde {1} a {2}';
lang_es['Content.Label.EstimatedTotalsForXDayPeriod'] = 'Totales Estimados para un Periodo de {1} Días';
lang_es['Content.Label.EstimatedUsers'] = 'Usuarios Estimados';
lang_es['Content.Label.Event'] = 'Evento';
lang_es['Content.Label.Event.PlaceholderAddNew'] = 'Añadir nuevo...';
lang_es['Content.Label.EventLog'] = 'Registro de Eventos';
lang_es['Content.Label.Events'] = 'Eventos';
lang_es['Content.Label.Exempt'] = 'Eximido';
lang_es['Content.Label.Expense'] = 'Gasto';
lang_es['Content.Label.ExpenseReports'] = 'Reportes de Gastos';
lang_es['Content.Label.ExpenseStatus.Open'] = 'Abierto';
lang_es['Content.Label.ExpenseStatus.Reconciled'] = 'Conciliado';
lang_es['Content.Label.ExpenseStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.ExpensedBy'] = 'Gastado por';
lang_es['Content.Label.Expenses'] = 'Gastos';
lang_es['Content.Label.ExportAsBillable'] = 'Exportar como Facturable';
lang_es['Content.Label.ExportCalendar'] = 'Calendario de Exportación';
lang_es['Content.Label.ExportCode'] = 'Código de Exportación';
lang_es['Content.Label.ExportMessage'] = 'Mensaje de Exportación';
lang_es['Content.Label.ExportedBy'] = 'Exportado por';
lang_es['Content.Label.Failed'] = 'Fallido';
lang_es['Content.Label.File'] = 'Archivo';
lang_es['Content.Label.Files'] = 'Archivos';
lang_es['Content.Label.FilterBy'] = 'Filtrar por';
lang_es['Content.Label.FilterByLastName'] = 'Filtrar por Apellido';
lang_es['Content.Label.FindUser'] = 'Encontrar usuario';
lang_es['Content.Label.FindUsers'] = 'Encontrar usuarios';
lang_es['Content.Label.Finished'] = 'Finalizado';
lang_es['Content.Label.FirstName'] = 'Primer Nombre';
lang_es['Content.Label.FixedHourlyRate'] = 'Tarifa Fija por Hora';
lang_es['Content.Label.FixedPay'] = 'Pago Fijo';
lang_es['Content.Label.FixedPayTotal'] = 'Pago Fijo Total';
lang_es['Content.Label.FixedRate'] = 'Tarifa Fija';
lang_es['Content.Label.FontSize.ExtraLarge'] = 'Extra Grande';
lang_es['Content.Label.FontSize.ExtraSmall'] = 'Extra Pequeño';
lang_es['Content.Label.FontSize.Large'] = 'Grande';
lang_es['Content.Label.FontSize.Medium'] = 'Mediano';
lang_es['Content.Label.FontSize.Small'] = 'Pequeño';
lang_es['Content.Label.ForgotPassword'] = 'Recuperar Contraseña';
lang_es['Content.Label.Format'] = 'Formato';
lang_es['Content.Label.FreeTrial'] = 'Prueba Gratis';
lang_es['Content.Label.Frequency'] = 'Frecuencia';
lang_es['Content.Label.From'] = 'De';
lang_es['Content.Label.FromSchedule'] = 'Desde Calendario';
lang_es['Content.Label.FromX'] = 'De {1}';
lang_es['Content.Label.Future'] = 'Futuro';
lang_es['Content.Label.Geolocation'] = 'Geolocalización';
lang_es['Content.Label.GracePeriod'] = 'Periodo de Gracia';
lang_es['Content.Label.GrandTotal'] = 'Total General';
lang_es['Content.Label.GrossPayPerPeriod'] = 'Pago Bruto por Periodo';
lang_es['Content.Label.GroupReportBy'] = 'Agrupar Reporte por';
lang_es['Content.Label.GroupTotal'] = 'Total del Grupo';
lang_es['Content.Label.GroupedBy'] = 'Agrupado Por';
lang_es['Content.Label.GuidedTutorial'] = 'Tutorial Dirigido';
lang_es['Content.Label.HRReport.CommendationLetter'] = 'Carta de Recomendación';
lang_es['Content.Label.HRReport.DisciplineReport'] = 'Reporte Disciplinario';
lang_es['Content.Label.HRReport.EmployeeTerminationLetter'] = 'Carta de Despido del Empleado';
lang_es['Content.Label.HRReport.IncidentReport'] = 'Reporte de Incidente';
lang_es['Content.Label.HRReport.NotSpecified'] = 'No Especificado';
lang_es['Content.Label.HRReport.PerformanceReview'] = 'Revisión de Desempeño';
lang_es['Content.Label.HRReport.TerminationLetter'] = 'Carta de Despido';
lang_es['Content.Label.HidesIn'] = 'Se esconde en';
lang_es['Content.Label.Hostname'] = 'Nombre del Host';
lang_es['Content.Label.Hourly'] = 'Por Hora';
lang_es['Content.Label.HourlyPayRate'] = 'Tarifa de Pago por Hora';
lang_es['Content.Label.HourlyPayTotal'] = 'Pago Fijo Total';
lang_es['Content.Label.HourlyRate'] = 'Tarifa por Hora';
lang_es['Content.Label.HourlyReports'] = 'Reportes de Tiempo por Hora';
lang_es['Content.Label.HourlyTime'] = 'Tiempo por Hora';
lang_es['Content.Label.Hours'] = 'Horas';
lang_es['Content.Label.HoursAbbrev'] = 'hrs';
lang_es['Content.Label.HoursAbbrev2'] = 'h';
lang_es['Content.Label.HoursRequested'] = 'Horas Solicitadas';
lang_es['Content.Label.HoursSummary'] = 'Resumen de Horas';
lang_es['Content.Label.HoursThisShift'] = 'Horas Este Turno';
lang_es['Content.Label.HoursToday'] = 'Horas Hoy';
lang_es['Content.Label.ICSFile'] = 'Archivo ICS';
lang_es['Content.Label.ID'] = 'ID';
lang_es['Content.Label.IPAddress'] = 'Dirección IP';
lang_es['Content.Label.IPAddress.ClockIn'] = 'Dirección IP de Entrada';
lang_es['Content.Label.IPAddress.ClockOut'] = 'Dirección IP de Salida';
lang_es['Content.Label.IPLockExempt'] = 'Exento de Bloqueo de IP';
lang_es['Content.Label.ImportCalendar'] = 'Importar Calendario';
lang_es['Content.Label.ImportLimitsDisabled'] = 'Los límites de importación están deshabilitados.';
lang_es['Content.Label.In'] = 'Entrada';
lang_es['Content.Label.IncludeUsers'] = 'Incluir Estos Usuarios';
lang_es['Content.Label.IncludeUsers.AllUsers'] = 'Todos los Usuarios';
lang_es['Content.Label.IncludeUsers.ByGroup'] = 'Por Grupo';
lang_es['Content.Label.IncludeUsers.OnlyHourly'] = 'Solo por Hora';
lang_es['Content.Label.IncludeUsers.OnlySalary'] = 'Solo Asalariados';
lang_es['Content.Label.IncludeUsers.SelectSpecific'] = 'Seleccionar Específicos';
lang_es['Content.Label.Incomplete'] = 'Incompleto';
lang_es['Content.Label.Initiative'] = 'Iniciativa';
lang_es['Content.Label.InternationalFormat'] = 'Formato Internacional';
lang_es['Content.Label.InvalidLocation'] = 'Ubicación Inválida';
lang_es['Content.Label.Items'] = 'Elementos';
lang_es['Content.Label.JobKnowledge'] = 'Conocimiento del Trabajo';
lang_es['Content.Label.JobTitle'] = 'Título Profesional';
lang_es['Content.Label.JumpTo'] = 'Saltar a';
lang_es['Content.Label.Lang.English'] = 'Inglés';
lang_es['Content.Label.Lang.Spanish'] = 'Español';
lang_es['Content.Label.Lang.en_US'] = 'Inglés';
lang_es['Content.Label.Lang.es_MX'] = 'Español';
lang_es['Content.Label.LastEdit'] = 'Última Edición';
lang_es['Content.Label.LastName'] = 'Apellido';
lang_es['Content.Label.LastUpdate'] = 'Última Actualización';
lang_es['Content.Label.Latest'] = 'Más Reciente';
lang_es['Content.Label.LinkedAccount'] = 'Cuenta Vinculada';
lang_es['Content.Label.LiveChat'] = 'Chat en Vivo';
lang_es['Content.Label.LiveSupport'] = 'Soporte en Línea';
lang_es['Content.Label.Loading'] = 'Cargando...';
lang_es['Content.Label.Location'] = 'Ubicación';
lang_es['Content.Label.LocationAddress'] = 'Dirección de la Ubicación';
lang_es['Content.Label.LocationCaptured'] = 'Ubicación Capturada';
lang_es['Content.Label.LocationNotCaptured'] = 'Ubicación NO Capturada';
lang_es['Content.Label.Login'] = 'Iniciar Sesión';
lang_es['Content.Label.LoginID'] = 'ID de Usuario';
lang_es['Content.Label.LoginStatus'] = 'Estado de Inicio de Sesión';
lang_es['Content.Label.Logo'] = 'Logo';
lang_es['Content.Label.LogoExists'] = 'Logo Existente';
lang_es['Content.Label.ManagementAbility'] = 'Capacidad de Gestión';
lang_es['Content.Label.MaxDistance'] = 'Distancia Máxima';
lang_es['Content.Label.Memo'] = 'Memorándum';
lang_es['Content.Label.Memos'] = 'Memos';
lang_es['Content.Label.MergeAllRecordsFromXTo'] = 'Fusionar Todos los Registros de \'\'{1}\'\' a:';
lang_es['Content.Label.Message'] = 'Mensaje';
lang_es['Content.Label.MessageBoard'] = 'Panel de Mensajes';
lang_es['Content.Label.MessageCount'] = 'Cantidad de Mensajes';
lang_es['Content.Label.MessageTitle'] = 'Título del Mensaje';
lang_es['Content.Label.Messages'] = 'Mensajes';
lang_es['Content.Label.Meters'] = 'Metros';
lang_es['Content.Label.Mileage'] = 'Millaje';
lang_es['Content.Label.MileageMultiplier'] = 'Multiplicador de Millaje';
lang_es['Content.Label.Miles'] = 'Millas';
lang_es['Content.Label.MinimumTimeIncrement'] = 'Incremento de Tiempo Mínimo';
lang_es['Content.Label.Minutes'] = 'Minutos';
lang_es['Content.Label.MinutesAbbrev'] = 'mins';
lang_es['Content.Label.MinutesAbbrev2'] = 'm';
lang_es['Content.Label.MinutesTardy'] = 'Minutos de Tardanza';
lang_es['Content.Label.Missing'] = 'falta...';
lang_es['Content.Label.Mobile'] = 'Móvil';
lang_es['Content.Label.ModifiedBy'] = 'Modificado por';
lang_es['Content.Label.Multiplier'] = 'Multiplicador';
lang_es['Content.Label.Name'] = 'Nombre';
lang_es['Content.Label.NameThisReport'] = 'Nombre este reporte.';
lang_es['Content.Label.New'] = 'Nuevo';
lang_es['Content.Label.NewBillRate'] = 'Nueva Tarifa de Facturación';
lang_es['Content.Label.NewClockInDate'] = 'Nueva Fecha de Entrada';
lang_es['Content.Label.NewClockInTime'] = 'Nueva Hora de Entrada';
lang_es['Content.Label.NewClockOutDate'] = 'Nueva Fecha de Salida';
lang_es['Content.Label.NewClockOutTime'] = 'Nueva Hora de Salida';
lang_es['Content.Label.NewHourlyPayRate'] = 'Nueva Tarifa de Pago por Hora';
lang_es['Content.Label.NewHours'] = 'Horas Nuevas';
lang_es['Content.Label.NewMinimumTimeIncrement'] = 'Nuevo Incremento de Tiempo Mínimo';
lang_es['Content.Label.NewPassword'] = 'Nueva Contraseña';
lang_es['Content.Label.NewPayRate'] = 'Nueva Tarifa de Pago';
lang_es['Content.Label.NewRecords'] = 'Nuevos Registros';
lang_es['Content.Label.NewSalaryPayRate'] = 'Nueva Tarifa de Salario';
lang_es['Content.Label.No'] = 'No';
lang_es['Content.Label.NoAccess'] = 'Sin Acceso';
lang_es['Content.Label.NoCreditCardRequired'] = 'No Se Requiere Tarjeta';
lang_es['Content.Label.NoDescription'] = 'Sin Descripción';
lang_es['Content.Label.NoHistory'] = 'Sin Historia';
lang_es['Content.Label.NoInformation'] = 'Sin información.';
lang_es['Content.Label.None'] = 'Ninguno';
lang_es['Content.Label.NotApplicable'] = 'N/A';
lang_es['Content.Label.NotEnabled'] = 'No Habilitado';
lang_es['Content.Label.NotRequired'] = 'No Requerido';
lang_es['Content.Label.NotValidated'] = 'No Validado';
lang_es['Content.Label.Note'] = 'Notas';
lang_es['Content.Label.NoteStatus.Closed'] = 'Cerrado';
lang_es['Content.Label.NoteStatus.Open'] = 'Abierto';
lang_es['Content.Label.NoteStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.NoteType.About'] = 'Acerca de';
lang_es['Content.Label.NoteType.BillingRelated'] = 'Facturación';
lang_es['Content.Label.NoteType.BugReport'] = 'Reporte de Error';
lang_es['Content.Label.NoteType.CustomerComment'] = 'Comentario del Cliente';
lang_es['Content.Label.NoteType.CustomerSuggestion'] = 'Sugerencia del Cliente';
lang_es['Content.Label.NoteType.DemoComment'] = 'Comentario de demostración';
lang_es['Content.Label.NoteType.General'] = 'General';
lang_es['Content.Label.NoteType.IncidentReport'] = 'Reporte de Incidente';
lang_es['Content.Label.NoteType.NoSale'] = 'No Hubo Venta';
lang_es['Content.Label.NoteType.ReadMe'] = 'LEEME';
lang_es['Content.Label.NoteType.Retention'] = 'Retención';
lang_es['Content.Label.NoteType.SpecialOffer'] = 'Oferta Especial';
lang_es['Content.Label.NoteType.TrialComment'] = 'Periodo de Prueba';
lang_es['Content.Label.NoteType.Unknown'] = 'Desconocido';
lang_es['Content.Label.Notes'] = 'Notas';
lang_es['Content.Label.Notes.OptionalPlaceholder'] = 'Opcional';
lang_es['Content.Label.Notifications'] = 'Notificaciones';
lang_es['Content.Label.Off'] = 'Deshabilitado';
lang_es['Content.Label.Offline'] = 'Desconectado';
lang_es['Content.Label.Okay'] = 'Ok';
lang_es['Content.Label.On'] = 'Habilitado';
lang_es['Content.Label.OnBreak'] = 'En Descanso';
lang_es['Content.Label.Optional'] = 'Opcional';
lang_es['Content.Label.OptionalFields'] = 'Campos Opcionales';
lang_es['Content.Label.Original'] = 'Original';
lang_es['Content.Label.OriginalPhoto'] = 'Foto Original';
lang_es['Content.Label.OriginalRecord'] = 'Registro Original';
lang_es['Content.Label.Origination'] = 'Orígen';
lang_es['Content.Label.OriginationType.Clock'] = 'Entrada Regular';
lang_es['Content.Label.OriginationType.MobileClock'] = 'Entrada Móvil';
lang_es['Content.Label.OriginationType.MobileTimeEntry'] = 'Ingreso de Tiempo Móvil';
lang_es['Content.Label.OriginationType.MobileTimer'] = 'Temporizador Móvil';
lang_es['Content.Label.OriginationType.TimeEntry'] = 'Ingreso de Tiempo';
lang_es['Content.Label.OriginationType.Timer'] = 'Temporizador';
lang_es['Content.Label.OriginationType.Unknown'] = 'Desconocido';
lang_es['Content.Label.Out'] = 'Salida';
lang_es['Content.Label.OutNotClockedIn'] = 'No Fichado';
lang_es['Content.Label.OverallPerformanceRating'] = 'Valoración General de Rendimiento';
lang_es['Content.Label.OverlappingRecords'] = 'Registros Superpuestos';
lang_es['Content.Label.Overtime'] = 'Sobretiempo';
lang_es['Content.Label.OvertimePolicy'] = 'Política de Horas Extras';
lang_es['Content.Label.OvertimePolicy.Daily'] = 'Diario';
lang_es['Content.Label.OvertimePolicy.None'] = 'Ninguno';
lang_es['Content.Label.OvertimePolicy.Unknown'] = 'Desconocido';
lang_es['Content.Label.OvertimePolicy.Weekly'] = 'Semanal';
lang_es['Content.Label.Paid'] = 'Pagado';
lang_es['Content.Label.Password'] = 'Contraseña';
lang_es['Content.Label.Password.Placeholder'] = 'su contraseña segura';
lang_es['Content.Label.PasswordRequirements'] = 'Requisito de Contraseña...';
lang_es['Content.Label.PauseCurrentlyRunningTimers'] = '¿Pausar los temporizadores activos?';
lang_es['Content.Label.Paused'] = 'Pausado';
lang_es['Content.Label.PayAnnually'] = 'Pagar Anualmente';
lang_es['Content.Label.PayAnnuallyInfo'] = 'Facturado automáticamente cada año en la fecha de aniversario.';
lang_es['Content.Label.PayFreelancer'] = 'Cuenta de Independiente';
lang_es['Content.Label.PayFreelancerInfo'] = 'Servicio gratuito - Dé seguimiento de sus propios datos sin cargo.';
lang_es['Content.Label.PayMonthly'] = 'Pago Mensual';
lang_es['Content.Label.PayMonthlyInfo'] = 'Facturado automáticamente el día 1 de cada mes.';
lang_es['Content.Label.PayPeriod'] = 'Periodo de Pago';
lang_es['Content.Label.PayPeriod.Bi-Monthly'] = 'Quincenal';
lang_es['Content.Label.PayPeriod.Bi-Weekly'] = 'Cada Dos Semanas';
lang_es['Content.Label.PayPeriod.Monthly'] = 'Mensual';
lang_es['Content.Label.PayPeriod.None'] = 'Ninguno';
lang_es['Content.Label.PayPeriod.Unknown'] = 'Desconocido';
lang_es['Content.Label.PayPeriod.Weekly'] = 'Semanal';
lang_es['Content.Label.PayRate'] = 'Tarifa de Pago';
lang_es['Content.Label.PayTotal'] = 'Pago Total';
lang_es['Content.Label.PayType'] = 'Tipo de Pago';
lang_es['Content.Label.PayType.Hourly'] = 'Por Hora';
lang_es['Content.Label.PayType.Salary'] = 'Asalariado';
lang_es['Content.Label.PayType.Unknown'] = 'Desconocido';
lang_es['Content.Label.PaymentAmount'] = 'Monto del Pago';
lang_es['Content.Label.PaymentDate'] = 'Fecha de Pago';
lang_es['Content.Label.PaymentDetails'] = 'Detalles del Pago';
lang_es['Content.Label.PaymentInfo'] = 'Información del Pago';
lang_es['Content.Label.Payroll.1pt0X'] = '1.0x';
lang_es['Content.Label.Payroll.1pt5X'] = '1.5x';
lang_es['Content.Label.Payroll.2pt0X'] = '2.0x';
lang_es['Content.Label.Payroll.AccrualPeriod'] = 'Periodo de Acumulación';
lang_es['Content.Label.Payroll.AccruedNextPeriod'] = 'Acumulado el Próximo Periodo';
lang_es['Content.Label.Payroll.AccruedThisPeriod'] = 'Acumulado Este Periodo';
lang_es['Content.Label.Payroll.AccruedYTD'] = 'Acumulado Hasta la Fecha';
lang_es['Content.Label.Payroll.Add'] = 'Agregar';
lang_es['Content.Label.Payroll.AdditionPolicy'] = 'Política de Adición';
lang_es['Content.Label.Payroll.Aprvd'] = 'Aprobado';
lang_es['Content.Label.Payroll.Credited'] = 'Acreditado';
lang_es['Content.Label.Payroll.DTHours'] = 'Sobretiempo 2.0x Horas';
lang_es['Content.Label.Payroll.Deducted'] = 'Deducir';
lang_es['Content.Label.Payroll.DeductionPolicy'] = 'Política de Deducción';
lang_es['Content.Label.Payroll.EligibleForCredit'] = 'Elegible para Crédito';
lang_es['Content.Label.Payroll.EstCredit'] = 'Crédito Estimado';
lang_es['Content.Label.Payroll.EstCurrentBalance'] = 'Saldo Actual Estimado';
lang_es['Content.Label.Payroll.EstFinalBalance'] = 'Saldo Final Estimado';
lang_es['Content.Label.Payroll.FinalAccrualBalance'] = 'Saldo de Acumulación Final';
lang_es['Content.Label.Payroll.Hours'] = 'Horas';
lang_es['Content.Label.Payroll.HoursPerYear'] = 'Horas por Año';
lang_es['Content.Label.Payroll.HoursTowardAccrual'] = 'Horas Totales Trabajadas para la Acumulación';
lang_es['Content.Label.Payroll.MaxHours'] = 'Máximo de Horas';
lang_es['Content.Label.Payroll.NewAccruals'] = 'Nuevas Acumulaciones';
lang_es['Content.Label.Payroll.OTHours'] = 'Sobretiempo 1.5x Horas';
lang_es['Content.Label.Payroll.OptimizationsApplied'] = 'Optimizaciones Aplicadas';
lang_es['Content.Label.Payroll.PayrollEndDate'] = 'Fecha de Finalización de la Nómina';
lang_es['Content.Label.Payroll.PayrollStartDate'] = 'Fecha de Inicio de la Nómina';
lang_es['Content.Label.Payroll.Policies'] = 'Políticas';
lang_es['Content.Label.Payroll.PreviousDaysWorked'] = 'Días anteriores trabajados esta semana';
lang_es['Content.Label.Payroll.PreviouslyPaidThisWeek'] = 'Horas estándar pagadas previamente esta semana';
lang_es['Content.Label.Payroll.RatePerHour'] = 'Tarifa por Hora';
lang_es['Content.Label.Payroll.RecordsCrossingDateline'] = 'Registros que Cruzan la Fecha Límite';
lang_es['Content.Label.Payroll.RecordsExceedingHrsThreshold'] = 'Registros que Superan el Umbral de Horas';
lang_es['Content.Label.Payroll.RecordsExceedingXHrsThreshold'] = 'Registros que Superan el Umbral de {1} Horas';
lang_es['Content.Label.Payroll.RecordsProcessed'] = 'Total de Registros Procesados';
lang_es['Content.Label.Payroll.RecordsShiftDifferential'] = 'Registros que Califican para el Diferencial de Turno';
lang_es['Content.Label.Payroll.RoundingIntervalApplied'] = 'Intervalo de Redondeo Aplicado';
lang_es['Content.Label.Payroll.RoundingIntervalApplied.NearestXMin'] = 'más cercano a {1} min';
lang_es['Content.Label.Payroll.Salary'] = 'Salario';
lang_es['Content.Label.Payroll.ShiftDifferentials'] = 'Diferenciales de Turno';
lang_es['Content.Label.Payroll.ShiftDifferentials.FixedPercent'] = 'Porcentaje Fijo';
lang_es['Content.Label.Payroll.ShiftDifferentials.One'] = 'Diferencial de Turno Uno';
lang_es['Content.Label.Payroll.ShiftDifferentials.Time'] = 'Tiempo';
lang_es['Content.Label.Payroll.ShiftDifferentials.Two'] = 'Diferencial de Turno Dos';
lang_es['Content.Label.Payroll.ShiftDifferentials.VariableAcctCode'] = 'Código de Cuenta Variable';
lang_es['Content.Label.Payroll.Sig'] = 'Firmado';
lang_es['Content.Label.Payroll.Standard'] = 'Estándar';
lang_es['Content.Label.Payroll.StandardHours'] = 'Horas Estándar';
lang_es['Content.Label.Payroll.TimeIn'] = 'Entrada';
lang_es['Content.Label.Payroll.TimeOut'] = 'Salida';
lang_es['Content.Label.Payroll.TotalAllHours'] = 'Total de Todas las Horas';
lang_es['Content.Label.Payroll.TotalDeducted'] = 'Total de Horas Deducidas';
lang_es['Content.Label.Payroll.TotalFixedPay'] = 'Pago Fijo Total';
lang_es['Content.Label.Payroll.TotalHoursAdded'] = 'Horas Totales Agregadas';
lang_es['Content.Label.Payroll.TotalHoursDeducted'] = 'Horas Totales Deducidas';
lang_es['Content.Label.Payroll.TotalOfAllHours'] = 'Total de Todas las Horas';
lang_es['Content.Label.Payroll.TotalPaidHours'] = 'Horas Pagadas Totales';
lang_es['Content.Label.Payroll.TotalPayable'] = 'Horas Pagables Totales';
lang_es['Content.Label.Payroll.TotalPayroll'] = 'Nómina Total';
lang_es['Content.Label.Payroll.TotalReportPayroll'] = 'Reporte de Nómina Total';
lang_es['Content.Label.Payroll.TotalVariablePay'] = 'Pago Variable Total';
lang_es['Content.Label.Payroll.UsersProcessed'] = 'Usuarios Procesados';
lang_es['Content.Label.Payroll.WeekOf'] = 'Semana de';
lang_es['Content.Label.Payroll.WorkedNextPeriod'] = 'Trabajado el Próximo Periodo';
lang_es['Content.Label.Payroll.WorkedThisPeriod'] = 'Trabajado Este Periodo';
lang_es['Content.Label.Payroll.WorkedYTD'] = 'Trabajado Este Año';
lang_es['Content.Label.PayrollPeriod'] = 'Periodo de Nómina';
lang_es['Content.Label.PayrollPeriods'] = 'Periodos de Nómina';
lang_es['Content.Label.PayrollSpecializedTools'] = 'Herramientas Especializadas';
lang_es['Content.Label.PayrollSpecializedTools.SeparateQueries'] = 'Ejecute Consultas de Datos Independientes';
lang_es['Content.Label.Pending'] = 'Pendiente';
lang_es['Content.Label.PerformanceDocuments'] = 'Documentos de Desempeño';
lang_es['Content.Label.PeriodEnd'] = 'Fin del Periodo';
lang_es['Content.Label.PeriodStart'] = 'Inicio del Periodo';
lang_es['Content.Label.Phone'] = 'Teléfono';
lang_es['Content.Label.PhoneExt'] = 'Ext.';
lang_es['Content.Label.Photo'] = 'Foto';
lang_es['Content.Label.PhotoNoImage'] = 'Sin Imágen';
lang_es['Content.Label.PhotoTimestampExempt'] = 'Exento de Registro de Foto';
lang_es['Content.Label.Photos'] = 'Fotos';
lang_es['Content.Label.PleaseWait'] = 'Por favor espere';
lang_es['Content.Label.PostDated'] = 'Publicación con Fecha';
lang_es['Content.Label.Posted'] = 'Publicado';
lang_es['Content.Label.Primary'] = 'Primario';
lang_es['Content.Label.PrimaryAccountAdmin'] = 'Administrador Principal de Cuenta';
lang_es['Content.Label.Print'] = 'Imprimir';
lang_es['Content.Label.Print.ExtendedData'] = 'Información extendida';
lang_es['Content.Label.Print.Legend'] = 'Leyenda';
lang_es['Content.Label.Print.Notes'] = 'Notas';
lang_es['Content.Label.Print.PageBreaks'] = 'Saltos de Página';
lang_es['Content.Label.Print.PrintPDF'] = 'Imprimir / PDF';
lang_es['Content.Label.Print.PrintPDFOptions'] = 'Impresión / Opciones de PDF';
lang_es['Content.Label.Print.PrintSize'] = 'Tamaño de impresión';
lang_es['Content.Label.Print.SignatureBlocks'] = 'Espacios de Firma';
lang_es['Content.Label.Private'] = 'Privado';
lang_es['Content.Label.ProfileHistory'] = 'Historial del Perfil';
lang_es['Content.Label.ProfileID'] = 'ID del Perfil';
lang_es['Content.Label.ProfilePhoto'] = 'Foto de Perfil';
lang_es['Content.Label.Project'] = 'Proyecto';
lang_es['Content.Label.ProjectDescription'] = 'Descripción del Proyecto';
lang_es['Content.Label.ProjectReports'] = 'Reportes de Proyectos';
lang_es['Content.Label.ProjectReset'] = '¡Reiniciar Proyecto!';
lang_es['Content.Label.ProjectTime'] = 'Tiempo de Proyecto';
lang_es['Content.Label.ProjectTimers'] = 'Temporizadores de Proyecto';
lang_es['Content.Label.ProjectTimersActive'] = 'Temporizadores Activos';
lang_es['Content.Label.ProjectTimersPaused'] = 'Temporizadores de Proyecto en Pausa';
lang_es['Content.Label.ProjectTimersStopped'] = 'Temporizadores de Proyecto Detenidos';
lang_es['Content.Label.Projects'] = 'Proyectos';
lang_es['Content.Label.Public'] = 'Público';
lang_es['Content.Label.QualityOfWork'] = 'Calidad de Trabajo';
lang_es['Content.Label.QuantityOfWork'] = 'Cantidad de Trabajo';
lang_es['Content.Label.Question'] = 'Pregunta';
lang_es['Content.Label.Questions'] = '¿Preguntas?';
lang_es['Content.Label.Rate'] = 'Tarifa';
lang_es['Content.Label.RateOverride'] = 'Reemplazo de Tarifa';
lang_es['Content.Label.RateUsed'] = 'Tarifa Utilizada';
lang_es['Content.Label.Rating'] = 'Calificación';
lang_es['Content.Label.Read'] = 'Leído';
lang_es['Content.Label.ReadOnly'] = 'Solo Lectura';
lang_es['Content.Label.Reason'] = 'Razón';
lang_es['Content.Label.ReasonForDisicplinaryAction'] = 'Razón de la Acción Disciplinaria y Acción Tomada';
lang_es['Content.Label.ReasonForEdit'] = 'Motivo de Edición';
lang_es['Content.Label.ReasonForTermination'] = 'Razón del Despido';
lang_es['Content.Label.Receipt'] = 'Recibo';
lang_es['Content.Label.ReceiptSubmitted'] = 'Recibo Ingresado';
lang_es['Content.Label.RecordAction.Deleted'] = 'Eliminado';
lang_es['Content.Label.RecordAction.Edit'] = 'Editar';
lang_es['Content.Label.RecordAction.New'] = 'Nuevo';
lang_es['Content.Label.RecordAction.Request'] = 'Solicitud';
lang_es['Content.Label.RecordAction.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordApproved.Alert'] = 'Alerta';
lang_es['Content.Label.RecordApproved.Approved'] = 'Aprobado';
lang_es['Content.Label.RecordApproved.Unapproved'] = 'No Aprobado';
lang_es['Content.Label.RecordApproved.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordBillable.Billable'] = 'Facturable';
lang_es['Content.Label.RecordBillable.Unbillable'] = 'No Facturable';
lang_es['Content.Label.RecordBillable.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordExpenseStatus.Open'] = 'Abierto';
lang_es['Content.Label.RecordExpenseStatus.Reconciled'] = 'Conciliado';
lang_es['Content.Label.RecordExpenseStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordHourlyStatus.Archived'] = 'Archivado';
lang_es['Content.Label.RecordHourlyStatus.Open'] = 'Abierto';
lang_es['Content.Label.RecordHourlyStatus.Paid'] = 'Pagado';
lang_es['Content.Label.RecordHourlyStatus.Split'] = 'Separado';
lang_es['Content.Label.RecordHourlyStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordProjectStatus.Archived'] = 'Archivado';
lang_es['Content.Label.RecordProjectStatus.New'] = 'Nuevo';
lang_es['Content.Label.RecordProjectStatus.Open'] = 'Abierto';
lang_es['Content.Label.RecordProjectStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordSigned.Signed'] = 'Firmado';
lang_es['Content.Label.RecordSigned.Unknown'] = 'Desconocido';
lang_es['Content.Label.RecordSigned.Unsigned'] = 'No Firmado';
lang_es['Content.Label.RecordXMinBreak'] = 'Marcar {1} Minutos de Descanso Pagado';
lang_es['Content.Label.Records'] = 'Registros';
lang_es['Content.Label.RecordsWithAlerts'] = 'Registros con Alertas';
lang_es['Content.Label.Referral'] = 'Referencia';
lang_es['Content.Label.RemainingX'] = 'Restante {1}';
lang_es['Content.Label.RemainingXTime'] = 'Tiempo Restante {1}';
lang_es['Content.Label.RememberMe'] = 'Recuérdame';
lang_es['Content.Label.RememberMyUserID'] = 'Recordar Mi Usuario';
lang_es['Content.Label.Removed'] = 'Removido';
lang_es['Content.Label.ReportDate'] = 'Fecha del Reporte';
lang_es['Content.Label.ReportGenerated'] = 'Reporte Generado';
lang_es['Content.Label.ReportRange'] = 'Rango del Reporte';
lang_es['Content.Label.ReportType'] = 'Tipo de Reporte';
lang_es['Content.Label.Reports.ActiveAccountCodes'] = 'Códigos de Cuenta Activos';
lang_es['Content.Label.Reports.ActiveCustomers'] = 'Clientes Activos';
lang_es['Content.Label.Reports.ActiveEvents'] = 'Eventos Activos';
lang_es['Content.Label.Reports.ActiveProjects'] = 'Proyectos Activos';
lang_es['Content.Label.Reports.ActiveVendors'] = 'Proveedores Activos';
lang_es['Content.Label.Reports.AllAccountCodes'] = 'Todos los Códigos de Cuenta';
lang_es['Content.Label.Reports.AllActiveUsers'] = 'Todos los Usuarios Activos';
lang_es['Content.Label.Reports.AllActiveUsersWithArchivedNote'] = 'Todos los Usuarios Activos (abrir para seleccionar usuarios archivados)';
lang_es['Content.Label.Reports.AllCustomers'] = 'Todos los Clientes';
lang_es['Content.Label.Reports.AllEvents'] = 'Todos los Eventos';
lang_es['Content.Label.Reports.AllProjects'] = 'Todos los Proyectos';
lang_es['Content.Label.Reports.AllVendors'] = 'Todos los Proveedores';
lang_es['Content.Label.Reports.ArchivedAccountCodes'] = 'Códigos de Cuenta Archivados';
lang_es['Content.Label.Reports.ArchivedCustomers'] = 'Clientes Archivados';
lang_es['Content.Label.Reports.ArchivedEvents'] = 'Eventos Archivados';
lang_es['Content.Label.Reports.ArchivedProjects'] = 'Proyectos Archivados';
lang_es['Content.Label.Reports.ArchivedVendors'] = 'Proveedores Archivados';
lang_es['Content.Label.Reports.ExpenseReport'] = 'Reporte de Gastos';
lang_es['Content.Label.Reports.HideX'] = 'Ocultar {1}';
lang_es['Content.Label.Reports.HourlyTimeReport'] = 'Reporte de Tiempo por Hora';
lang_es['Content.Label.Reports.HoursSummary'] = 'Resumen de Horas';
lang_es['Content.Label.Reports.IncludeAllAccountCodes'] = 'Incluir Todos los Códigos de Cuenta';
lang_es['Content.Label.Reports.IncludeAllActiveUsers'] = 'Incluir Todos los Usuarios Activos (desmarque para incluir archivados)';
lang_es['Content.Label.Reports.IncludeAllActiveUsersNoArchived'] = 'Incluir Todos los Usuarios Activos';
lang_es['Content.Label.Reports.IncludeAllCustomers'] = 'Incluir Todos los Clientes';
lang_es['Content.Label.Reports.IncludeAllEvents'] = 'Incluir Todos los Eventos';
lang_es['Content.Label.Reports.IncludeAllProjects'] = 'Incluir Todos los Proyectos';
lang_es['Content.Label.Reports.IncludeAllVendors'] = 'Incluir Todos los Proveedores';
lang_es['Content.Label.Reports.LocationReport'] = 'Reporte de Ubicación';
lang_es['Content.Label.Reports.OverlappingRecordsReport'] = 'Reporte de Registros Superpuestos';
lang_es['Content.Label.Reports.ProjectTimeReport'] = 'Reporte de Tiempo de Proyecto';
lang_es['Content.Label.Reports.ShowX'] = 'Mostrar {1}';
lang_es['Content.Label.Reports.UserInfoReport'] = 'Informe de información del usuario';
lang_es['Content.Label.Reports.ViewXAccountCodes'] = 'Ver {1,number,#} Códigos de Cuenta';
lang_es['Content.Label.Reports.ViewXCustomers'] = 'Ver {1,number,#} Clientes';
lang_es['Content.Label.Reports.ViewXEmployees'] = 'Ver {1,number,#} Empleados';
lang_es['Content.Label.Reports.ViewXEvents'] = 'Ver {1,number,#} Eventos';
lang_es['Content.Label.Reports.ViewXProjects'] = 'Ver {1,number,#} Proyectos';
lang_es['Content.Label.Reports.ViewXTimeClasses'] = 'Ver {1} Clases de Tiempo';
lang_es['Content.Label.Reports.ViewXVendors'] = 'Ver {1,number,#} Proveedores';
lang_es['Content.Label.Reports.XAccountCodes'] = '{1,number,#} Códigos de Cuenta';
lang_es['Content.Label.Reports.XCustomers'] = '{1,number,#} Clientes';
lang_es['Content.Label.Reports.XEmployees'] = '{1,number,#} Empleados';
lang_es['Content.Label.Reports.XEvents'] = '{1,number,#} Eventos';
lang_es['Content.Label.Reports.XProjects'] = '{1,number,#} Proyectos';
lang_es['Content.Label.Reports.XTimeClasses'] = '{1} Clases de Tiempo';
lang_es['Content.Label.Reports.XVendors'] = '{1,number,#} Proveedores';
lang_es['Content.Label.Requested'] = 'Solicitado';
lang_es['Content.Label.Requests'] = 'Solicitudes';
lang_es['Content.Label.Required'] = 'Requerido';
lang_es['Content.Label.RestrictedFields'] = 'Campos Restringidos';
lang_es['Content.Label.Result'] = 'Resultado';
lang_es['Content.Label.ResultsFor'] = 'Resultados para';
lang_es['Content.Label.Retry'] = 'Reintentar';
lang_es['Content.Label.ReviewType'] = 'Tipo de Revisión';
lang_es['Content.Label.ReviewedBy'] = 'Revisado por';
lang_es['Content.Label.Revision'] = 'Revisión';
lang_es['Content.Label.RunInBackground'] = '¿Necesita un reporte grande? Ejecútelo en segundo plano.';
lang_es['Content.Label.Running'] = 'Corriendo';
lang_es['Content.Label.SalaryPayRate'] = 'Tarifa de Pago de Salario';
lang_es['Content.Label.SalesStatus.Closed'] = 'Cerrado';
lang_es['Content.Label.SalesStatus.Ghost'] = 'Fantasma';
lang_es['Content.Label.SalesStatus.NoContact'] = 'No Contactar';
lang_es['Content.Label.SalesStatus.None'] = 'Ninguno';
lang_es['Content.Label.SalesStatus.Post-Demo'] = 'Post-Demo';
lang_es['Content.Label.SalesStatus.Pre-Demo'] = 'Pre-Demo';
lang_es['Content.Label.SalesStatus.Tracking'] = 'Marcando';
lang_es['Content.Label.SalesStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.SameAsX'] = 'Igual que {1}';
lang_es['Content.Label.ScheduledXTimeNotYetTaken'] = '{1} Tiempo Programado aún no Tomado';
lang_es['Content.Label.SearchKeywords'] = 'Buscar Palabras Claves';
lang_es['Content.Label.SearchNotesOnKeywords'] = 'Notas de Búsqueda en Palabras Clave';
lang_es['Content.Label.SearchResults'] = 'Resultados de Búsqueda';
lang_es['Content.Label.SearchResultsFor'] = 'Resultados de búsqueda para';
lang_es['Content.Label.SecurityCode'] = 'Código de Seguridad';
lang_es['Content.Label.SecurityQuestion'] = 'Pregunta de Seguridad';
lang_es['Content.Label.SelectAFormat'] = 'Seleccione un formato:';
lang_es['Content.Label.SelectAccountToLink'] = 'Seleccionar Cuenta para Vincular';
lang_es['Content.Label.SelectListItems'] = 'Seleccionar Elementos de Listas';
lang_es['Content.Label.SelectPayrollPeriod'] = 'Seleccionar Periodo de Nómina';
lang_es['Content.Label.SelectPayrollPeriod.AppendUsers'] = 'Agregar usuarios seleccionados a un reporte de nómina existente';
lang_es['Content.Label.SelectPayrollPeriod.ScheduledPeriods'] = 'Periodos Programados';
lang_es['Content.Label.SelectShiftToUseWithReport'] = 'Seleccione Turno para Usar con el Reporte';
lang_es['Content.Label.SelectUsersToAssignToSupervisor'] = 'Seleccione a los usuarios que serán asignados a este supervisor.';
lang_es['Content.Label.SelectedItems'] = 'Elementos Seleccionados';
lang_es['Content.Label.SelectedTotal'] = 'Total Seleccionado';
lang_es['Content.Label.SendAMemo'] = 'Enviar un Memo';
lang_es['Content.Label.SendClockOutLink'] = 'Enviar Enlace de Marcar Salida';
lang_es['Content.Label.SendTo'] = 'Enviar A';
lang_es['Content.Label.SentBy'] = 'Enviado Por';
lang_es['Content.Label.Settings'] = 'Ajustes';
lang_es['Content.Label.Shared'] = 'Compartido';
lang_es['Content.Label.SharedWith'] = 'Compartido Con';
lang_es['Content.Label.Shift'] = 'Turno';
lang_es['Content.Label.ShiftEnd'] = 'Fin de Turno';
lang_es['Content.Label.ShiftRestricted'] = 'Turno Restringido';
lang_es['Content.Label.ShiftRestriction'] = 'Restricción de Turno';
lang_es['Content.Label.ShiftStart'] = 'Inicio de Turno';
lang_es['Content.Label.ShippingInfo'] = 'Información de Envío';
lang_es['Content.Label.ShowReportFor'] = 'Mostrar Reporte para';
lang_es['Content.Label.ShowingXMostRecentChangesToTimesheet'] = 'Mostrando los {1} cambios más recientes en la hoja de horas.';
lang_es['Content.Label.SignUps'] = 'Registros';
lang_es['Content.Label.Signature.AllRecordsSignedBySupervisor'] = 'Todos los registros fueron aprobados electrónicamente por el supervisor.';
lang_es['Content.Label.Signature.AllRecordsSignedByWorked'] = 'Todos los registros fueron firmados electrónicamente por este trabajador.';
lang_es['Content.Label.Signature.ContractorSignature'] = 'Firma del Contratista';
lang_es['Content.Label.Signature.EmployeeSignature'] = 'Firma del Empleado';
lang_es['Content.Label.Signature.OtherSignature'] = 'Otra Firma';
lang_es['Content.Label.Signature.Signature'] = 'Firma';
lang_es['Content.Label.Signature.StudentSignature'] = 'Firma del Alumno';
lang_es['Content.Label.Signature.SupervisorSignature'] = 'Firma del Supervisor';
lang_es['Content.Label.Signature.VolunteerSignature'] = 'Firma del Voluntario';
lang_es['Content.Label.Signed'] = 'Firmado';
lang_es['Content.Label.SignedAbbrv'] = 'Fir';
lang_es['Content.Label.Size'] = 'Tamaño';
lang_es['Content.Label.Snapshot'] = 'Captura';
lang_es['Content.Label.SplitHourlyTime'] = 'Dividir Tiempo por Hora';
lang_es['Content.Label.SplitRecord'] = 'Dividir Un Registro en Dos Registros';
lang_es['Content.Label.SplitRecord.EarlierAcctCode'] = 'Código de cuenta del registro anterior';
lang_es['Content.Label.SplitRecord.EarlierEnd'] = 'Final del registro anterior';
lang_es['Content.Label.SplitRecord.EarlierStart'] = 'Inicio de registro anterior';
lang_es['Content.Label.SplitRecord.LaterAcctCode'] = 'Código de cuenta del registro posterior';
lang_es['Content.Label.SplitRecord.LaterEnd'] = 'Final de registro posterior';
lang_es['Content.Label.SplitRecord.LaterStart'] = 'Inicio de registro posterior';
lang_es['Content.Label.Standard'] = 'Estándar';
lang_es['Content.Label.Start'] = 'Comienzo';
lang_es['Content.Label.StartDate'] = 'Fecha de Inicio';
lang_es['Content.Label.StartTime'] = 'Hora de Inicio';
lang_es['Content.Label.Started'] = 'Empezado';
lang_es['Content.Label.State'] = 'Estado';
lang_es['Content.Label.Status'] = 'Estado';
lang_es['Content.Label.StatusReport.Daily'] = 'Diario';
lang_es['Content.Label.StatusReport.Monthly'] = 'Mensual';
lang_es['Content.Label.StatusReport.Never'] = 'Nunca';
lang_es['Content.Label.StatusReport.Weekly'] = 'Semanal';
lang_es['Content.Label.Subdomain'] = 'Subdominio';
lang_es['Content.Label.Subscribed'] = 'Suscrito';
lang_es['Content.Label.Subscription'] = 'Suscripción';
lang_es['Content.Label.Supervisor'] = 'Supervisor';
lang_es['Content.Label.SupervisorSignature'] = 'Firma del Supervisor';
lang_es['Content.Label.Support'] = 'Soporte';
lang_es['Content.Label.SupportCenter'] = 'Centro de Soporte';
lang_es['Content.Label.Suspended'] = 'Suspendido';
lang_es['Content.Label.SystemMessage'] = 'Mensaje del sistema';
lang_es['Content.Label.SystemNote'] = 'Nota del Sistema';
lang_es['Content.Label.TZ'] = 'ZH';
lang_es['Content.Label.TakePhoto'] = 'Tomar Foto';
lang_es['Content.Label.Teamwork'] = 'Trabajo en Equipo';
lang_es['Content.Label.TerminationDate'] = 'Fecha del Despido';
lang_es['Content.Label.TerminationLetter'] = 'Carta de Despido';
lang_es['Content.Label.TheFollowingUsersAreIncludedInThisReport'] = 'Los siguientes usuarios están incluidos en este reporte:';
lang_es['Content.Label.ThereWasOneRecentChangeToTimesheet'] = 'Hubo un cambio reciente en la hoja de tiempo.';
lang_es['Content.Label.ThereWereXRecentChangeToTimesheet'] = 'Hubo {1} cambios recientes en la hoja de tiempo.';
lang_es['Content.Label.Time'] = 'Tiempo';
lang_es['Content.Label.TimeClass'] = 'Clasificación de Tiempo';
lang_es['Content.Label.TimeClasses'] = 'Clases de Tiempo';
lang_es['Content.Label.TimeClassification'] = 'Clasificación de Tiempo';
lang_es['Content.Label.TimeClassifications'] = 'Clasificaciones de Tiempo';
lang_es['Content.Label.TimeEntry'] = 'Ingreso de Tiempo';
lang_es['Content.Label.TimeRequest'] = 'Solicitud de Tiempo';
lang_es['Content.Label.TimeZone'] = 'Zona Horaria';
lang_es['Content.Label.TimeZoneAbbrev'] = 'ZH';
lang_es['Content.Label.Total'] = 'Total';
lang_es['Content.Label.TotalBill'] = 'Facturación Total';
lang_es['Content.Label.TotalCost'] = 'Costo Total';
lang_es['Content.Label.TotalExpenses'] = 'Gastos Totales';
lang_es['Content.Label.TotalFixedPay'] = 'Paga Fija Total';
lang_es['Content.Label.TotalHours'] = 'Total de Horas';
lang_es['Content.Label.TotalHoursAdded'] = 'Total de Horas Agregadas';
lang_es['Content.Label.TotalHoursDeducted'] = 'Total de Horas Deducidas';
lang_es['Content.Label.TotalMileage'] = 'Millaje Total';
lang_es['Content.Label.TotalOfAllHours'] = 'Total de Todas las Horas';
lang_es['Content.Label.TotalPay'] = 'Pago Total';
lang_es['Content.Label.TotalPayableHours'] = 'Total de Horas Pagables';
lang_es['Content.Label.TotalPayroll'] = 'Nómina Total';
lang_es['Content.Label.TotalRecords'] = 'Total de Registros';
lang_es['Content.Label.TotalTime'] = 'Tiempo Total';
lang_es['Content.Label.TotalVariablePay'] = 'Total de Paga Variable';
lang_es['Content.Label.Totals'] = 'Totales';
lang_es['Content.Label.TrackBalancesAnotherWay'] = 'No, voy a llevar la cuenta de saldos acumulados en otro sistema.';
lang_es['Content.Label.TrackBalancesInTimesheets'] = 'Sí, voy a llevar la cuenta de saldos acumulados en Timesheets.com.';
lang_es['Content.Label.TransactionDate'] = 'Fecha de Transacción';
lang_es['Content.Label.TransactionID'] = 'ID de Transacción';
lang_es['Content.Label.TrialExpiration'] = 'Expiración de Periodo de Prueba';
lang_es['Content.Label.Type'] = 'Tipo';
lang_es['Content.Label.Unapproved'] = 'No Aprobado';
lang_es['Content.Label.UniqueIdentifier'] = 'Identificador Único';
lang_es['Content.Label.Unknown'] = 'Desconocido';
lang_es['Content.Label.Unread'] = 'No Leído';
lang_es['Content.Label.UnscheduledShift'] = 'Turno No Programado';
lang_es['Content.Label.UpdateAccountCode'] = 'Actualizar Código de Cuenta';
lang_es['Content.Label.Updated'] = 'Actualizado';
lang_es['Content.Label.UploadImportFile'] = 'Subir Archivo de Importación';
lang_es['Content.Label.UploadPhoto'] = 'Subir Foto';
lang_es['Content.Label.UploadReceipt'] = 'Subir Recibo';
lang_es['Content.Label.UploadReceipt.JPGPDF'] = 'JPG o PDF';
lang_es['Content.Label.UseDefaultRate'] = 'Usar Tarifa Predeterminada';
lang_es['Content.Label.UseWith'] = 'Usar con';
lang_es['Content.Label.User'] = 'Usuario';
lang_es['Content.Label.UserAccess.Administrator'] = 'Administrador';
lang_es['Content.Label.UserAccess.Employee'] = 'Empleado';
lang_es['Content.Label.UserAccess.SiteAdmin'] = 'SysAdmin';
lang_es['Content.Label.UserAccess.Supervisor'] = 'Supervisor';
lang_es['Content.Label.UserAccess.Unknown'] = 'Desconocido';
lang_es['Content.Label.UserComments'] = 'Comentarios del Usuario';
lang_es['Content.Label.UserID'] = 'ID de Usuario';
lang_es['Content.Label.UserInfo'] = 'Información de Usuario';
lang_es['Content.Label.UserLicenses'] = 'Licencias de Usuario';
lang_es['Content.Label.UserNumber'] = 'Número de Usaurio';
lang_es['Content.Label.UserStatus.Active'] = 'Activo';
lang_es['Content.Label.UserStatus.Archived'] = 'Archived';
lang_es['Content.Label.UserStatus.Unknown'] = 'Desconocido';
lang_es['Content.Label.Username'] = 'Nombre de usuario';
lang_es['Content.Label.Users'] = 'Usuarios';
lang_es['Content.Label.UsersByName'] = 'Usuarios por Nombre';
lang_es['Content.Label.UsersBySupervisor'] = 'Usuarios por Supervisor';
lang_es['Content.Label.Validated'] = 'Validado';
lang_es['Content.Label.VariablePay'] = 'Paga Variable';
lang_es['Content.Label.VariablePayRate'] = 'Tarifa de Pago Variable';
lang_es['Content.Label.VariablePayTotal'] = 'Total de Pago Variable';
lang_es['Content.Label.VariableRate'] = 'Tarifa Variable';
lang_es['Content.Label.Vendor'] = 'Proveedor';
lang_es['Content.Label.Vendors'] = 'Proveedores';
lang_es['Content.Label.Version'] = 'Versión';
lang_es['Content.Label.ViewExpenses'] = 'Ver Gastos';
lang_es['Content.Label.ViewHourlyTimesheet'] = 'Ver Hoja de Tiempo por Hora';
lang_es['Content.Label.ViewProjectTimers'] = 'Ver Temporizadores de Proyecto';
lang_es['Content.Label.ViewProjectTimesheet'] = 'Ver Horas de Proyecto';
lang_es['Content.Label.ViewRecordsFrom'] = 'Ver registros de';
lang_es['Content.Label.ViewSettings'] = 'Ver Ajustes';
lang_es['Content.Label.WorkDate'] = 'Fecha de Trabajo';
lang_es['Content.Label.WorkWeekDelineator'] = 'Delineador de Semana Laboral';
lang_es['Content.Label.WorkersCompJobCode'] = 'Código de Compensación de Trabajadores';
lang_es['Content.Label.WritePermission'] = 'Permiso de Escritura';
lang_es['Content.Label.XActive'] = '{1,number,#} Activos';
lang_es['Content.Label.XActiveUsers'] = '{1,number,#} Usuarios Activos';
lang_es['Content.Label.XPayrollReports'] = '{1,number,#} Reporte(s) de Nómina';
lang_es['Content.Label.Yes'] = 'S­í';
lang_es['Content.Label.YesLimitTo'] = 'Sí,Limite a';
lang_es['Content.Label.Your'] = 'Tu';
lang_es['Content.Label.YourContactPhone'] = 'Tu Teléfono de Contacto';
lang_es['Content.Label.YourKey'] = 'Tu Llave';
lang_es['Content.Label.YourSupervisor'] = 'Tu Supervisor';
lang_es['Content.Label.YourToken'] = 'Tu Token';
lang_es['Content.Label.ZipCode'] = 'Código Postal';
lang_es['Content.Legend'] = 'Leyenda';
lang_es['Content.Legend.AccountAdmin'] = 'Administrador de Cuenta';
lang_es['Content.Legend.ActiveAccount'] = 'Cuenta Activa';
lang_es['Content.Legend.ActiveAccountEmployeeLoginDisabled'] = 'Cuenta activa con inicio de sesión de empleado deshabilitado';
lang_es['Content.Legend.ActiveAccountUserLoginDisabled'] = 'Cuenta activa con inicio de sesión de usuario deshabilitado';
lang_es['Content.Legend.Add'] = 'Agregar';
lang_es['Content.Legend.AddRecord'] = 'Agregar Registro';
lang_es['Content.Legend.Approved'] = 'Aprobado';
lang_es['Content.Legend.Archive'] = 'Archivo';
lang_es['Content.Legend.ArchiveAllItems'] = 'Archivar Todos los Elementos';
lang_es['Content.Legend.Archived'] = 'Archivado';
lang_es['Content.Legend.ArchivedUser'] = 'Usario Archivado';
lang_es['Content.Legend.Billable'] = 'Facturable';
lang_es['Content.Legend.ClockUserOut'] = 'Marcar Salida del Usuario';
lang_es['Content.Legend.Contractor'] = 'Contratista';
lang_es['Content.Legend.CurrentlyClockedIn'] = 'Registrado Actualmente';
lang_es['Content.Legend.DashTotalsText'] = 'Los totales incluyen registros no archivados/no pagados en los últimos 90 días.';
lang_es['Content.Legend.DashboardTotalsText'] = 'Los totales incluyen registros no archivados/no pagados en los últimos 90 días.';
lang_es['Content.Legend.DefaultItem'] = 'El Elemento predeterminado no se puede eliminar, archivar ni restringir.';
lang_es['Content.Legend.Delete'] = 'Eliminar';
lang_es['Content.Legend.DeleteAllItems'] = 'Eliminar todos los elementos';
lang_es['Content.Legend.Deleted'] = 'Eliminado';
lang_es['Content.Legend.DisabledDueToFutureDateRestriction'] = 'Deshabilitado Debido a Restricción de Fecha Futura';
lang_es['Content.Legend.DisabledDueToTimeEntryCutoff'] = 'Desactivado Debido a Corte de Ingreso de Tiempo';
lang_es['Content.Legend.Download'] = 'Descargar';
lang_es['Content.Legend.DownloadItemList'] = 'Descargar Lista de Artículos';
lang_es['Content.Legend.Early'] = 'Temprano';
lang_es['Content.Legend.Edit'] = 'Editar';
lang_es['Content.Legend.EditItem'] = 'Editar Artículo';
lang_es['Content.Legend.EditRequest'] = 'Editar Solicitud';
lang_es['Content.Legend.Employee'] = 'Empleado';
lang_es['Content.Legend.EmployeeClockedIn'] = 'El Usario Está Registrado';
lang_es['Content.Legend.EmployeeClockedIn8Hours'] = 'Empleado registrado por 8+ horas';
lang_es['Content.Legend.EmployeeClockedInTimerOpen'] = 'El empleado está registrado / tiene temporizadores de proyecto activos';
lang_es['Content.Legend.EmployeeOpenTimer'] = 'El usuario tiene temporizadores activos';
lang_es['Content.Legend.Export'] = 'Exportar';
lang_es['Content.Legend.ExportReport'] = 'Reporte de Exportacion';
lang_es['Content.Legend.GenericDelete'] = 'Eliminar';
lang_es['Content.Legend.GenericEdit'] = 'Editar';
lang_es['Content.Legend.GenericMerge'] = 'Unir';
lang_es['Content.Legend.GenericView'] = 'Ver';
lang_es['Content.Legend.HasReceipt'] = 'Tiene recibo';
lang_es['Content.Legend.Hint'] = 'Pista';
lang_es['Content.Legend.Holiday'] = 'Feriado (HOL)';
lang_es['Content.Legend.Import'] = 'Importar';
lang_es['Content.Legend.Late'] = 'Tarde';
lang_es['Content.Legend.Legend'] = 'Leyenda';
lang_es['Content.Legend.Locked'] = 'Bloqueado';
lang_es['Content.Legend.ManualClockIn'] = 'Marcar Entrada manualmente';
lang_es['Content.Legend.ManualClockOut'] = 'Marcar Salida manualmente';
lang_es['Content.Legend.MarkAllAsRead'] = 'Marcar Todo como Leido';
lang_es['Content.Legend.Merge'] = 'Unir';
lang_es['Content.Legend.MergeProject'] = 'Unir Proyecto';
lang_es['Content.Legend.New'] = 'Nuevo';
lang_es['Content.Legend.Next'] = 'Siguiente';
lang_es['Content.Legend.None'] = 'Ninguno';
lang_es['Content.Legend.NotAvailable'] = 'No Disponible';
lang_es['Content.Legend.NotRequired'] = 'No requerido';
lang_es['Content.Legend.NotSignedNotApproved'] = 'Uno o más registros no firmados/aprobados';
lang_es['Content.Legend.OnBreak'] = 'El usuario está en descanso';
lang_es['Content.Legend.OnTime'] = 'A Tiempo';
lang_es['Content.Legend.Other'] = 'Otro';
lang_es['Content.Legend.Paid'] = 'Pagado';
lang_es['Content.Legend.PauseTimer'] = 'Pausar Temporizador';
lang_es['Content.Legend.Paused'] = 'Pausado';
lang_es['Content.Legend.PausedTimer'] = 'Temporizador en Pausa';
lang_es['Content.Legend.PayrollAppendedDate'] = '[adicional {1}]';
lang_es['Content.Legend.PendingAlert'] = 'Alerta Pendiente';
lang_es['Content.Legend.PendingAlertEdit'] = 'Alerta de Edición Pendiente';
lang_es['Content.Legend.PhotoStamp'] = 'Sello de foto';
lang_es['Content.Legend.PostDated'] = 'Publicación con Fecha';
lang_es['Content.Legend.Previous'] = 'Anterior';
lang_es['Content.Legend.PrimaryAccountAdmin'] = 'Administrador Principal de Cuenta';
lang_es['Content.Legend.Print.Admin'] = 'Adm = Administrador';
lang_es['Content.Legend.Print.Contractor'] = 'C = Contratista';
lang_es['Content.Legend.Print.Employee'] = 'E = Empleado';
lang_es['Content.Legend.Print.Hourly'] = 'Hrly = Por Hora';
lang_es['Content.Legend.Print.Other'] = 'O = Otro';
lang_es['Content.Legend.Print.Salaried'] = 'Sal = Asalariado';
lang_es['Content.Legend.Print.Student'] = 'S = Estudiante';
lang_es['Content.Legend.Print.Supervisor'] = 'Sup = Supervisor';
lang_es['Content.Legend.Print.Volunteer'] = 'Voluntario';
lang_es['Content.Legend.ReOpenPayrollPeriod'] = 'Reabrir el Periodo de Nómina';
lang_es['Content.Legend.ReactivateUser'] = 'Reactivar Usuario';
lang_es['Content.Legend.Read'] = 'Leído';
lang_es['Content.Legend.RecentlyModifiedBySupervisor'] = 'Modificado Recientemente Por Un Supervisor';
lang_es['Content.Legend.Reconciled'] = 'Conciliado';
lang_es['Content.Legend.Refresh'] = 'Actualizar';
lang_es['Content.Legend.RequestedChange'] = 'Cambio Solicitado';
lang_es['Content.Legend.RestoreAllItems'] = 'Restaurar Todos los Elementos';
lang_es['Content.Legend.Running'] = 'En Funcionamiento';
lang_es['Content.Legend.RunningTimer'] = 'Temporizador Activo';
lang_es['Content.Legend.Salaried'] = 'Asalariado';
lang_es['Content.Legend.Settings'] = 'Ajustes';
lang_es['Content.Legend.Split'] = 'Separar';
lang_es['Content.Legend.SplitPaid'] = 'Pago Dividido';
lang_es['Content.Legend.StartTimer'] = 'Iniciar Temporizador';
lang_es['Content.Legend.StopTimer'] = 'Detener Temporizador';
lang_es['Content.Legend.Student'] = 'Estudiante';
lang_es['Content.Legend.SupervisorManager'] = 'Supervisor/Gerente';
lang_es['Content.Legend.SupervisorSettings.PermissionIsDependent'] = 'El acceso a este permiso depende de otros permisos en esta página.';
lang_es['Content.Legend.SupervisorSettings.PermissionIsDisabled'] = 'Este permiso está deshabilitado debido a restricciones en un nivel de acceso superior.';
lang_es['Content.Legend.SupervisorSettings.PermissionIsDisabledForSubs'] = 'Este permiso, cuando se deshabilita, también se deshabilitará para los supervisores subordinados de este usuario.';
lang_es['Content.Legend.Title.HourlyTimesheetSubTitle'] = 'Es posible que algunos totales diarios por hora <i class="font-red">se muestren en rojo</i> para ayudar a evitar horas extras.';
lang_es['Content.Legend.Unarchive'] = 'Desarchivar';
lang_es['Content.Legend.Unread'] = 'No Leído';
lang_es['Content.Legend.UserClockedIn'] = 'El usuario está registrado';
lang_es['Content.Legend.UserClockedIn8Hours'] = 'Usuario registrado por 8+ horas';
lang_es['Content.Legend.UserOpenTimer'] = 'El usuario tiene temporizadores activos';
lang_es['Content.Legend.UserSigned'] = 'Firmado por Usuario';
lang_es['Content.Legend.View'] = 'Ver';
lang_es['Content.Legend.ViewDetails'] = 'Ver Detalles/Notas';
lang_es['Content.Legend.ViewRecord'] = 'Ver Registro';
lang_es['Content.Legend.ViewReport'] = 'Ver Reporte';
lang_es['Content.Legend.Volunteer'] = 'Voluntario';
lang_es['Content.Link.AddMany'] = 'agregar muchos...';
lang_es['Content.Link.Back'] = 'Volver';
lang_es['Content.Link.BackToCalendarsAndSchedules'] = 'Volver a Calendarios y Horarios';
lang_es['Content.Link.BackToDashboard'] = 'Volver al Panel';
lang_es['Content.Link.BackToListing'] = 'Volver al Listado';
lang_es['Content.Link.BackToLogin'] = 'Volver a Inicio de Sesión';
lang_es['Content.Link.BackToReports'] = 'Volver a Reportes';
lang_es['Content.Link.BackToSettings'] = 'Volver a Ajustes';
lang_es['Content.Link.BackToTimesheet'] = 'Volver a Hoja de Horas';
lang_es['Content.Link.Cancel'] = 'Cancelar';
lang_es['Content.Link.CancelBackToCalendars'] = 'Cancelar / Volver a Calendarios';
lang_es['Content.Link.Close'] = 'Cerrar';
lang_es['Content.Link.CloseAll'] = 'Cierra todo';
lang_es['Content.Link.ContactExternal'] = 'Contacto';
lang_es['Content.Link.ContactUs'] = 'Contáctenos';
lang_es['Content.Link.CustomDateRange'] = 'Intervalo de fechas personalizado';
lang_es['Content.Link.DeselectAll'] = 'Deseleccionar Todo';
lang_es['Content.Link.DesktopView'] = 'Vista de Escritorio';
lang_es['Content.Link.DownloadImportFile'] = 'Descargar Archivo de Importación';
lang_es['Content.Link.DownloadTemplateFileWithHeader'] = 'Descargar Archivo de Plantilla con Encabezado';
lang_es['Content.Link.EditThisRecord'] = 'Editar Este Registro';
lang_es['Content.Link.ExitSetupWizard'] = 'Salir del Asistente de Configuración';
lang_es['Content.Link.GetAnAccount'] = 'Obtenga una Cuenta';
lang_es['Content.Link.Go'] = 'Ir';
lang_es['Content.Link.HourlyTimesheetReport'] = 'Reporte Personalizable de Tiempo por Hora';
lang_es['Content.Link.ImportFile'] = 'Importar Archivo';
lang_es['Content.Link.LearnMore'] = 'Leer Más';
lang_es['Content.Link.LoginToCustomerAccts'] = 'Iniciar Sesión en Cuentas de Clientes';
lang_es['Content.Link.MileageAndExpensesReport'] = 'Reporte Personalizable de Gastos y Millaje';
lang_es['Content.Link.OpenAll'] = 'Abrir todo';
lang_es['Content.Link.PartnerAgreement'] = 'Acuerdo de Socio';
lang_es['Content.Link.PartnerEnroll'] = 'Inscríbase en el Programa de Socios';
lang_es['Content.Link.PartnerInfo'] = 'Socios';
lang_es['Content.Link.Password'] = 'Contraseña';
lang_es['Content.Link.ProjectTimesheetReport'] = 'Reporte Personalizable de Tiempo de Proyecto';
lang_es['Content.Link.QBOReviewListItems'] = 'Elementos de la lista de revisión';
lang_es['Content.Link.RemoveFailed'] = 'Remover Fallidos';
lang_es['Content.Link.ReportClosedPayroll'] = 'Reportes de Periodo de Nómina Cerrados';
lang_es['Content.Link.ReportCoCalendar'] = 'Calendario de la Compañía';
lang_es['Content.Link.ReportCustExpense'] = 'Reportes Personalizables';
lang_es['Content.Link.ReportCustHourly'] = 'Reportes Personalizables de Horas';
lang_es['Content.Link.ReportCustHourlyProcessed'] = 'Reporte Personalizable de Horas Procesadas';
lang_es['Content.Link.ReportCustProject'] = 'Reportes Personalizables';
lang_es['Content.Link.ReportEmpInfo'] = 'Información de Usuario';
lang_es['Content.Link.ReportLocationTardy'] = 'Reportes de Ubicación y Tardanzas';
lang_es['Content.Link.ReportOverlappingRecords'] = 'Registros Superpuestos';
lang_es['Content.Link.ReportPreviewPayroll'] = 'Vista Previa del Periodo Actual (con sobretiempo)';
lang_es['Content.Link.ReportQBOExpense'] = 'Gastos';
lang_es['Content.Link.ReportQBOHourly'] = 'Tiempo por Hora';
lang_es['Content.Link.ReportQBOProject'] = 'Tiempo de Proyecto';
lang_es['Content.Link.ReportRecordHistory'] = 'Reporte de Historial de Registros';
lang_es['Content.Link.Resend'] = 'Reenviar';
lang_es['Content.Link.Reset'] = 'Reiniciar';
lang_es['Content.Link.Retry'] = 'Reintentar';
lang_es['Content.Link.ReturnToHomepage'] = 'Regresar a la Página Principal';
lang_es['Content.Link.ReturnToLogin'] = 'Volver al Inicio de Sesión';
lang_es['Content.Link.SelectAll'] = 'Seleccionar Todo';
lang_es['Content.Link.SelectAllGroups'] = 'Seleccionar Todos los Grupos';
lang_es['Content.Link.Timesheet'] = 'Hoja de Tiempo';
lang_es['Content.Link.UpdatePW'] = 'Actualizar Contraseña';
lang_es['Content.Link.UseGlobalEntry'] = 'Usar Ingreso Global';
lang_es['Content.Link.UseSingleEntry'] = 'Usar Ingreso Única';
lang_es['Content.Link.VisitSupport'] = 'Visite nuestra documentación de soporte y ayuda para obtener la información más actualizada.';
lang_es['Content.Misc.AccountAccessIsDisabled'] = 'El acceso a la cuenta está actualmente deshabilitado.';
lang_es['Content.Misc.AccountDeletion.Confirmed'] = 'Su cuenta ha sido confirmada para eliminación y todos los datos se eliminarán permanentemente a más tardar 30 días después de la finalización de su servicio. Gracias.';
lang_es['Content.Misc.AccountDeletion.NotConfirmed'] = 'El código de confirmación proporcionado no es correcto. Si cree que se trata de un error, póngase en contacto con el servicio de atención al cliente. Gracias.';
lang_es['Content.Misc.And'] = 'Y';
lang_es['Content.Misc.CharsLeft'] = 'caracteres restantes';
lang_es['Content.Misc.ClickToViewImage'] = 'Clic para ver imagen';
lang_es['Content.Misc.ConflictingPaidRecords'] = 'Registros Pagados en Conflicto';
lang_es['Content.Misc.ConflictingUnpaidRecords'] = 'ID de Registros No Pagados en Conflicto';
lang_es['Content.Misc.Contact.BusinessHourTimes'] = '{1} - {2} Pacífico';
lang_es['Content.Misc.Contact.BusinessHours'] = 'Horas de Trabajo';
lang_es['Content.Misc.Contact.QuestionsCommentsSuggestions'] = 'Preguntas, Comentarios o Sugerencias';
lang_es['Content.Misc.Contact.ScheduleATimeToMeet'] = 'Programe una hora para reunirse con una representante.';
lang_es['Content.Misc.Contact.ScheduleAnAppointment'] = 'Agendar una Reunion';
lang_es['Content.Misc.Contact.SendToSupervisor'] = 'Enviar este mensaje a mi supervisor.';
lang_es['Content.Misc.Contact.SendToSupport'] = 'Envíe este mensaje a {1} servicio al cliente.';
lang_es['Content.Misc.Contact.SendUsAnEmail'] = 'Envianos un Email';
lang_es['Content.Misc.Contact.YourAccountRep'] = 'Su representante de cuenta es {1}.';
lang_es['Content.Misc.DefaultSignatureText'] = 'Al firmar arriba, reconozco y certifico que la información contenida en este documento es verdadera y precisa.';
lang_es['Content.Misc.FirstTimeLogin.ChooseATour'] = 'Elija el tour que se aplique a usted:';
lang_es['Content.Misc.FirstTimeLogin.WonderingWhatToDo'] = '¿Se pregunta qué hacer primero? ¡Realice el recorrido de 2 minutos!';
lang_es['Content.Misc.FutureLineInfo'] = 'La línea futura marca las 12:00am de mañana.';
lang_es['Content.Misc.Item.AccountCode.CannotBeArchived'] = 'El Código de Cuenta tiene horas nuevas. No se puede archivar hasta que se facturen las horas.';
lang_es['Content.Misc.Item.AccountCode.CannotBeDeleted'] = 'El Código de Cuenta tiene horas facturadas y no puede ser eliminado. Solo se puede archivar después de facturar las horas.';
lang_es['Content.Misc.Item.AccountCode.IsDefaultItem'] = 'Este es el código de cuenta predeterminado. Siempre está disponible y no se puede eliminar ni archivar.';
lang_es['Content.Misc.Item.AfterSuccessfulMerge'] = 'Después de completar la fusión:';
lang_es['Content.Misc.Item.ArchiveItem'] = 'Archivar este elemento';
lang_es['Content.Misc.Item.Customer.CannotBeArchived'] = 'El Cliente tiene horas nuevas. No se puede archivar hasta que se facturen las horas y se concilien los gastos pendientes asignados.';
lang_es['Content.Misc.Item.Customer.CannotBeDeleted'] = 'El Cliente tiene horas facturadas y no puede ser eliminado. Solo puede ser archivado despues de facturar las horas y conciliar los gastos abiertos.';
lang_es['Content.Misc.Item.Customer.IsDefaultItem'] = 'Este es el Cliente predeterminado. Siempre está disponible y no se puede eliminar ni archivar.';
lang_es['Content.Misc.Item.DeleteItem'] = 'Eliminar este elemento';
lang_es['Content.Misc.Item.DoNothing'] = 'No hacer nada';
lang_es['Content.Misc.Item.Event.CannotBeArchived'] = 'Este Evento tiene gastos no conciliados. No puede ser archivado hasta que esos gastos sean conciliados.';
lang_es['Content.Misc.Item.Event.CannotBeDeleted'] = 'Este Evento no se puede eliminar porque hay gastos previamente archivados.';
lang_es['Content.Misc.Item.Event.IsDefaultItem'] = 'Este es el Evento predeterminado. Siempre está disponible y no se puede eliminar ni archivar.';
lang_es['Content.Misc.Item.Project.CannotBeArchived'] = 'El Proyecto tiene horas nuevas. No se puede archivar hasta que se facturen las horas y se concilien los gastos pendientes asignados.';
lang_es['Content.Misc.Item.Project.CannotBeDeleted'] = 'El Proyecto tiene horas facturadas y no puede ser eliminado. Solo se puede archivar después de facturar las horas y conciliar los gastos abiertos asignados.';
lang_es['Content.Misc.Item.Project.IsDefaultItem'] = 'Este es el Proyecto predeterminado. Siempre está disponible y no se puede eliminar ni archivar.';
lang_es['Content.Misc.Item.Vendor.CannotBeArchived'] = 'Este Proveedor tiene gastos no conciliados. No se puede archivar hasta que se concilien esos gastos.';
lang_es['Content.Misc.Item.Vendor.CannotBeDeleted'] = 'Este Proveedor no se puede eliminar porque hay gastos previamente archivados.';
lang_es['Content.Misc.Item.Vendor.IsDefaultItem'] = 'Este es el Proveedor predeterminado. Siempre está disponible y no se puede eliminar ni archivar.';
lang_es['Content.Misc.ItemOrSelectionCurrentlyUnavailable'] = 'Este artículo o selección no está disponible actualmente.';
lang_es['Content.Misc.JPGorPDF'] = 'jpg o pdf';
lang_es['Content.Misc.Or'] = 'O';
lang_es['Content.Misc.PayrollSummaryUnavailable'] = 'Resumen de Nómina Actualmente No Disponible';
lang_es['Content.Misc.ProjectWeekCopySelection.Part1'] = 'Está a punto de copiar estos registros a la próxima semana. No se copiarán el tiempo archivado, el tiempo que excedería los límites diarios y los registros con alertas.';
lang_es['Content.Misc.ProjectWeekCopySelection.Part2'] = '¿Cómo le gustaría manejar la copia de registros con clientes u otras selecciones que ya no son opciones válidas?';
lang_es['Content.Misc.QBO.AccountDisconnectedSuccessfully'] = 'Timesheets.com se desconectó con éxito de QuickBooks Online.';
lang_es['Content.Misc.Questions'] = '¿Preguntas?';
lang_es['Content.Misc.ReferralProgramTagline'] = 'Las referencias recibirán un correo electrónico mencionando su nombre e invitándolos a probar Timesheets.com. Recibirá una tarjeta de regalo de Amazon de $25 90 días después de que la referencia se convierta en un cliente activo, siempre que el cliente permanezca con nosotros durante el periodo de 90 días.';
lang_es['Content.Misc.Setting.AutoAdjustForDST'] = '¿Ajustar automáticamente al Horario de Verano?';
lang_es['Content.Misc.VisitOurSupportSite'] = 'Visite nuestro sitio de soporte para obtener detalles completos del programa.';
lang_es['Content.Misc.WantToTestSampleAccount'] = '¿Quiere probar una cuenta de muestra?';
lang_es['Content.Misc.WeAppreciateYourEfforts'] = 'Gana $25 por cada recomendación';
lang_es['Content.Month.Full.April'] = 'Abril';
lang_es['Content.Month.Full.August'] = 'Agosto';
lang_es['Content.Month.Full.December'] = 'Diciembre';
lang_es['Content.Month.Full.February'] = 'Febrero';
lang_es['Content.Month.Full.January'] = 'Enero';
lang_es['Content.Month.Full.July'] = 'Julio';
lang_es['Content.Month.Full.June'] = 'Junio';
lang_es['Content.Month.Full.March'] = 'Marzo';
lang_es['Content.Month.Full.May'] = 'Mayo';
lang_es['Content.Month.Full.November'] = 'Noviembre';
lang_es['Content.Month.Full.October'] = 'Octubre';
lang_es['Content.Month.Full.September'] = 'Septiembre';
lang_es['Content.Month.Short.April'] = 'abr';
lang_es['Content.Month.Short.August'] = 'agusto';
lang_es['Content.Month.Short.December'] = 'dic';
lang_es['Content.Month.Short.February'] = 'feb';
lang_es['Content.Month.Short.January'] = 'enero';
lang_es['Content.Month.Short.July'] = 'jul';
lang_es['Content.Month.Short.June'] = 'jun';
lang_es['Content.Month.Short.March'] = 'marzo';
lang_es['Content.Month.Short.May'] = 'mayo';
lang_es['Content.Month.Short.November'] = 'nov';
lang_es['Content.Month.Short.October'] = 'oct';
lang_es['Content.Month.Short.September'] = 'set';
lang_es['Content.Notification.1.Description'] = 'Envía una notificación cuando un usuario que le reporta directamente marca salida.';
lang_es['Content.Notification.1.Title'] = 'Los usuarios que me informan directamente marcan salida';
lang_es['Content.Notification.10.Description'] = 'Envía una notificación tras haber permanecido registrado por mas horas que la configuración de turno predeterminada.';
lang_es['Content.Notification.10.Title'] = 'Estoy registrado por más tiempo que mi turno';
lang_es['Content.Notification.11.Description'] = 'Le envía una notificación cuando un empleado asignado elige notificarle que ha completado una acción como aprobar, archivar o conciliar registros.';
lang_es['Content.Notification.11.Title'] = 'Un usuario completa una acción electiva';
lang_es['Content.Notification.2.Description'] = 'Envía una notificación cuando un usuario que le reporta directamente a usted crea una nueva alerta de tiempo.';
lang_es['Content.Notification.2.Title'] = 'Se crean nuevas alertas de tiempo';
lang_es['Content.Notification.3.Description'] = 'Le envía una notificación cuando un usuario que le reporta directamente a usted ha creado una nueva alerta de tiempo de proyecto.';
lang_es['Content.Notification.3.Title'] = 'Se crean nuevas alertas de proyecto';
lang_es['Content.Notification.4.Description'] = 'Esta función le permite a usted y a sus supervisores la posibilidad de enviar un enlace para marcar salida sin tener que iniciar sesión. Tenga en cuenta que es posible que esta función no funcione si existen determinadas configuraciones de seguridad.';
lang_es['Content.Notification.4.Title'] = 'Estoy registrado para recibir un enlace de salida rápida';
lang_es['Content.Notification.5.Description'] = 'Envía una notificación cuando un usuario que le reporta directamente a usted marca entrada.';
lang_es['Content.Notification.5.Title'] = 'Los usuarios que directamente me reportan marcan entrada';
lang_es['Content.Notification.6.Description'] = 'Envía una notificación cuando cualquier usuario que le reporta directamente marca salida y crea un registro que excede su turno de trabajo.';
lang_es['Content.Notification.6.Title'] = 'Los usuarios que me reportan directamente exceden su turno de trabajo al marcar la salida';
lang_es['Content.Notification.7.Description'] = 'Envía una notificación cuando se marca la salida automáticamente por exceder su turno de trabajo. Solo habilitado cuando la salida automática está habilitada para la cuenta de la empresa.';
lang_es['Content.Notification.7.Title'] = 'Me marcan la salida automáticamente por exceder mi turno de trabajo';
lang_es['Content.Notification.8.Description'] = 'Envía una notificación cuando cualquier usuario que reporta directamente a usted marca salida automáticamente por exceder su turno de trabajo. Solo habilitado cuando la salida automática está habilitado para la cuenta de la empresa.';
lang_es['Content.Notification.8.Title'] = 'A los usuarios que me reportan directamente se les marca la salida automáticamente por exceder su turno de trabajo';
lang_es['Content.Notification.9.Description'] = 'Le envía una notificación el último día del periodo de nómina actual. Solo habilitado si la cuenta de la empresa se ha configurado para usar periodos de nómina.';
lang_es['Content.Notification.9.Title'] = 'Es el último día del periodo de nómina actual';
lang_es['Content.Pagination.CurrentDate'] = 'Fecha Actual';
lang_es['Content.Pagination.DisplayingXToYOfZ'] = 'Mostrando {1} {2} a {3} de {4}';
lang_es['Content.Pagination.LabelX-YOfZ'] = '{1} {2} - {3} de {4}';
lang_es['Content.Pagination.LastWeek'] = 'La Semana Pasada';
lang_es['Content.Pagination.Next'] = 'Próxima';
lang_es['Content.Pagination.NextDate'] = 'Próxima Fecha';
lang_es['Content.Pagination.NextWeek'] = 'Próxima Semana';
lang_es['Content.Pagination.NextXY'] = 'Siguiente {1} {2}';
lang_es['Content.Pagination.Page'] = 'Página';
lang_es['Content.Pagination.PageXofY'] = 'Página {1} de {2}';
lang_es['Content.Pagination.PerPage'] = 'Por Página';
lang_es['Content.Pagination.Previous'] = 'Anterior';
lang_es['Content.Pagination.PreviousDate'] = 'Fecha Anterior';
lang_es['Content.Pagination.PreviousXY'] = '{1} {2} Anterior';
lang_es['Content.Pagination.Show'] = 'Mostrar';
lang_es['Content.Pagination.StartOfWeek'] = 'Inicio de Semana';
lang_es['Content.Pagination.XOfYRecordsProcessed'] = '{1} / {2} registros procesados';
lang_es['Content.Pagination.XY'] = '{1} {2}';
lang_es['Content.Report.Name.PayrollReport'] = 'Reporte de Nómina';
lang_es['Content.Report.Name.ProcessedPayrollReportHours'] = 'Reporte de Horas de Nómina Procesadas';
lang_es['Content.ReportFileFormats.GroupType'] = 'Tipo de Grupo';
lang_es['Content.ReportFileFormats.GroupType.AccountCode'] = 'Código de Cuenta';
lang_es['Content.ReportFileFormats.GroupType.Customer'] = 'Cliente';
lang_es['Content.ReportFileFormats.GroupType.Employee'] = 'Empleado';
lang_es['Content.ReportFileFormats.GroupType.Event'] = 'Evento';
lang_es['Content.ReportFileFormats.GroupType.None'] = 'Ninguno';
lang_es['Content.ReportFileFormats.GroupType.Project'] = 'Proyecto';
lang_es['Content.ReportFileFormats.GroupType.Vendor'] = 'Proveedor';
lang_es['Content.ReportFileFormats.Instruction'] = 'El formato de archivo \'\'{1}\'\' no está disponible. Para descargar un archivo en este formato, genere un reporte usando los siguientes parámetros:';
lang_es['Content.ReportFileFormats.ReportFormat'] = 'Formato';
lang_es['Content.ReportFileFormats.ReportFormat.Detailed'] = 'Detallado';
lang_es['Content.ReportFileFormats.ReportFormat.Summary'] = 'Resumen';
lang_es['Content.ReportFileFormats.Title.Unsupported'] = 'No Admitido';
lang_es['Content.Select'] = 'Seleccionar';
lang_es['Content.Select.AccountCode'] = 'Código de Cuenta';
lang_es['Content.Select.Administrators'] = 'Administradors';
lang_es['Content.Select.Customer'] = 'Cliente';
lang_es['Content.Select.Date'] = 'Fecha';
lang_es['Content.Select.DirectReportsOnly'] = 'Sólo Subalternos Directos';
lang_es['Content.Select.DoNotLink'] = 'NO ENLACE';
lang_es['Content.Select.Dollars'] = 'Dólares';
lang_es['Content.Select.Downloads'] = '-- Descargas --';
lang_es['Content.Select.Employee'] = 'Empleado';
lang_es['Content.Select.Employees'] = 'Empleados';
lang_es['Content.Select.Event'] = 'Evento';
lang_es['Content.Select.Every'] = 'Todos';
lang_es['Content.Select.Everyone'] = 'Todos';
lang_es['Content.Select.JobTitle'] = 'Título Profesional';
lang_es['Content.Select.LocateUser'] = 'Localizar Usuario';
lang_es['Content.Select.Mileage'] = 'Millaje';
lang_es['Content.Select.None'] = 'Ninguno';
lang_es['Content.Select.One'] = 'Uno';
lang_es['Content.Select.Payroll.RunOnXForYToZ'] = 'Ejecutar {1} para {2} hasta {3}';
lang_es['Content.Select.PrimaryAccountAdmin'] = 'Administrador Principal de Cuenta';
lang_es['Content.Select.Project'] = 'Proyecto';
lang_es['Content.Select.SelectOne'] = '-- Seleccione Uno --';
lang_es['Content.Select.SelectUser'] = '-- Seleccionar Usuario --';
lang_es['Content.Select.Supervisor'] = 'Supervisor';
lang_es['Content.Select.Supervisors'] = 'Supervisors';
lang_es['Content.Select.User'] = 'Usuario';
lang_es['Content.Select.Vendor'] = 'Proveedor';
lang_es['Content.Tabs'] = 'Tiempo de Proyecto';
lang_es['Content.Tabs.AccountAlerts'] = 'Alertas de la Cuenta';
lang_es['Content.Tabs.AccountCodes'] = 'Códigos de Cuenta';
lang_es['Content.Tabs.AchievementDocs'] = 'Documentos de Logros';
lang_es['Content.Tabs.Active'] = 'Activo';
lang_es['Content.Tabs.AddMany'] = 'Agregar Varios';
lang_es['Content.Tabs.AddOne'] = 'Agregar Uno';
lang_es['Content.Tabs.AddReferral'] = 'Añadir Referido';
lang_es['Content.Tabs.All'] = 'Todo';
lang_es['Content.Tabs.AllActive'] = 'Todos los Activos';
lang_es['Content.Tabs.AnnouncementsFromTimesheets'] = 'Anuncios de Timesheets.com';
lang_es['Content.Tabs.AnnouncementsToEmployees'] = 'Anuncios a los Empleados';
lang_es['Content.Tabs.AnnouncementsToUsers'] = 'Anuncios de la Compañía';
lang_es['Content.Tabs.Archive'] = 'Archivado';
lang_es['Content.Tabs.Archived'] = 'Archivado';
lang_es['Content.Tabs.ArchivedUsers'] = 'Usuarios Archivados';
lang_es['Content.Tabs.AssignedEmployees'] = 'Usuarios Asignados';
lang_es['Content.Tabs.Audits'] = 'Áuditos';
lang_es['Content.Tabs.Balances'] = 'Balances';
lang_es['Content.Tabs.Calendars'] = 'Calendarios';
lang_es['Content.Tabs.Cancelled'] = 'Cancelado';
lang_es['Content.Tabs.ClosedPayrollPeriods'] = 'Periodos de Nómina Cerrados';
lang_es['Content.Tabs.ClosedPayrollPeriodsForUserX'] = 'Periodos de Nómina Cerrados para {1}';
lang_es['Content.Tabs.Commissions'] = 'Comisiones';
lang_es['Content.Tabs.Company'] = 'Compañía';
lang_es['Content.Tabs.CompanyCalendars'] = 'Calendarios de la Compañía';
lang_es['Content.Tabs.CompanyWallAll'] = 'Todos';
lang_es['Content.Tabs.CompanyWallStarredOnly'] = 'Solo Destacados';
lang_es['Content.Tabs.CustomCalendars'] = 'Calendarios Personalizados';
lang_es['Content.Tabs.Customers'] = 'Clientes';
lang_es['Content.Tabs.DataExport'] = 'Exportación de Datos';
lang_es['Content.Tabs.Expense'] = 'Gastos';
lang_es['Content.Tabs.ExpenseEntry'] = 'Ingreso de Gastos';
lang_es['Content.Tabs.Expenses'] = 'Gastos';
lang_es['Content.Tabs.Expired'] = 'Expirado';
lang_es['Content.Tabs.Export'] = 'Exportar';
lang_es['Content.Tabs.ExportSchedules'] = 'Exportar Horarios';
lang_es['Content.Tabs.Features'] = 'Funciones';
lang_es['Content.Tabs.Files'] = 'Archivos';
lang_es['Content.Tabs.Finished'] = 'Finalizado';
lang_es['Content.Tabs.Forms'] = 'Formularios';
lang_es['Content.Tabs.Free'] = 'Gratuito';
lang_es['Content.Tabs.HRDocs'] = 'Docs RH';
lang_es['Content.Tabs.Hourly'] = 'Por Hora';
lang_es['Content.Tabs.HourlyTime'] = 'Tiempo por Hora';
lang_es['Content.Tabs.HourlyTimeEntriesEdits'] = 'Ingresos y Ediciones de Tiempo por Hora';
lang_es['Content.Tabs.HumanResources'] = 'Recursos Humanos';
lang_es['Content.Tabs.InTrial'] = 'En Periodo de Prueba';
lang_es['Content.Tabs.InactiveUsers'] = 'Usuarios Inactivos';
lang_es['Content.Tabs.Info'] = 'Información';
lang_es['Content.Tabs.InvitedX'] = 'Invitado ({1})';
lang_es['Content.Tabs.Lists'] = 'Listas';
lang_es['Content.Tabs.Management'] = 'Administración';
lang_es['Content.Tabs.Memos'] = 'Memos';
lang_es['Content.Tabs.MessageBoard'] = 'Panel de Mensajes';
lang_es['Content.Tabs.MyCalendars'] = 'Mis Calendarios';
lang_es['Content.Tabs.NewExport'] = 'Nueva Exportación';
lang_es['Content.Tabs.Notes'] = 'Notas';
lang_es['Content.Tabs.Notifications'] = 'Notificaciones';
lang_es['Content.Tabs.OpenExpenses'] = 'Gastos Abiertos';
lang_es['Content.Tabs.Options'] = 'Opciones';
lang_es['Content.Tabs.PayStubs'] = 'Talonarios de Pago';
lang_es['Content.Tabs.Payroll'] = 'Nómina';
lang_es['Content.Tabs.Pending'] = 'Pendiente';
lang_es['Content.Tabs.PerformanceDocuments'] = 'Documentos de Desempeño';
lang_es['Content.Tabs.PreviousExports'] = 'Exportaciones Previas';
lang_es['Content.Tabs.Private'] = 'Privado';
lang_es['Content.Tabs.Profile'] = 'Perfil';
lang_es['Content.Tabs.Project'] = 'Proyecto';
lang_es['Content.Tabs.ProjectTime'] = 'Tiempo de Proyecto';
lang_es['Content.Tabs.ProjectTimeEntriesEdits'] = 'Ingresos y Ediciones de Tiempo de Proyecto';
lang_es['Content.Tabs.Projects'] = 'Proyectos';
lang_es['Content.Tabs.Public'] = 'Público';
lang_es['Content.Tabs.Reconciled'] = 'Conciliados';
lang_es['Content.Tabs.Schedules'] = 'Horarios';
lang_es['Content.Tabs.Security'] = 'Seguridad';
lang_es['Content.Tabs.Settings'] = 'Ajustes';
lang_es['Content.Tabs.SharedByMe'] = 'Compartido por Mí';
lang_es['Content.Tabs.Subscribed'] = 'Suscrito';
lang_es['Content.Tabs.SupervisorSettings'] = 'Ajustes de Supervisor';
lang_es['Content.Tabs.TimeAndExpense'] = 'Tiempo y Gastos';
lang_es['Content.Tabs.TimeEntry'] = 'Ingreso de Tiempo';
lang_es['Content.Tabs.TimeOff'] = 'Tiempo Libre';
lang_es['Content.Tabs.TimeOffEntriesRequests'] = 'Ingresos y Solicitudes de Tiempo Libre';
lang_es['Content.Tabs.Timers'] = 'Temporizadores';
lang_es['Content.Tabs.Timesheet'] = 'Hoja de Horas';
lang_es['Content.Tabs.TrackReferrals'] = 'Monitorear Referidos';
lang_es['Content.Tabs.User'] = 'Usuario';
lang_es['Content.Tabs.WeekView'] = 'Vista de Semana';
lang_es['Content.TimeClass.DoubleTime'] = 'Doble Tiempo';
lang_es['Content.TimeClass.DoubleTimeAbbrev'] = 'DT';
lang_es['Content.TimeClass.Holiday'] = 'Día Feriado';
lang_es['Content.TimeClass.HolidayAbbrev'] = 'FER';
lang_es['Content.TimeClass.Other1'] = 'Otro1';
lang_es['Content.TimeClass.Other1Abbrev'] = 'OT1';
lang_es['Content.TimeClass.Other2'] = 'Otro2';
lang_es['Content.TimeClass.Other2Abbrev'] = 'OT2';
lang_es['Content.TimeClass.Overtime'] = 'Sobretiempo';
lang_es['Content.TimeClass.OvertimeAbbrev'] = 'ST';
lang_es['Content.TimeClass.PTO'] = 'Tiempo Libre Pagado';
lang_es['Content.TimeClass.PTOAbbrev'] = 'TLP';
lang_es['Content.TimeClass.Salaried'] = 'Sin Paga/Asalariado';
lang_es['Content.TimeClass.SalariedAbbrev'] = 'SP/A';
lang_es['Content.TimeClass.Sick'] = 'Incapacidad';
lang_es['Content.TimeClass.SickAbbrev'] = 'INC';
lang_es['Content.TimeClass.Standard'] = 'Estándar';
lang_es['Content.TimeClass.StandardAbbrev'] = 'EST';
lang_es['Content.TimeClass.Unclassified'] = 'Desclasificado';
lang_es['Content.TimeClass.Vacation'] = 'Vacaciones';
lang_es['Content.TimeClass.VacationAbbrev'] = 'VAC';
lang_es['Content.UIControls.DisplaySupervisors'] = 'Mostrar Supervisores';
lang_es['Content.UIControls.DisplaySupervisors.All'] = 'Todos';
lang_es['Content.UIControls.DisplaySupervisors.Once'] = 'Una Vez';
lang_es['Content.UIControls.GuidedTours'] = 'Lanzador de Tour Guiado';
lang_es['Content.UIControls.GuidedTours.Hide'] = 'Ocultar';
lang_es['Content.UIControls.GuidedTours.Show'] = 'Mostrar';
lang_es['Content.UIControls.ItemOrdering'] = 'Ordenamiento de Elementos';
lang_es['Content.UIControls.ItemOrdering.Alphabetical'] = 'Alfabético';
lang_es['Content.UIControls.ItemOrdering.MostRecent'] = 'Más Recientes';
lang_es['Content.UIControls.ItemSelection'] = 'Selección de Elementos';
lang_es['Content.UIControls.ItemSelection.Checkboxes'] = 'Casillas';
lang_es['Content.UIControls.ItemSelection.Lists'] = 'Listas';
lang_es['Content.UIControls.ScreenMode'] = 'Modo Oscuro';
lang_es['Content.UIControls.SideMenu'] = 'Menú Lateral';
lang_es['Content.UIControls.SideMenu.Automatic'] = 'Automático';
lang_es['Content.UIControls.SideMenu.Closed'] = 'Cerrado';
lang_es['Content.UIControls.SideMenu.Open'] = 'Abierto';
lang_es['Content.UIControls.SwitchToMobileView'] = 'Cambiar a vista móvil';
lang_es['Content.UIControls.TableSize'] = 'Tamaño de Tabla';
lang_es['Content.UIControls.TableSize.Compact'] = 'Compacto';
lang_es['Content.UIControls.TableSize.Medium'] = 'Mediano';
lang_es['Content.UIControls.TableSize.Regular'] = 'Regular';
lang_es['Content.UIControls.TableSorting'] = 'Ordenar Tabla';
lang_es['Content.UIControls.TableSorting.Grouped'] = 'Grupal';
lang_es['Content.UIControls.TableSorting.Individual'] = 'Individual';
lang_es['Content.UIControls.UserSelection'] = 'Selección de Usuario';
lang_es['Content.UIControls.UserSelection.Alphabetical'] = 'Alfabético';
lang_es['Content.UIControls.UserSelection.Supervisor'] = 'Por Supervisor';
lang_es['HelpLinks.Affiliate.Click-Throughs'] = 'https://support2.timesheets.com/knowledge-base/encontrar-y-usar-codigo-de-seguimiento/?lang=es';
lang_es['HelpLinks.Affiliate.Dashboard'] = 'https://support2.timesheets.com/knowledge-base/comprendiendo-el-panel-de-socios/?lang=es';
lang_es['HelpLinks.Affiliate.ReferralsSingle'] = 'https://support2.timesheets.com/knowledge-base/refirir-clientes/?lang=es';
lang_es['HelpLinks.Guides.AddingUsers'] = 'NOT_USED';
lang_es['HelpLinks.Guides.ApprovingTimesheets'] = 'NOT_USED';
lang_es['HelpLinks.Guides.ClosingPayrollPeriodReport'] = 'NOT_USED';
lang_es['HelpLinks.Guides.CreatingLists'] = 'NOT_USED';
lang_es['HelpLinks.Guides.DisablingArchivingUsers'] = 'NOT_USED';
lang_es['HelpLinks.Guides.ExpensesSheet'] = 'NOT_USED';
lang_es['HelpLinks.Guides.HourlyClockSecurity'] = 'NOT_USED';
lang_es['HelpLinks.Guides.HourlyTimesheet'] = 'NOT_USED';
lang_es['HelpLinks.Guides.ProjectTimesheet'] = 'NOT_USED';
lang_es['HelpLinks.Guides.RunningReports'] = 'NOT_USED';
lang_es['HelpLinks.Guides.Schedule'] = 'NOT_USED';
lang_es['HelpLinks.Guides.SettingUpTimeOffAndAccruals'] = 'NOT_USED';
lang_es['HelpLinks.Guides.TourTheDashboard'] = 'NOT_USED';
lang_es['HelpLinks.Sys.AccountCodeAssignment'] = 'https://support2.timesheets.com/knowledge-base/pestana-codigos-de-cuenta/?lang=es';
lang_es['HelpLinks.Sys.AlertsList'] = 'https://support2.timesheets.com/knowledge-base/alertas-del-tiempo-por-hora/?lang=es';
lang_es['HelpLinks.Sys.AnnouncementsCreate'] = 'https://support2.timesheets.com/knowledge-base/memos-and-announcements/#announcements';
lang_es['HelpLinks.Sys.ArchiveUser'] = 'https://support2.timesheets.com/knowledge-base/disable-deactivate-terminate-and-reactivate-users/?lang=es';
lang_es['HelpLinks.Sys.ArchiveUsers'] = 'https://support2.timesheets.com/knowledge-base/disable-deactivate-terminate-and-reactivate-users/?lang=es';
lang_es['HelpLinks.Sys.AssignedEmployees'] = 'https://support2.timesheets.com/knowledge-base/pestana-empleados-asignados/?lang=es';
lang_es['HelpLinks.Sys.BulkAddAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-codigos-de-cuenta/?lang=es/#importar-lista-codigos-de-cuenta';
lang_es['HelpLinks.Sys.BulkAddCustomers'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-clientes/?lang=es/#importar-lista-de-clientes';
lang_es['HelpLinks.Sys.BulkAddEvents'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-eventos/?lang=es/#importar-lista-de-eventos';
lang_es['HelpLinks.Sys.BulkAddProjects'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proyectos/?lang=es/#importar-lista-de-proyectos';
lang_es['HelpLinks.Sys.BulkAddVendors'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proveedores/?lang=es/#importar-lista-de-proveedores';
lang_es['HelpLinks.Sys.Calendars'] = 'https://support2.timesheets.com/knowledge-base/crear-y-editar-calendarios/?lang=es';
lang_es['HelpLinks.Sys.CompanyDirectory'] = 'https://support2.timesheets.com/knowledge-base/pestana-funciones/?lang=es/#directorio';
lang_es['HelpLinks.Sys.CustomerAssignment'] = 'https://support2.timesheets.com/knowledge-base/pestana-clientes/?lang=es';
lang_es['HelpLinks.Sys.DashboardCompanyOverview'] = 'https://support2.timesheets.com/knowledge-base/comprendiendo-el-panel/?lang=es';
lang_es['HelpLinks.Sys.DataExport.QBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-instrucciones-de-configuracion/?lang=es';
lang_es['HelpLinks.Sys.DisplaySettings'] = 'https://support2.timesheets.com/knowledge-base/submenu-icono-de-usuario/?lang=es';
lang_es['HelpLinks.Sys.DownloadHistory'] = 'TBD';
lang_es['HelpLinks.Sys.EditAccountCode'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-codigos-de-cuenta/?lang=es';
lang_es['HelpLinks.Sys.EditCustomer'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-clientes/?lang=es';
lang_es['HelpLinks.Sys.EditEvent'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-eventos/?lang=es';
lang_es['HelpLinks.Sys.EditProject'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proyectos/?lang=es';
lang_es['HelpLinks.Sys.EditVendor'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proveedores/?lang=es';
lang_es['HelpLinks.Sys.EventAssignment'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-eventos/?lang=es';
lang_es['HelpLinks.Sys.EventLog'] = 'https://support2.timesheets.com/knowledge-base/pistas-de-auditoria-el-historial-del-perfil-el-registro-de-eventos-y-el-historial-de-acumulacion/?lang=es';
lang_es['HelpLinks.Sys.ExpenseExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-instrucciones-de-importacion/?lang=es';
lang_es['HelpLinks.Sys.ExpenseReconcile'] = 'https://support2.timesheets.com/knowledge-base/conciliacion-de-gastos/?lang=es';
lang_es['HelpLinks.Sys.ExpensesSheet'] = 'https://support2.timesheets.com/knowledge-base/introduccion-a-la-hoja-de-gastos/?lang=es';
lang_es['HelpLinks.Sys.GenerateAPIKeys'] = 'https://support2.timesheets.com/knowledge-base/primeros-pasos-con-api/?lang=es';
lang_es['HelpLinks.Sys.HRPerformanceDocs'] = 'https://support2.timesheets.com/knowledge-base/documentos-de-desempeno/?lang=es';
lang_es['HelpLinks.Sys.Hint.CustomerProjectSelectors'] = 'https://support2.timesheets.com/knowledge-base/metodos-de-entrada-de-tiempo-para-proyecto/?lang=es/#listas-con-tiempo-de-proyecto';
lang_es['HelpLinks.Sys.Hint.DefaultListItem'] = 'https://support2.timesheets.com/knowledge-base/introduccion-a-las-listas/?lang=es/#restricciones-de-acceso';
lang_es['HelpLinks.Sys.Hint.EmployeeInvalidLocation'] = 'https://support2.timesheets.com/knowledge-base/advertencia-de-ubicacion-no-valida/?lang=es';
lang_es['HelpLinks.Sys.Hint.ExpenseReceiptUpload'] = 'https://support2.timesheets.com/knowledge-base/millaje-y-entrada-de-gastos/?lang=es';
lang_es['HelpLinks.Sys.Hint.HourlyApprovalHelp'] = 'https://support2.timesheets.com/knowledge-base/metodos-de-entrada-de-tiempo-por-hora/?lang=es/#formulario-entrada-de-tiempo';
lang_es['HelpLinks.Sys.Hint.ProjectApprovalHelp'] = 'https://support2.timesheets.com/knowledge-base/metodos-de-entrada-de-tiempo-para-proyecto/?lang=es/#formulario-ingreso-de-tiempo';
lang_es['HelpLinks.Sys.Hint.ProjectRestrictedFields'] = 'https://support2.timesheets.com/knowledge-base/tasas-de-facturacion-y-tarifas-de-costos/?lang=es/#campos-restringidos';
lang_es['HelpLinks.Sys.Hint.RestrictedProjectTimeColumns'] = 'https://support2.timesheets.com/knowledge-base/tasas-de-facturacion-y-tarifas-de-costos/?lang=es/#campos-restringidos';
lang_es['HelpLinks.Sys.Hint.TeamWorkStatus'] = 'https://support2.timesheets.com/knowledge-base/pagina-de-horarios/?lang=es';
lang_es['HelpLinks.Sys.Hint.UserInvalidLocation'] = 'https://support2.timesheets.com/knowledge-base/advertencia-de-ubicacion-no-valida/?lang=es';
lang_es['HelpLinks.Sys.HourlyPayrollTimeExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-instrucciones-de-importacion/?lang=es';
lang_es['HelpLinks.Sys.HourlyTimesheet'] = 'https://support2.timesheets.com/knowledge-base/introduccion-a-la-hoja-de-tiempo-por-hora/?lang=es';
lang_es['HelpLinks.Sys.ImportUsers'] = 'https://support2.timesheets.com/knowledge-base/agregar-nuevos-usuarios/?lang=es/#importar';
lang_es['HelpLinks.Sys.LinkToAProPartner'] = 'https://support2.timesheets.com/knowledge-base/acceso-a-cuentas-de-clientes-referidos/?lang=es';
lang_es['HelpLinks.Sys.MergeAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/fusionar-archivar-y-eliminar-elementos-de-la-lista/?lang=es';
lang_es['HelpLinks.Sys.MergeCustomers'] = 'https://support2.timesheets.com/knowledge-base/fusionar-archivar-y-eliminar-elementos-de-la-lista/?lang=es';
lang_es['HelpLinks.Sys.MergeEvents'] = 'https://support2.timesheets.com/knowledge-base/fusionar-archivar-y-eliminar-elementos-de-la-lista/?lang=es';
lang_es['HelpLinks.Sys.MergeProjects'] = 'https://support2.timesheets.com/knowledge-base/fusionar-archivar-y-eliminar-elementos-de-la-lista/?lang=es';
lang_es['HelpLinks.Sys.MergeVendors'] = 'https://support2.timesheets.com/knowledge-base/fusionar-archivar-y-eliminar-elementos-de-la-lista/?lang=es';
lang_es['HelpLinks.Sys.MessageCenter'] = 'https://support2.timesheets.com/knowledge-base/notificaciones-memo-y-centro-de-mensajes/?lang=es';
lang_es['HelpLinks.Sys.MyAccount.MyAccountSettings'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-mi-cuenta/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.ADP.Payforce'] = 'https://support2.timesheets.com/knowledge-base/adp-pay-force-2/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.ADP.Run'] = 'https://support2.timesheets.com/knowledge-base/adp-pay-force/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.Ceridian'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.Heartland'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.PaychexPreview'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.PayrollCS'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.PayrollRelief'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollFormats.Polaris'] = 'https://support2.timesheets.com/knowledge-base/pestana-exportacion-de-datos/?lang=es';
lang_es['HelpLinks.Sys.PayrollPeriodReports'] = 'https://support2.timesheets.com/knowledge-base/reporte-de-periodo-de-nomina-cerrado/?lang=es';
lang_es['HelpLinks.Sys.PendingAlertsList'] = 'https://support2.timesheets.com/knowledge-base/solicitar-tiempo-libre/?lang=es';
lang_es['HelpLinks.Sys.PreviouslyGeneratedReports'] = 'https://support2.timesheets.com/knowledge-base/reportes-generados-previamente/?lang=es';
lang_es['HelpLinks.Sys.PrintPDFOptions'] = 'https://support2.timesheets.com/knowledge-base/impresion-y-exportacion-de-informes/?lang=es';
lang_es['HelpLinks.Sys.PrintingReports'] = 'https://support2.timesheets.com/knowledge-base/impresion-y-exportacion-de-informes/?lang=es';
lang_es['HelpLinks.Sys.ProjectAssignment'] = 'https://support2.timesheets.com/knowledge-base/pestana-proyectos/?lang=es';
lang_es['HelpLinks.Sys.ProjectTimeApprove'] = 'https://support2.timesheets.com/knowledge-base/aprobacion-del-tiempo-de-proyecto/?lang=es';
lang_es['HelpLinks.Sys.ProjectTimeArchive'] = 'https://support2.timesheets.com/knowledge-base/archivar-tiempo-del-proyecto/?lang=es';
lang_es['HelpLinks.Sys.ProjectTimeBillableUpdate'] = 'https://support2.timesheets.com/knowledge-base/convertidor-para-tiempo-de-proyecto-facturable-no-facturable/?lang=es';
lang_es['HelpLinks.Sys.ProjectTimeExportToQBO'] = 'https://support2.timesheets.com/knowledge-base/quickbooks-online-instrucciones-de-importacion/?lang=es';
lang_es['HelpLinks.Sys.ProjectTimesheet'] = 'https://support2.timesheets.com/knowledge-base/introduccion-a-la-hoja-del-tiempo-de-proyecto/?lang=es';
lang_es['HelpLinks.Sys.QBDSetupTutorialDesktopApp'] = 'https://support2.timesheets.com/article-categories/quickbooks-para-es/?lang=es';
lang_es['HelpLinks.Sys.QBOSetupTutorialIIF'] = 'https://support2.timesheets.com/knowledge-base/privado-quickbooks-desktop-metodo-de-importacion-iif/?lang=es';
lang_es['HelpLinks.Sys.ReportApproveHourlyTime'] = 'https://support2.timesheets.com/knowledge-base/aprobacion-de-tiempo-por-hora-para-nomina/?lang=es';
lang_es['HelpLinks.Sys.ReportArchiveHourlyTime'] = 'https://support2.timesheets.com/knowledge-base/archivar-hora-por-hora/?lang=es';
lang_es['HelpLinks.Sys.ReportClosePayrollPeriod'] = 'https://support2.timesheets.com/knowledge-base/cerrar-un-periodo-de-nomina/?lang=es';
lang_es['HelpLinks.Sys.ReportExpenses'] = 'https://support2.timesheets.com/knowledge-base/11-reporte-de-gastos-personalizable-2/?lang=es';
lang_es['HelpLinks.Sys.ReportHourlyPostPayroll'] = 'https://support2.timesheets.com/knowledge-base/6-reporte-personalizable-de-horas-procesadas/?lang=es';
lang_es['HelpLinks.Sys.ReportHourlyPrePayroll'] = 'https://support2.timesheets.com/knowledge-base/reporte-personalizables-de-horas/?lang=es';
lang_es['HelpLinks.Sys.ReportHourlyRecordHistory'] = 'https://support2.timesheets.com/knowledge-base/9-reporte-de-historial-de-registros-2/?lang=es';
lang_es['HelpLinks.Sys.ReportLocationAndTardy'] = 'https://support2.timesheets.com/knowledge-base/8-informes-de-ubicacion-y-tardanzas/?lang=es';
lang_es['HelpLinks.Sys.ReportOverlappingRecords'] = 'https://support2.timesheets.com/knowledge-base/7-reporte-de-registros-superpuestos/?lang=es';
lang_es['HelpLinks.Sys.ReportPreliminaryPayroll'] = 'https://support2.timesheets.com/knowledge-base/vista-previa-del-periodo-actual/?lang=es';
lang_es['HelpLinks.Sys.ReportProjectTime'] = 'https://support2.timesheets.com/knowledge-base/10-reportes-perzonalizables-2/?lang=es';
lang_es['HelpLinks.Sys.ReportsAndExports'] = 'https://support2.timesheets.com/article-categories/reportes/?lang=es';
lang_es['HelpLinks.Sys.ResetPasswordsAndWelcomeEmails'] = 'https://support2.timesheets.com/knowledge-base/reenviar-correos-electronicos-de-bienvenida/?lang=es';
lang_es['HelpLinks.Sys.SecurityGeolocation'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-seguridad-por-geolocalizacion/?lang=es';
lang_es['HelpLinks.Sys.SecurityIPAddressList'] = 'https://support2.timesheets.com/knowledge-base/lista-de-bloqueo-de-direcciones-ip/?lang=es';
lang_es['HelpLinks.Sys.SecurityMessageBoard'] = 'https://support2.timesheets.com/knowledge-base/message-board-security/';
lang_es['HelpLinks.Sys.SecurityPhotoTimestamp'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-seguridad-con-foto/?lang=es';
lang_es['HelpLinks.Sys.SecuritySettings'] = 'https://support2.timesheets.com/article-categories/pestana-de-seguridad/?lang=es';
lang_es['HelpLinks.Sys.SecurityTimeEntryCutoff'] = 'https://support2.timesheets.com/knowledge-base/corte-de-ingreso-de-tiempo/?lang=es';
lang_es['HelpLinks.Sys.TimeOffAndAccruals'] = 'https://support2.timesheets.com/knowledge-base/ingreso-de-tiempo-libre/?lang=es';
lang_es['HelpLinks.Sys.Tools.CompTimeConverter'] = 'https://support2.timesheets.com/knowledge-base/convertidor-de-tiempo-compensatorio/?lang=es';
lang_es['HelpLinks.Sys.UpdateBrandLogo'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-su-marca-privada/?lang=es';
lang_es['HelpLinks.Sys.UserAccrualImport'] = 'https://support2.timesheets.com/knowledge-base/importar-exportar-saldos-de-acumulacion/?lang=es';
lang_es['HelpLinks.Sys.UserAddNew'] = 'https://support2.timesheets.com/knowledge-base/agregar-nuevos-usuarios/?lang=es';
lang_es['HelpLinks.Sys.UserGlobalProfileUpdate'] = 'https://support2.timesheets.com/knowledge-base/actualizar-varios-perfiles-de-usuario/?lang=es';
lang_es['HelpLinks.Sys.UserProfileHistory'] = 'https://support2.timesheets.com/knowledge-base/pistas-de-auditoria-el-historial-del-perfil-el-registro-de-eventos-y-el-historial-de-acumulacion/?lang=es';
lang_es['HelpLinks.Sys.VendorAssignment'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proveedores/?lang=es';
lang_es['HelpLinks.Sys.ViewAccountCodes'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-codigos-de-cuenta/?lang=es';
lang_es['HelpLinks.Sys.ViewCustomers'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-clientes/?lang=es';
lang_es['HelpLinks.Sys.ViewEvents'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-eventos/?lang=es';
lang_es['HelpLinks.Sys.ViewProjects'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proyectos/?lang=es';
lang_es['HelpLinks.Sys.ViewVendors'] = 'https://support2.timesheets.com/knowledge-base/configuracion-de-proveedores/?lang=es';
lang_es['PageTitles.Admin.AdvertisingSummary'] = 'Resúmen de Publicidad';
lang_es['PageTitles.Admin.ApplicationSummary'] = 'Resumen de la Aplicación';
lang_es['PageTitles.Admin.BrandAdministration'] = 'Administración de Marca';
lang_es['PageTitles.Admin.CommissionPayments'] = 'Pagos de Comisiones';
lang_es['PageTitles.Admin.DatabaseIntegrityTests'] = 'Pruebas de Integridad de la Base de Datos';
lang_es['PageTitles.Admin.DatabaseStatistics'] = 'Estadísticas de la Base de Datos';
lang_es['PageTitles.Admin.InvalidSiteAdminLogins'] = 'INICIO DE SESIÓN DE ADMINISTRADOR DEL SITIO NO VÁLIDO';
lang_es['PageTitles.Admin.LicenseStats'] = 'Estadísticas de Licencia';
lang_es['PageTitles.Admin.LoginHomepage'] = 'Inicio de Sesión del Administrador del Sitio';
lang_es['PageTitles.Admin.LoginMessage'] = 'Mensaje del Sistema de Inicio de Sesión';
lang_es['PageTitles.Admin.Maintain-AdminPermissions'] = 'Mantener los Permisos de Administrador';
lang_es['PageTitles.Admin.Maintain-ArchivedUsersUnpaidRecords'] = 'Usuarios Archivados con Registros No Pagados';
lang_es['PageTitles.Admin.Maintain-DeactivatedUsersUnpaidRecords'] = 'Usuarios Desactivados con Registros No Pagados';
lang_es['PageTitles.Admin.Maintain-DuplicatePayroll'] = 'Mantenimiento de Nómina Duplicada';
lang_es['PageTitles.Admin.Maintain-InvalidLoginAttempts'] = 'Intentos de Inicio de Sesión No Vá¡lidos Actualmente';
lang_es['PageTitles.Admin.Maintain-SupervisorPermissions'] = 'Mantener Permisos de Supervisor';
lang_es['PageTitles.Admin.Maintain-UserDisplayPreference'] = 'Mantenimiento de Preferencias de Pantalla de Usuario Individual';
lang_es['PageTitles.Admin.ManageAccountAlerts'] = 'Administrar Alertas de la Cuenta';
lang_es['PageTitles.Admin.ManageAnnouncements'] = 'Administrar Anuncios';
lang_es['PageTitles.Admin.ManageSiteAdminAccounts'] = 'Cuentas de Administrador del Sitio';
lang_es['PageTitles.Admin.PhotoRecordSets'] = 'Validar el Conjunto de Registros de Foto por Hora';
lang_es['PageTitles.Admin.PricingStructure'] = 'Estructura de Precios';
lang_es['PageTitles.Admin.PromoCodes'] = 'Códigos Promocionales';
lang_es['PageTitles.Admin.ReportAccountCreations'] = 'Reporte de Creación de Cuenta';
lang_es['PageTitles.Admin.ReportAccountNotes'] = 'Reporte de Notas de Cuenta';
lang_es['PageTitles.Admin.ReportAdministrativeTask'] = 'Reporte de Tareas Administrativas';
lang_es['PageTitles.Admin.ReportAlerts'] = 'Reporte de Alertas';
lang_es['PageTitles.Admin.ReportCancellations'] = 'Reporte de Cancelaciones';
lang_es['PageTitles.Admin.ReportCustomQuery'] = 'Consulta de Reporte Personalizado';
lang_es['PageTitles.Admin.ReportDailyBilling'] = 'Reporte de Resumen de Facturación Diaria';
lang_es['PageTitles.Admin.ReportLastPayroll'] = 'Reporte de Última Fecha de Nómina';
lang_es['PageTitles.Admin.ReportPageviews'] = 'Reporte de Vistas de Página';
lang_es['PageTitles.Admin.ReportPromoCodeUsage'] = 'Reporte de Uso de Código Promocional';
lang_es['PageTitles.Admin.ReportSystemLogMessages'] = 'Reporte de Mensajes de Registro del Sistema';
lang_es['PageTitles.Admin.ReportTotalUserHours'] = 'Reporte de Horas de Usuario';
lang_es['PageTitles.Admin.ServerSessionStatus'] = 'Estado de la Sesión del Servidor';
lang_es['PageTitles.Admin.Snapshot'] = 'Vistazo';
lang_es['PageTitles.Admin.StatusReportEmailTest'] = 'Prueba de Reporte de Estado por Correo Electrónico';
lang_es['PageTitles.Admin.UserAccountNotification'] = 'Insertar Notificación de Cuenta de Usuario';
lang_es['PageTitles.Admin.UserAccountSettings'] = 'Ajustes de la Cuenta de Usuario';
lang_es['PageTitles.Admin.ViewAccounts'] = 'Ver Cuentas';
lang_es['PageTitles.Admin.ViewAccruals'] = 'Ver Acumulaciones';
lang_es['PageTitles.Admin.ViewAffiliates'] = 'Ver Afiliados';
lang_es['PageTitles.Admin.ViewUserLoginHistory'] = 'Ver Historial de Inicio de Sesión del Usuario';
lang_es['PageTitles.Admin.ViewUserRequestHistory'] = 'Ver Historial De Solicitudes de Usuario';
lang_es['PageTitles.Admin.ViewUsers'] = 'Ver Usuarios';
lang_es['PageTitles.Affiliate.AccountProfile'] = 'Mi Perfil';
lang_es['PageTitles.Affiliate.AccountRegistration'] = 'Regístrese en nuestro Programa ProPartner';
lang_es['PageTitles.Affiliate.BannerAds'] = 'Código de Localización';
lang_es['PageTitles.Affiliate.Click-Throughs'] = 'Proporción de clicks';
lang_es['PageTitles.Affiliate.Commissions'] = 'Comisiones';
lang_es['PageTitles.Affiliate.Contact'] = 'Contáctenos';
lang_es['PageTitles.Affiliate.Dashboard'] = 'Panel';
lang_es['PageTitles.Affiliate.Help'] = 'Ayuda';
lang_es['PageTitles.Affiliate.MyAccount'] = 'Mi cuenta';
lang_es['PageTitles.Affiliate.MyProfile'] = 'Mi Perfil';
lang_es['PageTitles.Affiliate.ProPartnerProgram'] = 'Programa ProPartner';
lang_es['PageTitles.Affiliate.ReferralData'] = 'Datos de Referidos';
lang_es['PageTitles.Affiliate.ReferralsMultiple'] = 'Enviar Múltiples Referidos';
lang_es['PageTitles.Affiliate.ReferralsSingle'] = 'Agregar un Cliente';
lang_es['PageTitles.Affiliate.SandboxAccount'] = 'Crear una Cuenta de Sandbox';
lang_es['PageTitles.Affiliate.TrackingCode'] = 'Código de Localización';
lang_es['PageTitles.Affiliate.Training'] = 'Capacitación';
lang_es['PageTitles.Common.DataProcessingAgreement'] = 'Acuerdo de Procesamiento de Datos';
lang_es['PageTitles.Common.PrivacyStatement'] = 'Declaración de Privacidad';
lang_es['PageTitles.Common.ResetAccountActivation'] = 'Reiniciar Activación de Cuenta';
lang_es['PageTitles.Common.TermsOfUse'] = 'Términos de Uso';
lang_es['PageTitles.Sys.AccountCodeEdit'] = 'Editar Código de Cuenta';
lang_es['PageTitles.Sys.AccountCodeList'] = 'Lista de Códigos de Cuenta';
lang_es['PageTitles.Sys.AccountContactInfoUpdate'] = 'Confirmación de Información de Contacto';
lang_es['PageTitles.Sys.AccountCreated'] = '¡Cuenta creada!';
lang_es['PageTitles.Sys.AccountDetails'] = 'Detalles de la Cuenta';
lang_es['PageTitles.Sys.AccountDetailsAndPaymentHistory'] = 'Detalles de la Cuenta e Historial de Pagos';
lang_es['PageTitles.Sys.AccountPasswordUpdate'] = 'Cambie su Contraseña';
lang_es['PageTitles.Sys.AccountSetupWizard'] = 'Ajustes de Compañía';
lang_es['PageTitles.Sys.AccountSummary'] = 'Resumen de Cuenta';
lang_es['PageTitles.Sys.ActivateAccountSubscription'] = 'Activar Suscripción de la Cuenta';
lang_es['PageTitles.Sys.AddEditCalendars'] = 'Agregar y Editar Calendarios';
lang_es['PageTitles.Sys.AlertsList'] = 'Alertas que Requieren su Atención';
lang_es['PageTitles.Sys.AnnouncementsCreate'] = 'Crear un Anuncio General';
lang_es['PageTitles.Sys.AnnouncementsRecent'] = 'Anuncios Recientes';
lang_es['PageTitles.Sys.ArchiveUser'] = 'Archivar Usuario';
lang_es['PageTitles.Sys.ArchiveUsers'] = 'Archivar Usuarios';
lang_es['PageTitles.Sys.BillingReport'] = 'Reporte de Facturación';
lang_es['PageTitles.Sys.CalendarsAndSchedules'] = 'Calendarios y Horarios';
lang_es['PageTitles.Sys.CancellingService'] = 'Cancelación de Servicio';
lang_es['PageTitles.Sys.ClockInOut'] = 'Marcar Entrada/Salida';
lang_es['PageTitles.Sys.ClockStatus'] = 'Estado Actual de Usuario';
lang_es['PageTitles.Sys.ClockUserIn'] = 'Marcar Entrada de Usuario';
lang_es['PageTitles.Sys.ClockUserOut'] = 'Marcar Salida de Usuario';
lang_es['PageTitles.Sys.ClockedIn'] = 'Entrada Marcada';
lang_es['PageTitles.Sys.ClockedOut'] = 'Salida Marcada';
lang_es['PageTitles.Sys.CompTimeConverter'] = 'Convertidor de Tiempo Compensatorio';
lang_es['PageTitles.Sys.CompanyDirectory'] = 'Directorio de Compañía';
lang_es['PageTitles.Sys.CompanySettings'] = 'Ajustes de Compañía';
lang_es['PageTitles.Sys.CompanyTimeZoneSettings'] = 'Ajustes de Zona Horaria de la Compañía';
lang_es['PageTitles.Sys.CreateTrialAccount'] = 'Crear una Cuenta de Prueba';
lang_es['PageTitles.Sys.CustomerEdit'] = 'Editar Cliente';
lang_es['PageTitles.Sys.CustomerList'] = 'Lista de Clientes';
lang_es['PageTitles.Sys.CustomerService'] = 'Formulario de Atención al Cliente';
lang_es['PageTitles.Sys.Dashboard'] = 'Panel';
lang_es['PageTitles.Sys.DeactivateUsers'] = 'Desactivar Usuarios';
lang_es['PageTitles.Sys.DownloadHistory'] = 'Historial de Descargas';
lang_es['PageTitles.Sys.EditCustomCalendars'] = 'Editar Calendarios Personalizados';
lang_es['PageTitles.Sys.ErrorOops'] = 'Ups...';
lang_es['PageTitles.Sys.EventEdit'] = 'Editar Evento';
lang_es['PageTitles.Sys.EventList'] = 'Lista de Eventos';
lang_es['PageTitles.Sys.EventLog'] = 'Registro de Eventos';
lang_es['PageTitles.Sys.ExpenseExportToQBO'] = 'Exportar Gastos a QuickBooks Online';
lang_es['PageTitles.Sys.ExpenseReceipt'] = 'Recibo de Gastos';
lang_es['PageTitles.Sys.ExpenseReconcile'] = 'Conciliar Gastos';
lang_es['PageTitles.Sys.ExternalServices'] = 'Servicios Externos';
lang_es['PageTitles.Sys.FreeTrialEnd'] = 'Su Perí­odo de Prueba Gratuito ha Finalizado';
lang_es['PageTitles.Sys.GenerateAPIKeys'] = 'Generar Llaves de API';
lang_es['PageTitles.Sys.HourlyPayrollTimeExportToQBO'] = 'Exportar Tiempo por Hora a QuickBooks Online';
lang_es['PageTitles.Sys.HourlyPrintableTimesheets'] = 'Hojas de Tiempo Imprimibles por Hora';
lang_es['PageTitles.Sys.HourlyRecordTimeZoneUpdate'] = 'Actualizar la Zona Horaria de los Registros por Hora';
lang_es['PageTitles.Sys.HowToManual'] = 'Manual de Instrucciones';
lang_es['PageTitles.Sys.HumanResourcePerformanceDocuments'] = 'Documentos de Desempeño de Recursos Humanos';
lang_es['PageTitles.Sys.ImportAccountCodePayRates'] = 'Importar Tasas de Costo de Códigos de Cuenta';
lang_es['PageTitles.Sys.ImportProjectBillRates'] = 'Importar Tarifas de Facturación de Proyectos';
lang_es['PageTitles.Sys.ImportUsers'] = 'Importar Usuarios';
lang_es['PageTitles.Sys.LegalAdvice'] = 'Pregunte a un Experto Legal';
lang_es['PageTitles.Sys.LinkToAProPartner'] = 'Enlace para Socio ProPartner';
lang_es['PageTitles.Sys.LoggedOut'] = 'Desconectado';
lang_es['PageTitles.Sys.MergeItems'] = 'Combinar Todos los Registros de Este Elemento en Otro Elemento';
lang_es['PageTitles.Sys.MessageBoard'] = 'El Water Cooler';
lang_es['PageTitles.Sys.MessageCenter'] = 'Centro de Mensajes';
lang_es['PageTitles.Sys.MyAccount'] = 'Mi cuenta';
lang_es['PageTitles.Sys.MyAccount.AccountOptionsAndPaymentDetails'] = 'Opciones de Cuenta y Detalles de Pago';
lang_es['PageTitles.Sys.MyAccount.AddOrRemoveLicenses'] = 'Agregar o Remover Licencias o Servicios Premium';
lang_es['PageTitles.Sys.MyAccount.AddOrRemoveLicensesSub'] = 'Ajuste el tamaño de su cuenta y administre las actualizaciones de la cuenta.';
lang_es['PageTitles.Sys.MyAccount.CancelMyAccount'] = 'Cancelar mi Cuenta';
lang_es['PageTitles.Sys.MyAccount.CancelMyAccountSub'] = 'Cancele su cuenta de usuario gratuita.';
lang_es['PageTitles.Sys.MyAccount.CancelYourAccount'] = 'Cancelar su Cuenta';
lang_es['PageTitles.Sys.MyAccount.CancelYourAccountSub'] = 'Cancele su servicio al final del periodo de facturación actual.';
lang_es['PageTitles.Sys.MyAccount.ChangeMyPassword'] = 'Cambiar mi Contraseña';
lang_es['PageTitles.Sys.MyAccount.ChangeMyPasswordSub'] = 'Cambie su contraseña de inicio de sesión.';
lang_es['PageTitles.Sys.MyAccount.MyAccountSettings'] = 'Ajustes de mi Cuenta';
lang_es['PageTitles.Sys.MyAccount.MyAccountSettingsSub'] = 'Modifique ajustes de su cuenta aquí.';
lang_es['PageTitles.Sys.MyAccount.PaymentHistoryDetails'] = 'Detalles del Historial de Pagos';
lang_es['PageTitles.Sys.MyAccount.PaymentHistoryDetailsSub'] = 'Ver su estado de suscripción actual e historial de pagos.';
lang_es['PageTitles.Sys.MyAccount.ReferAFriend'] = 'Recomendar a un Amigo';
lang_es['PageTitles.Sys.MyAccount.ReferAFriendSub'] = 'Reciba una tarjeta de regalo por cada recomendación que se convierta en un cliente pago.';
lang_es['PageTitles.Sys.MyAccount.UpdatePaymentInformation'] = 'Actualice su Información de Pago';
lang_es['PageTitles.Sys.MyAccount.UpdatePaymentInformationSub'] = 'Actualice su información de facturación actual.';
lang_es['PageTitles.Sys.MyAccount.UploadCustomLogo'] = 'Subir Logo Personalizado';
lang_es['PageTitles.Sys.MyAccount.UploadCustomLogoSub'] = 'Cargue su logo personalizado como parte de su actualización de marca privada.';
lang_es['PageTitles.Sys.MyAccount.YourPersonalRepIs'] = 'Su representante de cuenta personal es {1}.';
lang_es['PageTitles.Sys.MyTeamsStatus'] = 'Estado de Mi Equipo';
lang_es['PageTitles.Sys.NewAccountVerification'] = 'Verificación de Cuenta Nueva';
lang_es['PageTitles.Sys.NewTimer'] = 'Nuevo Temporizador';
lang_es['PageTitles.Sys.OpenExpensesSummary'] = 'Resumen de Gastos Abiertos';
lang_es['PageTitles.Sys.PageNotFound'] = '¡Página no encontrada!';
lang_es['PageTitles.Sys.PayAndActivateAccount'] = 'Pague y Active Su Cuenta';
lang_es['PageTitles.Sys.PaymentAndBillingInformation'] = 'Información de Pago y Facturación';
lang_es['PageTitles.Sys.PayrollPeriodSummary'] = 'Resumen del Periodo de Nómina';
lang_es['PageTitles.Sys.PendingAlertsList'] = 'Registros Pendientes';
lang_es['PageTitles.Sys.Presets'] = 'Preajustes';
lang_es['PageTitles.Sys.PreviouslyGeneratedReports'] = 'Reportes Generados Previamente';
lang_es['PageTitles.Sys.ProjectEdit'] = 'Editar Proyecto';
lang_es['PageTitles.Sys.ProjectList'] = 'Lista de Proyectos';
lang_es['PageTitles.Sys.ProjectPrintableTimesheets'] = 'Hoja de Horas de Proyecto Imprimibles';
lang_es['PageTitles.Sys.ProjectTimeApprove'] = 'Aprobar Tiempo de Proyecto';
lang_es['PageTitles.Sys.ProjectTimeArchive'] = 'Archivar Tiempo de Proyecto';
lang_es['PageTitles.Sys.ProjectTimeBillableUpdate'] = 'Actualizar Tiempo Facturable de Proyecto';
lang_es['PageTitles.Sys.ProjectTimeExportToQBO'] = 'Exportar Tiempo de Proyecto a QuickBooks Online';
lang_es['PageTitles.Sys.PurchaseHistory'] = 'Historial de Compras';
lang_es['PageTitles.Sys.QBOExport'] = 'Exportación de QuickBooks Online';
lang_es['PageTitles.Sys.QuickBooksTutorial'] = 'Tutorial de QuickBooks&reg;';
lang_es['PageTitles.Sys.QuickClockOut'] = 'Marcar Salida Rápida';
lang_es['PageTitles.Sys.RecurringPaymentHistory'] = 'Historial de Pagos Recurrentes';
lang_es['PageTitles.Sys.ReferAFriend'] = 'Recomendar a un Amigo';
lang_es['PageTitles.Sys.RemoteLogin'] = 'Inicie Sesión Desde Su Propio Sitio';
lang_es['PageTitles.Sys.ReportApproveHourlyTime'] = 'Aprobar Tiempo por Hora';
lang_es['PageTitles.Sys.ReportArchiveHourlyTime'] = 'Archivar Tiempo por Hora';
lang_es['PageTitles.Sys.ReportClosePayrollPeriod'] = 'Cerrar Periodo de Nómina';
lang_es['PageTitles.Sys.ReportExpenses'] = 'Reporte de Gastos';
lang_es['PageTitles.Sys.ReportHourlyPayrollTime'] = 'Reportes de Nómina para Horas Trabajadas';
lang_es['PageTitles.Sys.ReportHourlyPrePayrollTime'] = 'Reportes para Horas Trabajadas';
lang_es['PageTitles.Sys.ReportHourlyRecordHistory'] = 'Reporte de Historial de Horas Trabajadas';
lang_es['PageTitles.Sys.ReportLocationAndTardy'] = 'Reportes de Ubicación y Tardanzas';
lang_es['PageTitles.Sys.ReportOverlappingRecords'] = 'Reporte de Registros Superpuestos';
lang_es['PageTitles.Sys.ReportPayrollPeriod'] = 'Reportes de Nómina';
lang_es['PageTitles.Sys.ReportPreliminaryPayroll'] = 'Reportes Preliminares de Nómina';
lang_es['PageTitles.Sys.ReportProjectTime'] = 'Reportes de Horas de Proyecto';
lang_es['PageTitles.Sys.ReportUserInfo'] = 'Informe de información del usuario';
lang_es['PageTitles.Sys.ReportsAndExports'] = 'Reportes y Exportaciones';
lang_es['PageTitles.Sys.ResetAccountPassword'] = 'Restablecer la Contraseña de su Cuenta';
lang_es['PageTitles.Sys.ResetPasswordsAndWelcomeEmails'] = 'Reenviar Correo Electrónico de Bienvenida';
lang_es['PageTitles.Sys.SecurityGeolocation'] = 'Ajustes de Geolocalización';
lang_es['PageTitles.Sys.SecurityIPAddressList'] = 'Lista de Restricciones de Direcciones IP';
lang_es['PageTitles.Sys.SecurityMessageBoard'] = 'Seguridad del tablero de mensajes';
lang_es['PageTitles.Sys.SecurityPhotoTimestamp'] = 'Ajustes de Registro por Foto';
lang_es['PageTitles.Sys.SecuritySettings'] = 'Ajustes de Seguridad';
lang_es['PageTitles.Sys.SecurityTimeEntryCutoff'] = 'Corte de Ingreso de Tiempo';
lang_es['PageTitles.Sys.SecurityUserPW'] = 'Seguridad de Contraseña de Usuario';
lang_es['PageTitles.Sys.SessionCookieTest'] = 'Prueba de Cookies';
lang_es['PageTitles.Sys.ShiftStatus'] = 'Estado de Turno';
lang_es['PageTitles.Sys.SignatureStatements'] = 'Declaraciones de Firma';
lang_es['PageTitles.Sys.SubscribingTo'] = 'Suscribirse a';
lang_es['PageTitles.Sys.SubscriptionOptions'] = 'Opciones de Suscripción';
lang_es['PageTitles.Sys.SystemUnavailable'] = 'Sistema No Disponible';
lang_es['PageTitles.Sys.TimesheetDateSpan'] = 'Hoja de Horas desde: {1} HASTA {2}';
lang_es['PageTitles.Sys.Tools'] = 'Instrumentos';
lang_es['PageTitles.Sys.Tools.APIAccess'] = 'Acceso API';
lang_es['PageTitles.Sys.Tools.APIAccessSub'] = 'Genere claves API para acceso programático a su cuenta.';
lang_es['PageTitles.Sys.Tools.BillableProjectTimeConverter'] = 'Conversor de Horas de Proyecto Facturables / No Facturables';
lang_es['PageTitles.Sys.Tools.BillableProjectTimeConverterSub'] = 'Actualice el estado facturable de varios registros de proyectos.';
lang_es['PageTitles.Sys.Tools.CompTimeConverter'] = 'Convertidor de Tiempo Compensatorio';
lang_es['PageTitles.Sys.Tools.CompTimeConverterSub'] = 'Convierta las horas extras en tiempo compensatorio.';
lang_es['PageTitles.Sys.Tools.CompanyAnnouncements'] = 'Anuncios de Compañía';
lang_es['PageTitles.Sys.Tools.CompanyAnnouncementsSub'] = 'Cree anuncios que se mostrarán en todas las cuentas de usuario.';
lang_es['PageTitles.Sys.Tools.GlobalHourlyTimeEntry'] = 'Ingreso Global de Horas Trabajadas';
lang_es['PageTitles.Sys.Tools.GlobalProjectTimeEntry'] = 'Ingreso Global de Horas de Proyecto';
lang_es['PageTitles.Sys.Tools.GlobalTimeEntry'] = 'Ingreso de Tiempo Global';
lang_es['PageTitles.Sys.Tools.ImporExportAccrualBalances'] = 'Importar/Exportar Saldos de Acumulación';
lang_es['PageTitles.Sys.Tools.ImporExportAccrualBalancesSub'] = 'Cambie la configuración y los balances de tiempo libre acumulados de varios usuarios a la vez.';
lang_es['PageTitles.Sys.Tools.LinkToProPartner'] = 'Enlace a un ProPartner';
lang_es['PageTitles.Sys.Tools.LinkToProPartnerSub'] = 'Vincula una cuenta de usuario a tu ProPartner.';
lang_es['PageTitles.Sys.Tools.ResendWelcomeEmails'] = 'Reenviar Correos Electrónicos de Bienvenida';
lang_es['PageTitles.Sys.Tools.ResendWelcomeEmailsSub'] = 'Reenvíe los correos electrónicos de bienvenida originales con un enlace para ingresar.';
lang_es['PageTitles.Sys.Tools.UpdateMultipleUserProfiles'] = 'Actualizar Varios Perfiles de Usuario';
lang_es['PageTitles.Sys.Tools.UpdateMultipleUserProfilesSub'] = 'Cambie la configuración de perfil para varios usuarios a la vez.';
lang_es['PageTitles.Sys.Tools.WorkTimeCalculator'] = 'Calculadora de Tiempo de Trabajo';
lang_es['PageTitles.Sys.Tools.WorkTimeCalculatorSub'] = 'Sume horas trabajadas manualmente.';
lang_es['PageTitles.Sys.Training'] = 'Capacitación';
lang_es['PageTitles.Sys.TrialDaysLeft'] = 'Dí­as Restantes del Perí­odo de Prueba';
lang_es['PageTitles.Sys.TrialExpired'] = 'Prueba Vencida';
lang_es['PageTitles.Sys.TrySampleAccount'] = 'Pruebe Nuestra Cuenta de Muestra';
lang_es['PageTitles.Sys.UpcomingEvents'] = 'Próximos Eventos';
lang_es['PageTitles.Sys.UpdateBrandLogo'] = 'Actualizar el Logo de la Compañía';
lang_es['PageTitles.Sys.UpdatePaymentInformation'] = 'Actualizar la Información de Pago';
lang_es['PageTitles.Sys.UserAccrualImport'] = 'Importación de Balances de Usuario';
lang_es['PageTitles.Sys.UserAddNew'] = 'Agregar Nuevo Usuario';
lang_es['PageTitles.Sys.UserFiles'] = 'Archivos de Usuario';
lang_es['PageTitles.Sys.UserFirstTimeLogin'] = 'Primer Inicio de Sesión';
lang_es['PageTitles.Sys.UserGlobalProfileUpdate'] = 'Actualizar Varios Perfiles de Usuario';
lang_es['PageTitles.Sys.UserImport'] = 'Importación de Usuarios';
lang_es['PageTitles.Sys.UserImportSalesforce'] = 'Importación de Usuarios de Salesforce';
lang_es['PageTitles.Sys.UserNotes'] = 'Notas de Usuario';
lang_es['PageTitles.Sys.UserProfileHistory'] = 'Historial de Perfil de Usuario';
lang_es['PageTitles.Sys.UserUpdateAccountInfo'] = 'Actualizar Información de Cuenta';
lang_es['PageTitles.Sys.VendorEdit'] = 'Editar Proveedor';
lang_es['PageTitles.Sys.VendorList'] = 'Lista de Proveedores';
lang_es['PageTitles.Sys.ViewCalendars'] = 'Calendarios de la Compañía';
lang_es['PageTitles.Sys.WorkTimeCalculatorFastAnswer'] = 'Calculadora Rápida de Tiempo de Trabajo';
lang_es['UIMessages.Accruals.AccruedHoursRange'] = 'Las horas acumuladas deben estar entre -10000 y 10000.';
lang_es['UIMessages.Accruals.CurrentlyAccruedNotGreater'] = 'Las horas acumuladas no pueden ser superiores al máximo permitido.';
lang_es['UIMessages.Accruals.HourlyAccrualParams'] = 'Las horas por hora trabajada deben ser inferiores a las horas acumuladas por año y al límite máximo (si no hay reinversión).';
lang_es['UIMessages.Accruals.HourlyAccrualRange'] = 'Las horas por hora trabajada deben ser números entre 0 y 99999.';
lang_es['UIMessages.Accruals.HoursPerYearParams'] = 'Las horas por año deben ser números entre 0 y 8760.';
lang_es['UIMessages.Accruals.InvalidRate'] = 'Tasa de acumulación no válida';
lang_es['UIMessages.Accruals.LeapDayInvalid'] = 'Las acumulaciones no pueden ocurrir en el día bisiesto.';
lang_es['UIMessages.Accruals.MaxAccruedHoursRange'] = 'El máximo de horas acumuladas debe estar entre 0 y 99999.';
lang_es['UIMessages.Accruals.SalUsersCannotByHoursWorked'] = 'Usuarios asalariados no pueden acumular por horas trabajadas.';
lang_es['UIMessages.Accruals.StartDateRequired'] = 'Se requiere una fecha de inicio de acumulación.';
lang_es['UIMessages.Accruals.StartDateRequiredForX'] = '{1}: Se requiere una fecha de inicio de acumulación.';
lang_es['UIMessages.Accruals.UIOLICapInvalid'] = 'El límite de \'Usar o Perder\' debe ser entre 0 y 99999 (deshabilitado).';
lang_es['UIMessages.Accruals.UIOLIDateInvalid'] = 'Se necesita una fecha válida para la reinversión de \'Usar o Perder\'.';
lang_es['UIMessages.Affiliate.AcceptAgreement'] = 'Debe aceptar el Acuerdo de Socio para continuar.';
lang_es['UIMessages.Affiliate.AcctCreated'] = 'Cuenta ProPartner creada!';
lang_es['UIMessages.Affiliate.AcctDisabled'] = 'La cuenta de afiliado ha sido deshabilitada. Comuníquese con Soporte.';
lang_es['UIMessages.Affiliate.ClientAdded'] = 'Su nuevo cliente ha sido agregado. Si nos pidió que nos comuniquemos con ellos lo haremos de inmediato.';
lang_es['UIMessages.Affiliate.ErrorCreatingAccount'] = 'Hubo un error al crear la cuenta de referido. Comuníquese con atención al cliente.';
lang_es['UIMessages.Affiliate.IDInvalid'] = 'ID de afiliado no válido';
lang_es['UIMessages.Affiliate.Linked'] = 'Cuenta de afiliado vinculada';
lang_es['UIMessages.Affiliate.NoneFound'] = 'No se encontró ninguna cuenta de afiliado';
lang_es['UIMessages.Affiliate.ReferralRecorded'] = 'Se ha registrado una cuenta de referido en nuestros registros y se ha enviado un correo electrónico de bienvenida. Si su referido se convierte en cliente pago, nos comunicaremos con usted con respecto a su compensación por referido. Puede ingresar otro referido ahora.';
lang_es['UIMessages.Affiliate.RequiredFields'] = 'Por favor complete los campos requeridos arriba.';
lang_es['UIMessages.Affiliate.ResendReferral'] = 'Referido reenviado';
lang_es['UIMessages.Affiliate.Unlinked'] = 'Cuenta de afiliado desvinculada';
lang_es['UIMessages.Announcement.AnnouncementAdded'] = 'Anuncio agregado';
lang_es['UIMessages.Calendar.ActionOnlyOnScheduleView'] = 'La acción solo se puede realizar en la vista de calendario.';
lang_es['UIMessages.Calendar.CalendarAdded'] = 'Calendario agregado';
lang_es['UIMessages.Calendar.CalendarArchived'] = 'El Calendario está archivado y no puede ser editado.';
lang_es['UIMessages.Calendar.CalendarNotFound'] = 'No se pudo encontrar el calendario.';
lang_es['UIMessages.Calendar.CalendarNotFoundOrInitialized'] = 'No se pudo encontrar o inicializar el calendario.';
lang_es['UIMessages.Calendar.CalendarUpdated'] = 'Calendario actualizado';
lang_es['UIMessages.Calendar.CalendarsArchived'] = 'Calendarios Archivados';
lang_es['UIMessages.Calendar.CalendarsDeleted'] = 'Calendarios Borrados';
lang_es['UIMessages.Calendar.CalendarsRestored'] = 'Calendarios Restaurados';
lang_es['UIMessages.Calendar.CopiedSuccessForEditableCalendars'] = 'Las fechas del calendario se copiaron correctamente para todos los calendarios editables.';
lang_es['UIMessages.Calendar.DescriptionCharLimit'] = 'La descripción del calendario está limitada a {1} caracteres';
lang_es['UIMessages.Calendar.EndDateRequired'] = 'Se requiere una fecha de finalización';
lang_es['UIMessages.Calendar.ErrorImportingICSFile'] = 'Hubo un error importando el archivo ICS.';
lang_es['UIMessages.Calendar.EventAvailabilityStatusInvalid'] = 'El estado de disponibilidad del evento no era válido.';
lang_es['UIMessages.Calendar.EventDatesInvalid'] = 'Las fechas del evento no son válidas.';
lang_es['UIMessages.Calendar.EventOverlap'] = 'Este evento se superpondrá a un evento existente.';
lang_es['UIMessages.Calendar.EventPublishedStatusInvalid'] = 'El estado de publicación del evento no era válido.';
lang_es['UIMessages.Calendar.EventTitleRequired'] = 'Se requiere el título del evento';
lang_es['UIMessages.Calendar.EventsCannotBeAdded'] = 'No se pueden agregar eventos al calendario seleccionado.';
lang_es['UIMessages.Calendar.ImportFileDateInvalid'] = 'La posición de fecha [{1}] no era una estructura de fecha válida.';
lang_es['UIMessages.Calendar.InvalidActionTryAgain'] = 'Acción no válida. Actualice el calendario y vuelva a intentarlo.';
lang_es['UIMessages.Calendar.ModifyingNotSupported'] = 'El calendario seleccionado no admite la modificación de sus propiedades.';
lang_es['UIMessages.Calendar.NameCharLimit'] = 'El nombre del calendario está limitado a {1} caracteres';
lang_es['UIMessages.Calendar.NameRequired'] = 'El nombre del calendario es obligatorio';
lang_es['UIMessages.Calendar.NoPermissionToEditEventsForCalendar'] = 'No hay permiso para editar eventos para este calendario';
lang_es['UIMessages.Calendar.NoPermissionToViewEventsForCalendar'] = 'No hay permiso para ver los eventos de este calendario';
lang_es['UIMessages.Calendar.PropertiesReset'] = 'Restablecimiento de propiedades del calendario';
lang_es['UIMessages.Calendar.PropertiesUpdated'] = 'Las propiedades del calendario fueron actualizadas';
lang_es['UIMessages.Calendar.SelectedDatesInvalid'] = 'Las fechas seleccionadas no son válidas. Actualice el calendario y vuelva a intentarlo.';
lang_es['UIMessages.Calendar.StartDateCannotBeBeforeEndDate'] = 'La fecha de inicio no puede ser anterior a la fecha de finalización.';
lang_es['UIMessages.Calendar.StartDateRequired'] = 'Se requiere una fecha de inicio';
lang_es['UIMessages.Calendar.ValidCSVRequired'] = 'Se requiere un archivo CSV válido';
lang_es['UIMessages.Calendar.ValidFileRequired'] = 'Se requiere un archivo válido';
lang_es['UIMessages.Calendar.ValidICSRequired'] = 'Se requiere un archivo ICS válido';
lang_es['UIMessages.Calendar.ValidTypeRequired'] = 'Se requiere un tipo de calendario válido';
lang_es['UIMessages.ClockAction.BreakErrorMustBeClockedIn'] = 'Error de tiempo de descanso. Debe haber marcado su entrada para comenzar un descanso.';
lang_es['UIMessages.ClockAction.BreakStartResumeAt'] = 'Ahora estás en tu descanso de {1} minutos. Su turno se reanudará automáticamente a las {2}.';
lang_es['UIMessages.ClockAction.CameraDisabled'] = 'Cámara desactivada';
lang_es['UIMessages.ClockAction.ClockEditsAreNotEnabled'] = 'La edición de registros de reloj no está habilitada para esta cuenta.';
lang_es['UIMessages.ClockAction.ClockInColorCheckFail'] = 'Se detectó una baja calidad de foto al marcar la entrada.';
lang_es['UIMessages.ClockAction.ClockInDisabled'] = 'El registro está deshabilitado porque la cuenta está bloqueada.';
lang_es['UIMessages.ClockAction.ClockInFacialDetectFail'] = 'Posible falla de detección facial en el registro';
lang_es['UIMessages.ClockAction.ClockInInterrupted'] = 'Intento de marcar entrada interrumpido. Inténtelo de nuevo.';
lang_es['UIMessages.ClockAction.ClockInPhotoNotValid'] = 'La foto de registro estaba corrupta';
lang_es['UIMessages.ClockAction.ClockInTimeChangeRequest'] = 'La edición de la hora de entrada está pendiente y no se reflejará hasta que haya sido aprobada / denegada / modificada por su supervisor.';
lang_es['UIMessages.ClockAction.ClockInTimeChangedTo'] = 'La hora de entrada se ha cambiado a {1}';
lang_es['UIMessages.ClockAction.ClockInTimeGreaterThanPresentTime'] = 'La hora de entrada es mayor que la hora actual';
lang_es['UIMessages.ClockAction.ClockOutColorCheckFail'] = 'Se detectó mala calidad de imagen al marcar salida';
lang_es['UIMessages.ClockAction.ClockOutFacialDetectFail'] = 'Posible falla de detección facial al marcar salida';
lang_es['UIMessages.ClockAction.ClockOutPhotoNotValid'] = 'Los datos de la foto al marcar salida estaban corruptos';
lang_es['UIMessages.ClockAction.ClockOutTimeChangeRequest'] = 'La edición de la hora de salida está pendiente y no se reflejará hasta que haya sido aprobada / denegada / modificada por su supervisor.';
lang_es['UIMessages.ClockAction.ClockOutTimeChangedTo'] = 'La hora de salida se ha cambiado a {1}';
lang_es['UIMessages.ClockAction.ClockedInAt'] = 'Entrada marcada a las {1}';
lang_es['UIMessages.ClockAction.ClockedOutAt'] = 'Salida marcada a las {1}';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.AnalyzeError'] = 'Ocurrió un error al analizar la foto.';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.ClockInGeneral'] = 'Error de foto al Marcar Entrada:';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.ClockOutGeneral'] = 'Error de foto al Marcar Salida:';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.LowConfidence'] = 'Baja certeza ({1}%) de un rostro.';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.MultipleFacesDetected'] = 'Múltiples rostros detectados.';
lang_es['UIMessages.ClockAction.Error.ClockPhotoError.NoFaceDetected'] = 'No se ha detectado un rostro.';
lang_es['UIMessages.ClockAction.Error.ErrorOccurred'] = 'Ha ocurrido un error. Intente nuevamente.';
lang_es['UIMessages.ClockAction.Error.ErrorOccurredInfo'] = 'Es posible que deba cerrar sesión en su cuenta y volver a iniciar sesión para completar esta acción.';
lang_es['UIMessages.ClockAction.Error.HourlyNotEnabled'] = 'El Tiempo por Hora no está habilitado para su cuenta.';
lang_es['UIMessages.ClockAction.Error.HourlyNotEnabledInfo'] = 'No puede marcar su entrada o salida porque esta función no está habilitada para su cuenta. Consulte a su supervisor si cree que se trata de un error.';
lang_es['UIMessages.ClockAction.Error.ProblemClockOut'] = 'Hubo un problema para marcar su salida.';
lang_es['UIMessages.ClockAction.Error.ProblemClockOutInfo'] = 'Parece que estaba intentando marcar su salida, sin embargo, no había marcado su entrada aún.';
lang_es['UIMessages.ClockAction.Error.ProblemClockOutInfoContactSuper'] = 'Puede comunicarse con su supervisor para que registre el tiempo que hace falta.';
lang_es['UIMessages.ClockAction.Error.ProblemClockOutInfoIfEdit'] = 'Puede marcar su entrada ahora y luego solicitar un cambio en su hora de entrada. Más tarde, podrá marcar su salida con normalidad.';
lang_es['UIMessages.ClockAction.Error.ProblemClockOutInfoIfTimEntry'] = 'Puede usar el formulario de ingreso de tiempo en su hoja de tiempo para ingresar manualmente su tiempo de trabajo, si es necesario.';
lang_es['UIMessages.ClockAction.Error.ProblemClockingIn'] = 'Hubo un problema al marcar su entrada.';
lang_es['UIMessages.ClockAction.Error.ProblemClockingInInfo'] = 'Parece que estaba intentando marcar entrada, pero nuestros registros muestran que todavía está fichado desde un turno anterior. Primero marque salida y luego podrá marcar su entrada normalmente.';
lang_es['UIMessages.ClockAction.Error.ProblemClockingInInfoIfEdit'] = 'Puede solicitar un cambio en la hora de salida según sea necesario y luego ingresar (marcar su entrada) normalmente.';
lang_es['UIMessages.ClockAction.FeaturesRequiredForAccount'] = 'Las siguientes funciones están habilitadas para su cuenta:';
lang_es['UIMessages.ClockAction.FutureError'] = 'Los usuarios no pueden marcar entrada en el futuro';
lang_es['UIMessages.ClockAction.GeoLocationEnabled'] = 'La seguridad por geolocalización está habilitada para su cuenta, pero no se pudo adquirir una ubicación. Configure su navegador para permitir que se comparta su ubicación con este sitio.';
lang_es['UIMessages.ClockAction.HourlyTrackingNotEnabled'] = 'El ingreso de tiempo por hora no está habilitado para este usuario.';
lang_es['UIMessages.ClockAction.IPLocationFail'] = 'Las entradas/salidas no están permitidas en esta ubicación. Comuníquese con su administrador para agregar su ubicación actual.';
lang_es['UIMessages.ClockAction.InvalidClockOutDate'] = 'Fecha de salida no válida';
lang_es['UIMessages.ClockAction.InvalidLocationNoAction'] = 'No se puede marcar entrada/salida desde una ubicación no válida.';
lang_es['UIMessages.ClockAction.LoginBeforeAttemptingClockAction'] = 'Debe acceder a su cuenta para poder marcar entrada o salida.';
lang_es['UIMessages.ClockAction.ModificationErrorInvalidParameters'] = 'Error de modificación. Los parámetros de tiempo no son válidos.';
lang_es['UIMessages.ClockAction.ModificationErrorNotClockedIn'] = 'La hora no se puede modificar porque no ha marcado su entrada.';
lang_es['UIMessages.ClockAction.ModificationNotAllowed'] = 'No se permite modificar la hora. Consulte a su supervisor para obtener ayuda.';
lang_es['UIMessages.ClockAction.NoActionShiftRestricted'] = 'No se puede marcar entrada/salida con este usuario porque su turno está restringido.';
lang_es['UIMessages.ClockAction.OutDateTimeBeforeIn'] = 'La fecha y la hora de salida son anteriores a la fecha y la hora de entrada.';
lang_es['UIMessages.ClockAction.PhotoDisabledDuringIn'] = 'El Registro de Foto estaba desactivado al marcar entrada.';
lang_es['UIMessages.ClockAction.PhotoDisabledDuringOut'] = 'El Registro de Foto estaba desactivado al marcar salida.';
lang_es['UIMessages.ClockAction.PhotoSecurityEnabled'] = 'El Registro de Foto está habilitado para su cuenta. Debe iniciar sesión en su cuenta para marcar su entrada o salida.';
lang_es['UIMessages.ClockAction.Requirement.GeolocationEnabled'] = 'Geolocalización Habilitada';
lang_es['UIMessages.ClockAction.Requirement.PhotoSecurityEnabled'] = 'Registro por Foto habilitado';
lang_es['UIMessages.ClockAction.ShiftRestrictionFail'] = 'Solo está permitido marcar entrada entre {1} - {2}';
lang_es['UIMessages.ClockAction.UserAlreadyClockedIn'] = 'El usuario ya ha marcado su entrada';
lang_es['UIMessages.ClockAction.UserAlreadyClockedOut'] = 'El usuario ya ha marcado su salida';
lang_es['UIMessages.ClockAction.UserClockedIn'] = 'El usuario ha marcado su entrada';
lang_es['UIMessages.ClockAction.UserClockedOut'] = 'El usuario ha marcado su salida';
lang_es['UIMessages.ClockAction.UserIsClockedIn'] = 'El usuario está fichado en este momento';
lang_es['UIMessages.ClockAction.UserIsNotClockedIn'] = 'El usuario no está fichado en este momento';
lang_es['UIMessages.ClockAction.UserIsShiftRestricted'] = 'La restricción de turno permite que este usuario esté fichado solo entre {1} y {2}.';
lang_es['UIMessages.Contact.EmailSent'] = 'El correo ha sido enviado';
lang_es['UIMessages.Contact.FeelFree'] = 'Por favor no dude en contactarnos en cualquier momento.';
lang_es['UIMessages.Contact.FeelFreeStaffReady'] = 'Nuestro personal está listo para ayudarle a configurar su cuenta o si tiene alguna pregunta relacionada con nuestro servicio.';
lang_es['UIMessages.Contact.NameEmailRequired'] = 'Se requiere un nombre y una dirección de correo electrónico válidos.';
lang_es['UIMessages.Exports.ADPBatchNumber'] = 'El número de lote de ADP debe ser un valor alfanumérico de 2 caracteres.';
lang_es['UIMessages.Exports.ADPCompanyCode'] = 'El código de compañía de ADP debe tener un valor alfanumérico de 3 caracteres.';
lang_es['UIMessages.Exports.ADPRunCompanyCode'] = 'El código de compañía de ADP Run debe tener un valor alfanumérico de 3 a 15 caracteres.';
lang_es['UIMessages.Exports.CodeForXTime'] = 'Ingrese un código para \'\'{1}\'\' horas.';
lang_es['UIMessages.Exports.IIFVersionNumberTooLong'] = 'El valor del número de versión de IIF es demasiado largo.';
lang_es['UIMessages.Exports.PaychexPreviewClientNumber'] = 'Ingrese su número de cliente de Paychex Preview.';
lang_es['UIMessages.Exports.PayrollCS.HolidayItemRequired'] = 'El valor de las horas de días feriados debe ser numérico.';
lang_es['UIMessages.Exports.PayrollCS.PersonalItemRequired'] = 'El valor de las horas personales debe ser numérico.';
lang_es['UIMessages.Exports.PayrollCS.SickItemRequired'] = 'El valor de las horas de incapacidad debe ser numérico.';
lang_es['UIMessages.Exports.PayrollCS.VacationItemRequired'] = 'El valor de las horas de vacaciones debe ser numérico.';
lang_es['UIMessages.Exports.PayrollRelief.ClientCodeRequired'] = 'Se requiere el código de cliente.';
lang_es['UIMessages.Exports.PayrollRelief.EmployerNameRequired'] = 'Se requiere el nombre del empleador.';
lang_es['UIMessages.Exports.PayrollRelief.FirmCodeRequired'] = 'Se requiere el código de la firma.';
lang_es['UIMessages.Exports.PayrollRelief.FirmRequired'] = 'Se requiere el nombre de la firma.';
lang_es['UIMessages.Exports.PayrollRelief.PayScheduleRequired'] = 'Se requiere la descripción del calendario de pago.';
lang_es['UIMessages.Exports.QBCompanyFileNo'] = 'El valor del QB File Number es demasiado largo.';
lang_es['UIMessages.Exports.QBReleaseNumberTooLong'] = 'El valor de QB Release Number es demasiado largo.';
lang_es['UIMessages.Exports.ValueDoubleTime'] = 'Especifique un valor para Double Time.';
lang_es['UIMessages.Exports.ValueOvertime'] = 'Especifique un valor para las horas extra.';
lang_es['UIMessages.Exports.ValueStandardTime'] = 'Especifique un valor para las horas estándar.';
lang_es['UIMessages.Exports.ValueStandardTimeNumeric'] = 'El valor de las horas estándar debe ser numérico.';
lang_es['UIMessages.Exports.ValueXTime'] = 'Especifique un valor para \'\'{1}\'\'.';
lang_es['UIMessages.File.BlockedScanFailed'] = 'bloqueado, el análisis antivirus falló';
lang_es['UIMessages.File.ChooseValidFormat'] = 'Elija un formato de archivo válido.';
lang_es['UIMessages.File.FileAvailableHere'] = 'El archivo está disponible para descargar {1}aquí{2}.';
lang_es['UIMessages.File.FileHeaderInvalid'] = 'El encabezado del archivo es incorrecto.';
lang_es['UIMessages.File.InvalidEvents'] = 'Uno o más eventos del archivo no eran válidos. Compruebe el archivo e inténtelo de nuevo o póngase en contacto con soporte.';
lang_es['UIMessages.File.InvalidFileType'] = 'Tipo de archivo no válido';
lang_es['UIMessages.File.InvalidKey'] = 'Clave de archivo no válida. Inténtalo de nuevo.';
lang_es['UIMessages.File.Latest'] = 'Más reciente';
lang_es['UIMessages.File.Limit10MB'] = 'Archivos limitados a 10 MB';
lang_es['UIMessages.File.LineContainsErrors'] = 'Línea {1} contiene errores';
lang_es['UIMessages.File.LineDuplicate'] = 'Se encontró que la línea {1} era un duplicado de un artículo existente.';
lang_es['UIMessages.File.LineInvalidApplicationCode'] = 'La línea {1} tiene un código de aplicación no válido.';
lang_es['UIMessages.File.LineInvalidCustomerAssociation'] = 'La línea {1} tiene un valor de asociación de cliente no válido. El valor debe ser Sí o No.';
lang_es['UIMessages.File.LineInvalidMinTimeIncrement'] = 'La línea {1} tiene un incremento de tiempo mínimo no válido.';
lang_es['UIMessages.File.LineInvalidName'] = 'La línea {1} tiene un nombre de elemento no válido.';
lang_es['UIMessages.File.LineInvalidProjectAssociation'] = 'La línea {1} tiene un valor de asociación de proyecto no válido. El valor debe ser Sí o No.';
lang_es['UIMessages.File.LineInvalidRate'] = 'La línea {1} tiene una tarifa no válida. Las tarifas deben estar entre {2} y {3}.';
lang_es['UIMessages.File.LineInvalidUserAssociation'] = 'La línea {1} tiene un valor de asociación de usuario no válido. El valor deben ser Sí o No.';
lang_es['UIMessages.File.LineNotSaved'] = 'No se pudo guardar la línea {1}.';
lang_es['UIMessages.File.LineTooManyFields'] = 'La línea {1} tiene demasiados campos.';
lang_es['UIMessages.File.NameLimit135Chars'] = 'El nombre del archivo es demasiado largo. Por favor limite el nombre del archivo a 135 caracteres.';
lang_es['UIMessages.File.NoValidFileUploaded'] = 'No se ha subido ningún archivo válido';
lang_es['UIMessages.File.RateErrorNotUpdated'] = 'Hubo un error desconocido. Las tarifas no se actualizaron.';
lang_es['UIMessages.File.RateUpdateSuccessful'] = 'Actualización de tasa completada';
lang_es['UIMessages.File.Scanning'] = 'escaneando...';
lang_es['UIMessages.File.TimedOutTryAnotherOrAgain'] = 'Se agotó el tiempo de carga del archivo. Vuelva a intentarlo con un archivo más pequeño o una conexión más rápida.';
lang_es['UIMessages.File.UploadedSuccessfully'] = 'Documento cargado exitosamente';
lang_es['UIMessages.General.404NotFound'] = 'La página solicitada no pudo ser encontrada.';
lang_es['UIMessages.General.ActionCannotBeUndone'] = 'Esta acción no se puede revertir.';
lang_es['UIMessages.General.AddSuccessful'] = 'Adición completada';
lang_es['UIMessages.General.AdditionalDetails'] = 'Detalles adicionales: {1}';
lang_es['UIMessages.General.CallCustomerService'] = 'Llame a soporte para obtener ayuda.';
lang_es['UIMessages.General.CopySuccessful'] = 'Copia exitosa';
lang_es['UIMessages.General.DeleteError'] = 'Eliminación no completada';
lang_es['UIMessages.General.DeleteSuccessful'] = 'Eliminación completada';
lang_es['UIMessages.General.EditSuccessful'] = 'Edición completada';
lang_es['UIMessages.General.Error'] = 'Hubo un error.';
lang_es['UIMessages.General.ErrorCommunicatingWithServer'] = 'Hubo un error de comunicación con el servidor.';
lang_es['UIMessages.General.ErrorGeneratingPDF'] = 'Se produjo un error al generar el PDF.';
lang_es['UIMessages.General.ErrorGettingHostNameFromIPAddress'] = 'Error al obtener el hostname de la dirección IP.';
lang_es['UIMessages.General.ErrorLoadingClock'] = 'Error al cargar el reloj...';
lang_es['UIMessages.General.ErrorLoadingData'] = 'Error al cargar la data...';
lang_es['UIMessages.General.ErrorTryAgain'] = 'Hubo un error. Inténtelo de nuevo.';
lang_es['UIMessages.General.Failed'] = 'Fallido';
lang_es['UIMessages.General.Failure'] = 'Falla';
lang_es['UIMessages.General.FileSizeLimitedToXMB'] = 'El tamaño del archivo está limitado a {1} MB.';
lang_es['UIMessages.General.FileStorageNotEnabled'] = 'En este momento, el almacenamiento de archivos no esta habilitado para su cuenta. Por favor llame a soporte.';
lang_es['UIMessages.General.FollowingErrorsOccured'] = 'Los siguientes errores ocurrieron:';
lang_es['UIMessages.General.ImportCompletedWithErrors'] = 'La importación se completó con los siguientes errores:';
lang_es['UIMessages.General.InputEmpty'] = 'Por favor complete el campo.';
lang_es['UIMessages.General.InvalidAction'] = 'Acción no válida';
lang_es['UIMessages.General.InvalidActionTryAgain'] = 'Acción no válida. Intente realizar su solicitud nuevamente.';
lang_es['UIMessages.General.InvalidSelection'] = 'Una o mas selecciones no son validas.';
lang_es['UIMessages.General.MakeSelectionForAction'] = 'Haga una selección para completar la acción.';
lang_es['UIMessages.General.MoveSuccessful'] = 'Movimiento exitoso';
lang_es['UIMessages.General.NoData'] = 'Sin datos.';
lang_es['UIMessages.General.NoDataToMap'] = 'No hay datos para mapear.';
lang_es['UIMessages.General.NoLicensesAvailable'] = 'Su cuenta ha alcanzado el número máximo de licencias de usuario disponibles. Para agregar un nuevo usuario, puede archivar a un usuario existente o hacer que el administrador de la cuenta principal adquiera licencias adicionales.';
lang_es['UIMessages.General.NoPermissionForAction'] = 'No tiene permiso para esta acción.';
lang_es['UIMessages.General.NoPermissionForActionContactSup'] = 'No tiene permiso para esta acción. Comuníquese con su supervisor.';
lang_es['UIMessages.General.NoPermissionToModifyItem'] = 'No cuenta con permiso para modificar el ajuste \'\'{1}\'\'.';
lang_es['UIMessages.General.NoValidActionSelected'] = 'No se seleccionó ninguna acción válida.';
lang_es['UIMessages.General.Payment.AccountCancelledEndOfBiillingPeriod'] = 'Su cuenta ha sido cancelada y caducará al final de su período de facturación actual.';
lang_es['UIMessages.General.Payment.AccountCancelledImmediately'] = 'Su cuenta ha sido cancelada con efecto inmediato.';
lang_es['UIMessages.General.Payment.AccountDeletionConfirmationEmailSent'] = 'Se ha enviado un correo electrónico para confirmar la eliminación de los datos de su cuenta.';
lang_es['UIMessages.General.Payment.AccountIsActivated'] = '¡Su cuenta está activada!';
lang_es['UIMessages.General.Payment.AccountIsUpToDate'] = 'Su cuenta ha sido actualizada.';
lang_es['UIMessages.General.Payment.AccountIsUpdated'] = 'Su cuenta está actualizada.';
lang_es['UIMessages.General.Payment.AccountMustBeTrialOrExpired'] = 'Su cuenta debe estar en período de prueba o vencida.';
lang_es['UIMessages.General.Payment.BillingInfoUpdated'] = 'Se actualizó la información de facturación.';
lang_es['UIMessages.General.Payment.BillingInfoUpdatedFailedCharged'] = 'La información de facturación se actualizó pero no se le pudo cobrar a la tarjeta de crédito.';
lang_es['UIMessages.General.Payment.CannotCreateSingleUserAccount'] = 'No puede crear una cuenta de usuario única.';
lang_es['UIMessages.General.Payment.ConfirmationEmailSentToYou'] = 'Se le ha enviado un correo electrónico de confirmación.';
lang_es['UIMessages.General.Payment.ErrorGeneratingReceipt'] = 'Hubo un error al generar un recibo.';
lang_es['UIMessages.General.Payment.FreeAccountCancelled'] = 'Su cuenta gratuita está cancelada. Esperamos verle de nuevo.';
lang_es['UIMessages.General.Payment.MoreThanOneUserInAccount'] = 'Hay más de un usuario activo en la cuenta.';
lang_es['UIMessages.General.Payment.NoChangesMadeToAccount'] = 'No se han realizado cambios en la cuenta. Debe cambiar la cantidad de licencias de usuario u opciones de cuenta para continuar con el pago.';
lang_es['UIMessages.General.Payment.OnlyPrimaryAdminCanCancelService'] = 'Solo el administrador de la cuenta principal puede cancelar el servicio.';
lang_es['UIMessages.General.Payment.PaymentMade'] = 'Ha realizado un pago.';
lang_es['UIMessages.General.Payment.PromoCodeApplied'] = 'Se aplicó el código de promoción.';
lang_es['UIMessages.General.Payment.PromoCodeInvalid'] = 'El código de promoción no es válido.';
lang_es['UIMessages.General.Payment.PromoCodeRemoved'] = 'Se eliminó el código de promoción.';
lang_es['UIMessages.General.Payment.ReceiptEmailedTo'] = 'El recibo se envió por correo electrónico a {1}.';
lang_es['UIMessages.General.Payment.SaveForRecords'] = 'Guarde esta información para sus registros.';
lang_es['UIMessages.General.Payment.SingleUserAccountActivated'] = 'Se ha activado su cuenta gratuita de usuario único. ¡Muchas gracias!';
lang_es['UIMessages.General.Payment.SubdomainAlphaNumeric'] = 'El subdominio debe ser sólo caracteres alfanuméricos.';
lang_es['UIMessages.General.Payment.SubdomainInUse'] = 'El subdominio ya está en uso. Por favor elija otro.';
lang_es['UIMessages.General.Payment.SubdomainRequired'] = 'Se requiere un subdominio válido para la opción de marca privada.';
lang_es['UIMessages.General.Payment.SubscriptionLevel'] = 'Seleccione un nivel de suscripción.';
lang_es['UIMessages.General.Payment.ThankYou'] = '¡Gracias!';
lang_es['UIMessages.General.Payment.ThereAreXDaysLeftInTrial'] = 'Quedan {1} días en su período de prueba.';
lang_es['UIMessages.General.Payment.TransactionDeclined'] = 'La transacción fue rechazada.';
lang_es['UIMessages.General.Payment.TransactionFlaggedForReview'] = 'La transacción se marcó para revisión.';
lang_es['UIMessages.General.Payment.TrialPeriodHasExpired'] = 'El período de prueba ha expirado.';
lang_es['UIMessages.General.PermanentlyDeleteItem'] = '¿Eliminar estos elementos de forma permanente?';
lang_es['UIMessages.General.PermanentlyDeleteItems'] = 'Borrar permanentemente estos elementos?';
lang_es['UIMessages.General.PleaseContactForAssistance'] = 'Comuníquese con soporte para obtener ayuda.';
lang_es['UIMessages.General.PleaseEnterAMessage'] = 'Por favor ingrese un mensaje.';
lang_es['UIMessages.General.ReasonForEditRequired'] = 'Se requiere el motivo de la edición';
lang_es['UIMessages.General.ReportBeingProcessed'] = 'El reporte está siendo procesado. Se le notificará cuando esté completo.';
lang_es['UIMessages.General.ReportContainsNoData'] = 'El reporte no contiene datos.';
lang_es['UIMessages.General.ReportExpired'] = 'El reporte ha caducado. Inténtelo de nuevo.';
lang_es['UIMessages.General.SaveSuccessful'] = 'Se guardaron los cambios';
lang_es['UIMessages.General.Success'] = 'Éxito';
lang_es['UIMessages.General.ThankYou'] = 'Gracias.';
lang_es['UIMessages.General.UnknownErrorOccured'] = 'Ha ocurrido un error desconocido.';
lang_es['UIMessages.General.UpdateSuccessful'] = 'Actualización exitosa.';
lang_es['UIMessages.General.ValueAlreadyExists'] = 'El valor ya existe.';
lang_es['UIMessages.HR.CurrentHourlyRateOrBlank'] = 'La tarifa de pago por hora actual debe ser numérica o debe dejarse en blanco';
lang_es['UIMessages.HR.CurrentSalaryRateOrBlank'] = 'La tarifa de pago de salario actual debe ser numérica o dejarse en blanco';
lang_es['UIMessages.HR.InvalidReportID'] = 'ID de Reporte de Recursos Humanos no válido';
lang_es['UIMessages.HR.InvalidUserIDForReport'] = 'ID de usuario no válida para este ID de Reporte de Recursos Humanos';
lang_es['UIMessages.HR.NewHourlyRateOrBlank'] = 'La nueva tarifa de pago por hora debe ser numérica o dejarse en blanco';
lang_es['UIMessages.HR.NewSalaryRateOrBlank'] = 'La nueva tarifa de pago de salario debe ser numérica o dejarse en blanco';
lang_es['UIMessages.HR.ReportDateIsRequired'] = 'Se requiere una fecha de informe válida.';
lang_es['UIMessages.HR.ReportReviewTypeRequired'] = 'Se requiere el tipo de evaluación de desempeño.';
lang_es['UIMessages.HR.ReviewerNameRequired'] = 'Se requiere el nombre del revisor.';
lang_es['UIMessages.HR.UserToReviewRequired'] = 'Se requiere que el usuario revise.';
lang_es['UIMessages.Lists.AccessRestrictedToItems'] = 'El acceso a estos elementos está restringido.';
lang_es['UIMessages.Lists.AcctCodeFeatureRequirement'] = 'El elemento de la lista debe estar habilitado para al menos una función.';
lang_es['UIMessages.Lists.AcctCodeInvalid'] = 'El código de cuenta no es válido. Inténtelo de nuevo.';
lang_es['UIMessages.Lists.AcctCodeRequired'] = 'Se requiere un codigo de cuenta valido.';
lang_es['UIMessages.Lists.BusinessFaxOrBlank'] = 'Ingrese un fax comercial válido o déjelo en blanco.';
lang_es['UIMessages.Lists.BusinessPhoneOrBlank'] = 'Ingrese un teléfono comercial válido o déjelo en blanco.';
lang_es['UIMessages.Lists.ContactPhoneOrBlank'] = 'Ingrese un teléfono de contacto válido o déjelo en blanco.';
lang_es['UIMessages.Lists.CustomerProjectMismatch'] = 'El cliente y el proyecto no son una combinacion valida.';
lang_es['UIMessages.Lists.CustomerProjectRequired'] = 'Se requiere una combinación válida de cliente y proyecto.';
lang_es['UIMessages.Lists.CustomerRequired'] = 'Se requiere un cliente válido.';
lang_es['UIMessages.Lists.EventRequired'] = 'Se requiere un evento válido.';
lang_es['UIMessages.Lists.ItemAdded'] = 'Elemento agregado.';
lang_es['UIMessages.Lists.ItemArchived'] = 'Elemento archivado.';
lang_es['UIMessages.Lists.ItemArchivedCount'] = 'Elementos archivados: {1,number,#}';
lang_es['UIMessages.Lists.ItemDeleted'] = 'Elemento eliminado.';
lang_es['UIMessages.Lists.ItemDeletedCount'] = 'Elementos eliminados: {1,number,#}';
lang_es['UIMessages.Lists.ItemInaccessibleUserSettings'] = 'Los siguientes elementos son inaccesibles según la configuración del usuario:';
lang_es['UIMessages.Lists.ItemNameRequired'] = 'Nombre del elemento requerido.';
lang_es['UIMessages.Lists.ItemRateRange'] = 'La tarifa del artículo debe estar entre {1,number,#} y {2,number,#}.';
lang_es['UIMessages.Lists.ItemRateRangeMultiple'] = 'Las tarifas para uno o más elemtnso no se actualizaron porque exceden el rango permitido.';
lang_es['UIMessages.Lists.ItemRestored'] = 'Elemento restaurado.';
lang_es['UIMessages.Lists.ItemRestoredCount'] = 'Elementos restaurados: {1,number,#}';
lang_es['UIMessages.Lists.ItemUpdated'] = 'Elemento actualizado.';
lang_es['UIMessages.Lists.ItemWasArchived'] = 'Este elemento fur archivado.';
lang_es['UIMessages.Lists.ItemWasDeleted'] = 'Este elemento fue eliminado.';
lang_es['UIMessages.Lists.ItemWasNotArchived'] = 'Este elemento no fue archivado.';
lang_es['UIMessages.Lists.ItemWasNotDeleted'] = 'Este elemento no fue eliminado.';
lang_es['UIMessages.Lists.ItemsArchived'] = 'Elementos archivados.';
lang_es['UIMessages.Lists.ItemsCouldNotArchive'] = 'Los siguientes elementos no se pueden archivar hasta que todos los registros que hacen referencia a ellos se hayan cerrado o archivado:';
lang_es['UIMessages.Lists.ItemsCouldNotDelete'] = 'Los siguientes elementos no se pueden eliminar porque se han utilizado. Elimine todas las entradas que hagan referencia al elemento o archive el elemento en su lugar:';
lang_es['UIMessages.Lists.ItemsCouldNotRestore'] = 'No se pudieron restaurar los siguientes elementos:';
lang_es['UIMessages.Lists.ItemsReadOnly'] = 'Los siguientes elementos son de solo lectura y no se pueden realizar acciones:';
lang_es['UIMessages.Lists.ItemsReadOnlyCannotDelete'] = 'Los siguientes elementos son de solo lectura y no se pueden eliminar:';
lang_es['UIMessages.Lists.ListImportCancelled'] = 'Importacion cancelada.';
lang_es['UIMessages.Lists.ListImportDoesNotExist'] = 'El archivo de importación no existe.';
lang_es['UIMessages.Lists.ListImported'] = '¡Lista importada!';
lang_es['UIMessages.Lists.MinTimeIncrementRequired'] = 'Se requiere un incremento de tiempo mínimo válido.';
lang_es['UIMessages.Lists.NewItemLimitAtATime'] = 'Se puede importar un máximo de {1} elementos a la vez. Reduzca la cantidad de elementos a importar y vuelva a intentarlo.';
lang_es['UIMessages.Lists.NewItemLimitPer24Hours'] = 'Actualmente, la cuenta está limitada a {1,number,#} elementos nuevos por período de 24 horas. Espere y vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.Lists.OneMoreUsersInvalid'] = 'Uno o más usuarios no eran válidos.';
lang_es['UIMessages.Lists.OneMoreUsersItemsInvalid'] = 'Uno o más usuarios y / o elementos no eran válidos.';
lang_es['UIMessages.Lists.ProjectRequired'] = 'Se requiere un proyecto válido.';
lang_es['UIMessages.Lists.UserSelectionRequired'] = 'Realice una selección de usuario.';
lang_es['UIMessages.Lists.VendorRequired'] = 'Se requiere un proveedor valido.';
lang_es['UIMessages.Memo.MemoCleared'] = 'Memo borrado';
lang_es['UIMessages.Memo.MemoSent'] = 'Memo enviado';
lang_es['UIMessages.Memo.MemoUpdated'] = 'Memo actualizado';
lang_es['UIMessages.MessageBoard.Deleted'] = 'Publicación eliminada';
lang_es['UIMessages.MessageBoard.Posted'] = 'Publicación actualizada';
lang_es['UIMessages.Note.NotUpdatedNotFound'] = 'No se pudo actualizar la nota porque no se pudo encontrar.';
lang_es['UIMessages.Note.NoteDeleted'] = 'Nota eliminada';
lang_es['UIMessages.Note.NoteNotFound'] = 'Nota no encontrada';
lang_es['UIMessages.Note.NoteSaved'] = 'Nota guardada';
lang_es['UIMessages.Note.NoteUpdated'] = 'Nota actualizada';
lang_es['UIMessages.Payroll.AccrualsEnabledForFeature'] = 'Las acumulaciones deben estar habilitadas para usar esta función.';
lang_es['UIMessages.Payroll.AccruedHoursNotUpdatedForX'] = 'Las horas acumuladas para los siguientes usuarios no se pudieron actualizar debido a un error';
lang_es['UIMessages.Payroll.AccruedHoursUpdated'] = 'Se han actualizado las horas acumuladas de todos los usuarios.';
lang_es['UIMessages.Payroll.AlertsExist'] = 'Existen alertas para uno o más usuarios incluidos en este reporte de nómina.';
lang_es['UIMessages.Payroll.AlertsExistInfo'] = 'El reporte del período de nómina no se puede guardar hasta que se resuelvan las alertas o los usuarios con alertas pendientes se eliminen del reporte.';
lang_es['UIMessages.Payroll.ArchivedUserReactivated'] = 'Se reactivó un usuario archivado.';
lang_es['UIMessages.Payroll.ArchivedUsersCountReactivated'] = 'Se reactivaron {1} usuarios archivados.';
lang_es['UIMessages.Payroll.CannotBeEmailedNoAddressOnFile'] = 'La nómina no se puede enviar por correo electrónico porque no hay una dirección de correo electrónico del destinatario registrada.';
lang_es['UIMessages.Payroll.ContainedInMoreRecentForX'] = 'No se pudo generar el reporte de nómina porque los siguientes usuarios están incluidos en un período de nómina cerrado más reciente';
lang_es['UIMessages.Payroll.ContainedInMoreRecentInfo'] = 'Cambiar el tiempo de un usuario en este período podría afectar sus horas extra en un periodo más reciente. Para agregar este tiempo al reporte de nómina, deberá eliminar el (los) usuario(s) de cualquier reporte de período de nómina más reciente y luego volver a intentarlo.';
lang_es['UIMessages.Payroll.DuplicateDeleted'] = 'Nómina duplicada eliminada';
lang_es['UIMessages.Payroll.ErrorGeneratingPayrollFile'] = 'Hubo un error al generar el archivo de nómina. Vuelva a intentarlo o póngase en contacto con soporte para obtener más ayuda.';
lang_es['UIMessages.Payroll.ExceedMaxContinuousWorked'] = 'Este reporte de nómina contiene registros que superan el umbral de {1} para el máximo de horas continuas trabajadas.';
lang_es['UIMessages.Payroll.ExceedMaxContinuousWorkedInfo'] = 'Uno o más registros en este reports exceden el límite del tiempo de almuerzo, de acuerdo a la normativa. Por favor expanda los detalles más abajo para estos usuarios y revise las líneas mostradas en letras rojas.';
lang_es['UIMessages.Payroll.IncompatibleTimeZonesForX'] = 'No se pudo generar el reporte de nómina debido a registros de zona horaria incompatibles para';
lang_es['UIMessages.Payroll.InvalidAccrualSettingsForX'] = 'No se pudo generar el reporte de nómina porque los siguientes usuarios tienen configuraciones de acumulación no válidas o incompletas';
lang_es['UIMessages.Payroll.InvalidTryAgain'] = 'El reporte del período de nómina seleccionado no es válido. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.Payroll.LockedTryAgain'] = 'El reporte está bloqueado y no se puede procesar. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.Payroll.MultipleTimeZonesForX'] = 'No se pudo generar el reporte de nómina porque los siguientes usuarios tienen registros en varias zonas horarias para el período de nómina';
lang_es['UIMessages.Payroll.NoPayrollDataForUsers'] = 'No hay datos de ninguno de sus usuarios asignados en este período de nómina.';
lang_es['UIMessages.Payroll.NoTimeRecordsTryAgain'] = 'El reporte no incluye ningún registro de tiempo y no se puede procesar. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.Payroll.NoUnpaidRecordsExist'] = 'No existen registros impagos para esas fechas.';
lang_es['UIMessages.Payroll.NotGeneratedAlertsExistFor'] = 'No se pudo generar el reporte de nómina porque existen alertas para los siguientes usuarios:';
lang_es['UIMessages.Payroll.OverlappingDates'] = 'Las fechas del período de nómina se superponen a un período de nómina cerrado existente.';
lang_es['UIMessages.Payroll.OverlappingDatesInfo'] = 'La superposición de reportes de períodos de nómina puede resultar en cálculos incorrectos de horas extra para los usuarios enumerados anteriormente. Es posible que desee revisar los reportes de períodos de nómina anteriores para asegurarse de que los usuarios solo aparezcan en un único reporte para cualquier período de nómina determinado.';
lang_es['UIMessages.Payroll.OverlappingRecords'] = 'El reporte de nómina contiene tiempos superpuestos en uno o más usuarios.';
lang_es['UIMessages.Payroll.OverlappingRecordsForX'] = 'El reporte de nómina contiene tiempos superpuestos para';
lang_es['UIMessages.Payroll.OverlappingRecordsInfo'] = 'Utilice el Reporte de Registros Superpuestos para ver los registros que causan el problema. Para obtener ayuda adicional, comuníquese con soporte.';
lang_es['UIMessages.Payroll.PayrollRunTypeIsRequired'] = 'El tipo de nómina se debe especificar con las fechas correspondientes.';
lang_es['UIMessages.Payroll.PendingAlertsIncluded'] = 'El reporte incluye registros con alertas pendientes y no se puede procesar.';
lang_es['UIMessages.Payroll.RecordsChangedTryAgain'] = 'Uno o más registros han cambiado. Vuelva a intentar la acción.';
lang_es['UIMessages.Payroll.Reopened'] = 'Período de nómina reabierto';
lang_es['UIMessages.Payroll.ReopenedForSelected'] = 'Período de nómina reabierto para el usuario seleccionado';
lang_es['UIMessages.Payroll.ReportExpiredTryAgain'] = 'El reporte ha caducado. Inténtelo de nuevo.';
lang_es['UIMessages.Payroll.SelectUserToDelete'] = 'Debe seleccionar el reporte de nómina de un usuario para eliminar.';
lang_es['UIMessages.Payroll.SplitTimeRecordsExist'] = 'Este reporte de período de nómina contiene registros de tiempo fraccionado para uno o más usuarios.';
lang_es['UIMessages.Payroll.SplitTimeRecordsExistInfo'] = 'Este reporte de período de nómina contiene registros de tiempo fraccionado para uno o más usuarios. Un registro de tiempo parcial cruza el umbral de inicio del día y puede ser un error cuando los usuarios se olvidan de fichar la salida. Si un registro se divide el último día del período de nómina, el registro se marcará como Pago dividido y existirá parcialmente en dos reportes separados. Los registros divididos se resaltan en los detalles a continuación en texto ROJO.';
lang_es['UIMessages.Payroll.UnpaidRecordsExist'] = 'Existen registros de tiempo no pagados antes de la fecha de inicio del período de nómina seleccionado para uno o más usuarios.';
lang_es['UIMessages.Payroll.UnpaidRecordsExistInfo'] = 'Hay registros de tiempo abiertos y posiblemente no remunerados antes de la fecha de inicio del reporte. Todos los registros deben cerrarse para evitar el no pagarle a un usuario. Para ver los registros de tiempo abiertos, ejecute un reporte previo a la nómina para las fechas anteriores a la fecha de inicio del período de nómina.';
lang_es['UIMessages.Payroll.UserAlreadyIncluded'] = 'No se puede agregar un usuario a un reporte de nómina en el que ya está incluido. Cambie los parámetros de la nómina o póngase en contacto con soporte.';
lang_es['UIMessages.Payroll.UsersAlreadyIncluded'] = 'Uno o más usuarios ya están incluidos en el reporte del período de nómina que está intentando modificar.';
lang_es['UIMessages.Payroll.UsersAlreadyIncludedInfo'] = 'Para ajustar el horario de un usuario, primero debe eliminarlo del reporte existente y luego intentarlo nuevamente.';
lang_es['UIMessages.Payroll.UsersCannotDelete'] = 'Los usuarios seleccionados para eliminar no eran válidos para este reporte. Inténtalo de nuevo.';
lang_es['UIMessages.Payroll.UsersCannotDeleteMoreRecent'] = 'El reporte de nómina no se puede eliminar porque incluye usuarios que están incluidos en un reporte de nómina más reciente. Elimine los usuarios de los reportes más recientes y vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.Payroll.ValidationErrorTryAgain'] = 'Hubo un error de validación. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.QBO.APICallFailed'] = 'Error en la llamada a la API de QBO';
lang_es['UIMessages.QBO.APICallSuccessful'] = 'Llamada a la API de QBO exitosa';
lang_es['UIMessages.QBO.AccountInvalid'] = 'La cuenta no es válida para la exportación de QuickBooks Online.';
lang_es['UIMessages.QBO.AuthorizationIncomplete'] = 'No se pudo completar la autorización con QuickBooks Online.';
lang_es['UIMessages.QBO.DisconnectSuccessful'] = 'Se desconectó de QuickBooks Online exitosamente.';
lang_es['UIMessages.QBO.ErrorPleaseSignInAgain'] = 'Hubo un error. Vuelve a iniciar sesión.';
lang_es['UIMessages.QBO.ExportDeleted'] = 'Exportación eliminada';
lang_es['UIMessages.QBO.ExportIDInvalid'] = 'El ID de exportación no es válido. Inténtelo de nuevo.';
lang_es['UIMessages.QBO.ExportInvalid'] = 'La exportación no es válida.';
lang_es['UIMessages.QBO.ExportRestarted'] = 'Exportación reiniciada';
lang_es['UIMessages.QBO.ExportStillPending'] = 'La exportación aún está pendiente.';
lang_es['UIMessages.QBO.FailedRecordsDeleted'] = 'Se eliminaron los registros fallidos';
lang_es['UIMessages.QBO.NotConfigured'] = 'La cuenta no está configurada para autorización con QuickBooks Online.';
lang_es['UIMessages.QBO.NotConnected'] = 'La cuenta aún no se ha conectado a QuickBooks Online.';
lang_es['UIMessages.QBO.OperationCompleteCount'] = 'Operación completa. Se agregaron {1} elementos. No se pudieron agregar {2} elementos.';
lang_es['UIMessages.QBO.QBOSubmitted'] = 'Los registros se enviaron para su exportación a QuickBooks Online. Se le notificará cuando se complete el proceso.';
lang_es['UIMessages.QBO.RecordsAlreadyExported'] = 'Ya se han exportado uno o más registros marcados para exportación.';
lang_es['UIMessages.QBO.TokenRefresh'] = 'Actualización de tokens exitoso';
lang_es['UIMessages.QBO.YouAreNowSignedIn'] = 'Ha iniciado sesión en su cuenta de QuickBooks Online.';
lang_es['UIMessages.Record.ArchivedCannotBeEdited'] = 'El registro se ha archivado y no se puede editar.';
lang_es['UIMessages.Record.ChangedFromBillableToUnbillable'] = 'El registro se cambió de facturable a no facturable.';
lang_es['UIMessages.Record.ChangedFromUnbillableToBillable'] = 'El registro se cambió de no facturable a facturable.';
lang_es['UIMessages.Record.ReceiptCannotBeGenerated'] = 'No se puede generar el recibo. Llame a soporte para obtener ayuda.';
lang_es['UIMessages.Record.RecordCannotBeApprovedOrDenied'] = 'El registro no se puede aprobar ni negar.';
lang_es['UIMessages.Record.RecordMustBeEditedByAdminToClearAlert'] = 'El registro debe ser editado por un administrador para borrar la alerta.';
lang_es['UIMessages.Record.RecordMustBeEditedToClearAlert'] = 'El registro debe editarse para borrar la alerta.';
lang_es['UIMessages.Record.RoundedToNearest'] = 'Redondeado a los {1} minutos más cercanos.';
lang_es['UIMessages.Record.SavedWithHrs'] = 'Registro guardado con {1} horas.';
lang_es['UIMessages.Record.SavedWithHrsOnDate'] = 'Guardado con {1} horas el {2}.';
lang_es['UIMessages.Record.SingleSubmittedForSuperApproval'] = 'Enviado para aprobación del supervisor.';
lang_es['UIMessages.Record.Updated'] = 'Registro actualizado.';
lang_es['UIMessages.Records.Added'] = 'Registro agregado';
lang_es['UIMessages.Records.AddedMany'] = 'Registros agregados';
lang_es['UIMessages.Records.AddedReceiptError'] = 'Registro guardado pero hubo un error al guardar el recibo. Intente editar el registro y agregar el recibo nuevamente. Los tipos de archivo aceptados son JPG y PDF.';
lang_es['UIMessages.Records.AlertAllowed'] = 'Solicitud(es) permitida(s)';
lang_es['UIMessages.Records.AlertDeleted'] = 'Solicitud(es) borrada(s)';
lang_es['UIMessages.Records.AlertDeniedDeleted'] = 'Solicitud(es) denegada(s) y borrada(s)';
lang_es['UIMessages.Records.AlreadyApprovedForCriteria'] = 'Todos los registros con esos parámetros ya están aprobados.';
lang_es['UIMessages.Records.AmountMileageRequired'] = 'Se requiere una cantidad monetaria o en millas.';
lang_es['UIMessages.Records.AmountRange'] = 'La cantidad estaba fuera del rango válido.';
lang_es['UIMessages.Records.ApprovedMany'] = 'Registros aprobados';
lang_es['UIMessages.Records.Archived'] = 'Registro archivado';
lang_es['UIMessages.Records.ArchivedCannotBeEdited'] = 'El registro está archivado y no se puede editar.';
lang_es['UIMessages.Records.ArchivedMany'] = 'Registros archivados';
lang_es['UIMessages.Records.BillRateRange'] = 'La tasa de facturación debe estar entre {1} y {2}';
lang_es['UIMessages.Records.BillableStatusRequired'] = 'Se requiere estado facturable';
lang_es['UIMessages.Records.BreakStartBetweenStartEnd'] = 'La hora de inicio de la pausa debe estar entre la hora de inicio y finalización del registro.';
lang_es['UIMessages.Records.CannotBeEditedArchivedUser'] = 'Los registros de usuarios archivados no se pueden modificar.';
lang_es['UIMessages.Records.CopiedSubsequentWeek'] = 'Registros copiados a la semana siguiente. Tenga en cuenta que la vista es ahora la semana siguiente.';
lang_es['UIMessages.Records.CostRateRange'] = 'La tasa de costo debe estar entre {1} y {2}';
lang_es['UIMessages.Records.CouldNotBeReassigned'] = 'Los registros no se pudieron reasignar.';
lang_es['UIMessages.Records.CreatedBySuperAdminCannotEdit'] = 'Los registros creados por supervisores o administradores no se pueden editar.';
lang_es['UIMessages.Records.Deleted'] = 'Registro eliminado';
lang_es['UIMessages.Records.DeletedCount'] = 'Registro eliminado: {1}';
lang_es['UIMessages.Records.DeletedMany'] = 'Registros eliminados';
lang_es['UIMessages.Records.Edited'] = 'Registro editado';
lang_es['UIMessages.Records.EditedMany'] = 'Registros editados';
lang_es['UIMessages.Records.ErrorAddingTimeFollowingUsers'] = 'Se produjo un error al agregar tiempo para los siguientes usuarios:';
lang_es['UIMessages.Records.ErrorDeleting'] = 'Hubo un error al eliminar el registro.';
lang_es['UIMessages.Records.ErrorProcessingExpense'] = 'Hubo un error al intentar procesar los registros de gastos seleccionados.';
lang_es['UIMessages.Records.ExpenseEntryCutoffAllFuture'] = 'El ingreso de gastos está restringido para fechas futuras.';
lang_es['UIMessages.Records.ExpenseEntryCutoffDatesAfter'] = 'El ingreso de gastos ha sido restringido a fechas después del {1}.';
lang_es['UIMessages.Records.ExpenseEntryCutoffDatesBetween'] = 'El ingreso de gastos se ha restringido a fechas entre {1} y ahora.';
lang_es['UIMessages.Records.IndicatorsForModifiedRecordsCleared'] = 'Indicadores para registros recientemente modificados fueron limpiados.';
lang_es['UIMessages.Records.LimitedToXHoursPerDay'] = 'La cuenta está limitada a {1} horas cada 24 horas laborables.';
lang_es['UIMessages.Records.MarkedBillable'] = 'Registros marcados como facturables';
lang_es['UIMessages.Records.MarkedUnbillable'] = 'Registros marcados como no facturables';
lang_es['UIMessages.Records.MileageMultiplierRange'] = 'El rango del multiplicador de millaje debe ser entre 0,00 y 99,99.';
lang_es['UIMessages.Records.MileageRange'] = 'El valor del millaje debe estar entre 0 y 65000.';
lang_es['UIMessages.Records.NoLongerValidAcctCode'] = 'Este código de cuenta ya no es una selección válida para crear nuevos registros.';
lang_es['UIMessages.Records.NoLongerValidCustomer'] = 'Este cliente ya no es una selección válida para crear nuevos registros.';
lang_es['UIMessages.Records.NoLongerValidProject'] = 'Este proyecto ya no es una selección válida para crear nuevos registros.';
lang_es['UIMessages.Records.NonStandardCannotDelete'] = 'Solo se puede eliminar tiempo estándar y no remunerado / asalariado. Por favor revise las Clasificaciones de Tiempo seleccionadas e intente de nuevo.';
lang_es['UIMessages.Records.NoneForCriteria'] = 'No hay registros con esos parámetros';
lang_es['UIMessages.Records.NoneSelectedInReport'] = 'Ninguno de los registros seleccionados estaba en el reporte.';
lang_es['UIMessages.Records.NotFound'] = 'Registro no encontrado.';
lang_es['UIMessages.Records.NotUpdatedForX'] = 'No se pudieron actualizar uno o más registros de {1}.';
lang_es['UIMessages.Records.NotUpdatedForXAlerts'] = 'Los registros de {1} no se pudieron actualizar porque está fichado(a) o tiene alertas pendientes.';
lang_es['UIMessages.Records.NotificationSentApprovedTime'] = 'Se envió una notificación a {1} de que se han aprobado registros.';
lang_es['UIMessages.Records.NotificationSentArchivedTime'] = 'Se envió una notificación a {1} de que se han archivado registros.';
lang_es['UIMessages.Records.NotificationSentClosedPayroll'] = 'Se envió una notificación a {1} de que se ha cerrado un período de nómina.';
lang_es['UIMessages.Records.NotificationSentOpenedExpense'] = 'Se envió una notificación a {1} de que se han abierto registros de gastos.';
lang_es['UIMessages.Records.NotificationSentReconciledExpense'] = 'Se envió una notificación a {1} de que se han conciliado gastos.';
lang_es['UIMessages.Records.NotificationSentTimesheetReady'] = 'Se envió una notificación a {1} de que su hoja de horas está lista para su revisión.';
lang_es['UIMessages.Records.NumericIDRequired'] = 'Se requiere un ID de registro numérico';
lang_es['UIMessages.Records.OnlyOpenCanBeDeleted'] = 'Solo se pueden eliminar registros abiertos';
lang_es['UIMessages.Records.Opened'] = 'Registros abiertos';
lang_es['UIMessages.Records.OpenedMany'] = 'Registros abiertos';
lang_es['UIMessages.Records.PaidCannotDelete'] = 'Los registros pagados no se pueden eliminar.';
lang_es['UIMessages.Records.PaidCannotEdit'] = 'Los registros pagados no se pueden editar.';
lang_es['UIMessages.Records.PendingCannotModify'] = 'El registro no se puede modificar mientras esté pendiente de aprobación.';
lang_es['UIMessages.Records.ReassignedItem'] = 'Los registros se han reasignado.';
lang_es['UIMessages.Records.ReassignedRenameArchiveItem'] = 'Los registros se han reasignado. Ahora puede cambiar el nombre o archivar este elemento.';
lang_es['UIMessages.Records.Reconciled'] = 'Registro reconciliado';
lang_es['UIMessages.Records.ReconciledMany'] = 'Registros reconciliados';
lang_es['UIMessages.Records.RecordNotAvailable'] = 'El estado del registro que estaba intentando editar ha cambiado. Por lo tanto, el registro ya no está disponible.';
lang_es['UIMessages.Records.SplitEndTimeBetweenStartEnd'] = 'La hora de finalización fraccionada debe estar entre la hora de inicio y finalización original.';
lang_es['UIMessages.Records.SplitStartTimeBetweenStartEnd'] = 'La hora de inicio fraccionada debe estar entre la hora de inicio y finalización original.';
lang_es['UIMessages.Records.SplitStartTimeEarlier'] = 'La hora de inicio fraccionada debe ser anterior a la hora de finalización fraccionada.';
lang_es['UIMessages.Records.StandardHoursLimit'] = 'Los registros de tiempo estándar deben ser inferiores a 99,99 horas.';
lang_es['UIMessages.Records.SubmittedForSuperApproval'] = 'Registro(s) enviado(s) para aprobación del supervisor';
lang_es['UIMessages.Records.TimEntryCutoffDatesAfter.Hourly'] = 'El ingreso de registros está restringido a fechas después del {1} para Horas Estándar o tiempo Sin Paga/Asalariado.';
lang_es['UIMessages.Records.TimeClassificationRequired'] = 'Se requiere una clasificación de tiempo válida.';
lang_es['UIMessages.Records.TimeEntryCutoffAllFuture'] = 'La entrada de registros está restringida para fechas futuras.';
lang_es['UIMessages.Records.TimeEntryCutoffDatesAfter'] = 'La entrada de tiempo se ha restringido a fechas posteriores al {1}.';
lang_es['UIMessages.Records.TimeEntryCutoffDatesAfter.Hourly'] = 'El ingreso de tiempo se ha restringido a fechas posteriores a {1}.';
lang_es['UIMessages.Records.TimeEntryCutoffDatesBetween'] = 'La entrada de tiempo se ha restringido a fechas entre {1} y ahora.';
lang_es['UIMessages.Records.TimeEntryCutoffDatesBetween.Hourly'] = 'La entrada de registros está restringida a fechas entre ahora y {1}.';
lang_es['UIMessages.Records.TimeOffLimitedTo24Hours'] = 'Registros de tiempo libre tienen un límite de máximo 24 horas por día.';
lang_es['UIMessages.Records.TimeRoundedToMinInc'] = 'El tiempo se ha redondeado a los {1} minutos más cercanos.';
lang_es['UIMessages.Records.UnapprovedMany'] = 'Registros no aprobados';
lang_es['UIMessages.Records.Unarchived'] = 'Registro desarchivado';
lang_es['UIMessages.Records.UnarchivedMany'] = 'Registros desarchivados';
lang_es['UIMessages.Records.UpdatedError'] = 'Uno o más de los registros no se pudieron actualizar.';
lang_es['UIMessages.Records.XRecordsSigned'] = 'Se ha(n) firmado {1} registro(s)';
lang_es['UIMessages.Records.XofYAlteredWhenCopied'] = 'Se modificaron {1} de {2} registros cuando se copiaron debido a permisos no válidos.';
lang_es['UIMessages.Records.XofYNotCopiedInvalidPermissions'] = 'Hubo {1} de {2} registros que no se copiaron debido a permisos no válidos.';
lang_es['UIMessages.Reports.AlertsExist'] = 'No se pudo generar el reporte debido a alertas existentes para los siguientes usuarios:';
lang_es['UIMessages.Reports.AlreadyApproved'] = 'Todos los registros del reporte ya están aprobados.';
lang_es['UIMessages.Reports.ApprovedStatusRequired'] = 'Se requiere el estado de registro (aprobado/no aprobado).';
lang_es['UIMessages.Reports.BillableStatusRequired'] = 'Se requiere la selección de estado facturable.';
lang_es['UIMessages.Reports.CannotRunAlertsExist'] = 'El reporte no se puede ejecutar porque incluye registros con alertas pendientes.';
lang_es['UIMessages.Reports.GroupByLimitations'] = 'Esa descarga solo permite agrupar por opciones para Cliente o Proyecto o Código de Cuenta.';
lang_es['UIMessages.Reports.GroupByRequired'] = 'Agrupar por selección requerido.';
lang_es['UIMessages.Reports.InvalidBaseLocationCoords'] = 'Coordenadas de ubicación base no válidas.';
lang_es['UIMessages.Reports.InvalidShiftRestrictionRange'] = 'La restricción de turno no es un rango válido.';
lang_es['UIMessages.Reports.MaxHoursThreshold'] = 'El umbral máximo de horas debe ser un número válido entre 0,01 y 9999,99.';
lang_es['UIMessages.Reports.MaximumDistanceValue'] = 'Introduzca un valor válido para la distancia máxima.';
lang_es['UIMessages.Reports.NoRecordsInPeriod'] = 'No hay registros en el período del reporte.';
lang_es['UIMessages.Reports.OneOrMoreAcctCodesRequired'] = 'Se deben incluir uno o más códigos de cuenta en el reporte.';
lang_es['UIMessages.Reports.OneOrMoreCustomersRequired'] = 'Se deben incluir uno o más clientes en el reporte.';
lang_es['UIMessages.Reports.OneOrMoreEventsRequired'] = 'Se deben incluir uno o más eventos en el reporte.';
lang_es['UIMessages.Reports.OneOrMoreProjectsRequired'] = 'Se deben incluir uno o más proyectos en el reporte.';
lang_es['UIMessages.Reports.OneOrMoreUsersRequired'] = 'Se deben incluir uno o más usuarios en el reporte.';
lang_es['UIMessages.Reports.OneOrMoreUsersRequiredNotSelf'] = 'Se deben incluir uno o más usuarios en el reporte. Usted no tiene permiso para incluirse a si mismo.';
lang_es['UIMessages.Reports.OneOrMoreVendorsRequired'] = 'Se deben incluir uno o más proveedores en el reporte.';
lang_es['UIMessages.Reports.ParametersUnavailable'] = 'Un reporte para esos parámetros no está disponible actualmente. Comuníquese con soporte.';
lang_es['UIMessages.Reports.ReportTimedOut'] = 'Se agotó el tiempo de espera del reporte. Genere el reporte nuevamente.';
lang_es['UIMessages.Reports.SignedStatusRequired'] = 'Se requiere la selección del estado de registro firmado.';
lang_es['UIMessages.Reports.StatusRequired'] = 'Se requiere la selección del estado del registro.';
lang_es['UIMessages.Reports.TimeClassRequired'] = 'Se requiere la selección de clasificación de tiempo.';
lang_es['UIMessages.Reports.ValidGracePeriod'] = 'Ingrese un período de gracia válido.';
lang_es['UIMessages.Reports.ValidTypeRequired'] = 'Se requiere un tipo de reporte válido.';
lang_es['UIMessages.Settings.CannotDisableLoginForOwnAccount'] = 'No es posible deshabilitar su propia cuenta.';
lang_es['UIMessages.Settings.CompanyWeekStartInvalid'] = 'El valor de inicio de la semana de la empresa no era válido.';
lang_es['UIMessages.Settings.CustomBrandNotEnabled'] = 'Un logotipo de marca personalizado no está habilitado para esta cuenta.';
lang_es['UIMessages.Settings.ExpenseDisabledError'] = 'El Registro de Gastos no se puede deshabilitar mientras haya gastos abiertos.';
lang_es['UIMessages.Settings.HourlyDisabledError'] = 'El registro de Tiempo por Hora no se puede deshabilitar mientras haya usuarios fichados o alertas abiertas u horas no pagadas.';
lang_es['UIMessages.Settings.LoginStatusForPrimaryUserCannotBeChanged'] = 'El estado de inicio de sesión para el usuario principal de la cuenta no se puede cambiar.';
lang_es['UIMessages.Settings.MaxHoursPerDayMoreThanZero'] = 'El máximo de horas permitidas por día debe ser superior a cero.';
lang_es['UIMessages.Settings.MileageMultiplierOrBlank'] = 'El multiplicador de millas debe estar entre 0.001 y 99.99 o dejarse en blanco.';
lang_es['UIMessages.Settings.MinTimeIncRequired'] = 'Se requiere un incremento de tiempo mínimo válido';
lang_es['UIMessages.Settings.MinWeeklyOTRange'] = 'El mínimo de horas extras semanales debe ser superior a 1.';
lang_es['UIMessages.Settings.MonthlyPayrollFirstDaySetting'] = 'Para períodos de nómina mensual, el primer día debe ser entre 1 y 28.';
lang_es['UIMessages.Settings.OTCutoffInvalid'] = 'Los valores de corte de horas extras no son válidos.';
lang_es['UIMessages.Settings.PayrollPeriodStartDateRequired'] = 'Se requiere una fecha de inicio del período de nómina para esa configuración.';
lang_es['UIMessages.Settings.PhotoSnapshot'] = 'Si no ve video, es porque no tiene una cámara web instalada o necesita permitir que el navegador use su cámara web.';
lang_es['UIMessages.Settings.PhotoSnapshotInfo'] = 'Si su cámara web no funciona correctamente, el botón Tomar Foto grabará una imagen en blanco. Debe informar a su supervisor si tiene alguna dificultad.';
lang_es['UIMessages.Settings.ProjectDisabledError'] = 'El registro de Tiempo de Proyecto no se puede deshabilitar mientras haya temporizadores o alertas de proyecto u horas abiertas.';
lang_es['UIMessages.Settings.TwiceMonthlyPayrollFirstDaySetting'] = 'Para períodos de nómina dos veces al mes, el primer día debe estar entre 1 y 13.';
lang_es['UIMessages.Settings.ValidDailyOT'] = 'Introduzca valores válidos para las horas extras diarias. 1.5x horas diarias deben ser menos que 2.0x horas diarias, que deben ser menos de 1.5x horas semanales.';
lang_es['UIMessages.Settings.WeeklyOTValue'] = 'El valor de las horas extras semanales debe ser un número entero entre 2 y 999.';
lang_es['UIMessages.StatusEmail.CannotAddSelf'] = 'Si desea recibir este reporte de estado, visite \'Ajustes de mi Cuenta\' y actualice la configuración de su cuenta personal.';
lang_es['UIMessages.StatusEmail.DeliveryFrequencyInvalid'] = 'La frecuencia de entrega no es válida.';
lang_es['UIMessages.StatusEmail.DistroListForForOutsideUseOnly'] = 'Utilice esta lista de distribución para agregar usuarios fuera de su organización que no tengan una cuenta de Timesheets.com.';
lang_es['UIMessages.StatusEmail.EmailAdded'] = 'Se agregó la dirección de correo electrónico';
lang_es['UIMessages.StatusEmail.EmailAddressAlreadyExists'] = 'La dirección de correo electrónico ya pertenece a una cuenta de usuario o ya está recibiendo el correo electrónico de estado.';
lang_es['UIMessages.StatusEmail.EmailDeleted'] = 'Dirección de correo electrónico eliminada';
lang_es['UIMessages.StatusEmail.Sent'] = 'Correo electrónico de estado enviado';
lang_es['UIMessages.StatusEmail.ValidEmailRequired'] = 'Se requiere una dirección de correo electrónico válida';
lang_es['UIMessages.Sys.AccountExpired'] = 'Su cuenta ha expirado.';
lang_es['UIMessages.Sys.AccountExpiresOneDay'] = 'Su cuenta expira en un día.';
lang_es['UIMessages.Sys.AccountExpiresToday'] = 'Su cuenta expira hoy.';
lang_es['UIMessages.Sys.AccountExpiresXDays'] = 'Su cuenta expira en {1} días.';
lang_es['UIMessages.Sys.AccountUnableToChargeCo'] = 'Recientemente, no pudimos cobrarle a su organización por nuestro servicio. Comuníquese con nosotros para obtener ayuda.';
lang_es['UIMessages.Sys.AccountUnableToChargeYou'] = 'Recientemente, no pudimos hacer el cobro a su tarjeta de crédito.';
lang_es['UIMessages.Sys.AccountUpdatePayment'] = 'Por favor actualice su información de pago.';
lang_es['UIMessages.Sys.AccountWasCancelled'] = 'Su cuenta ha sido cancelada. Gracias por su confianza en nuestros servicios.';
lang_es['UIMessages.Sys.ErrorProcessingPaymentCallCustomerService'] = 'Vuelva a intentarlo o llame a soporte para obtener ayuda completando su transacción.';
lang_es['UIMessages.Sys.ErrorProcessingPaymentInfo'] = 'Hubo un error al procesar su información de pago.';
lang_es['UIMessages.Sys.Item.ItemIsArchivedCannotBeEdited'] = 'Este elemento está archivado y no se puede modificar.';
lang_es['UIMessages.Sys.Item.ProjectDescriptionsAreViewable'] = 'Las descripciones de proyectos son visibles para todos los usuarios al ingresar o editar tiempo. Esto se puede modificar en \'Ajustes de Compañía\'.';
lang_es['UIMessages.Sys.NoPermissionToViewUserAccount'] = 'No cuenta con permisos para ver esta cuenta de usuario.';
lang_es['UIMessages.Sys.SandboxDisabled'] = 'Cuentas de prueba inhabilitadas en este servidor.';
lang_es['UIMessages.Sys.SessionExpired'] = 'Su sesión ha caducado. Inicie sesión e intente nuevamente.';
lang_es['UIMessages.Sys.SessionInvalidated'] = 'Su sesión ha sido invalidada. Inicie sesión e intente nuevamente.';
lang_es['UIMessages.Sys.SessionLoggedOut'] = 'Ha cerrado la sesión.';
lang_es['UIMessages.Sys.SessionLoggedOutOnError'] = 'Se detectó un error potencial y se cerró la sesión. Vuelva a iniciar sesión.';
lang_es['UIMessages.Sys.SessionUnknownLogout'] = 'Ocurrió un error inesperado. Usted ha sido desconectado.';
lang_es['UIMessages.Sys.TrialExpiresOneDay'] = 'Su cuenta de prueba expira en un día.';
lang_es['UIMessages.Sys.TrialExpiresToday'] = 'Su cuenta de prueba expira hoy.';
lang_es['UIMessages.Sys.TrialExpiresXDays'] = 'Su cuenta de prueba expira en {1} días.';
lang_es['UIMessages.Sys.UnsubscribeMeFromNotification'] = 'Quitar mi Suscripción de esta Notificación';
lang_es['UIMessages.Sys.UnsubscribedFromNotification'] = 'Se ha dado de baja de la notificación con efecto inmediato.';
lang_es['UIMessages.Sys.UserAccountInactiveCannotBeViewd'] = 'Esta cuenta de usuario está inactiva y no se puede ver.';
lang_es['UIMessages.Sys.UserProfileMissingEmail'] = 'Al perfil de este usuario le falta una dirección de correo electrónico. Se requiere una dirección de correo electrónico para iniciar sesión. Añada una dirección de correo electrónico {1}aquí{2}.';
lang_es['UIMessages.Sys.UserProfileMissingEmailNoPerm'] = 'Al perfil de este usuario le falta una dirección de correo electrónico. Pídales que agreguen uno o avise al administrador de su cuenta para que lo haga en nombre del usuario.';
lang_es['UIMessages.Sys.ViewNotEnabledForUser'] = 'Esta vista no está habilitada para este usuario.';
lang_es['UIMessages.Sys.Warning.SchedulePermissionsCorrupt'] = 'ADVERTENCIA: Los permisos de calendario de este usuario se han corrompido. Restablezcalos o comuníquese con nosotros para obtener ayuda.';
lang_es['UIMessages.Sys.Warning.UsersMissingEmailAddress'] = 'A los usuarios con {1} les falta una dirección de correo electrónico. Se requiere una dirección de correo electrónico para iniciar sesión.';
lang_es['UIMessages.Sys.YourProfileMissingEmail'] = 'A su perfil le falta una dirección de correo electrónico. Se requiere una dirección de correo electrónico para iniciar sesión. Agregue su dirección de correo electrónico {1} aquí {2}.';
lang_es['UIMessages.Timer.AllOtherRunningPaused'] = 'Todos los demás temporizadores en ejecución se detuvieron.';
lang_es['UIMessages.Timer.AllRestarted'] = 'Se reiniciaron todos los temporizadores en pausa.';
lang_es['UIMessages.Timer.ComboExists'] = 'Ya existe un temporizador para esos parámetros.';
lang_es['UIMessages.Timer.InvalidLocationCannotStart'] = 'No se puede realizar una acción de temporizador desde una ubicación no válida.';
lang_es['UIMessages.Timer.InvalidParameters'] = 'Parámetros de temporizador no válidos.';
lang_es['UIMessages.Timer.NoRunning'] = 'No hay temporizadores en ejecución.';
lang_es['UIMessages.Timer.OneRunningTimerLimit'] = 'Solo puede haber un temporizador activo a la vez.';
lang_es['UIMessages.Timer.Paused'] = 'Temporizador en pausa.';
lang_es['UIMessages.Timer.PausedCount'] = 'Temporizadores en pausa: {1}';
lang_es['UIMessages.Timer.PresetCreated'] = 'Plantilla creada.';
lang_es['UIMessages.Timer.Restarted'] = 'Se reinició el temporizador.';
lang_es['UIMessages.Timer.Started'] = 'Temporizador iniciado.';
lang_es['UIMessages.Timer.StoppingError'] = 'Error al detener el temporizador {1} {2} {3}.';
lang_es['UIMessages.Timer.TimerAlreadyRunning'] = 'El temporizador ya está corriendo.';
lang_es['UIMessages.User.AnnualReviewDateInvalid'] = 'El mes y el día de la Fecha de Revisión Anual de Desempeño no son válidos.';
lang_es['UIMessages.User.Archived'] = 'Usuario archivado.';
lang_es['UIMessages.User.ArchivedMany'] = 'Usuarios archivados.';
lang_es['UIMessages.User.CannotArchiveOnTheClock'] = 'El usuario está actualmente fichado. El usuario debe marcar salida antes que se pueda archivar su cuenta.';
lang_es['UIMessages.User.CannotArchiveOpenExpenses'] = 'Este usuario tiene gastos pendientes. Los registros deben conciliarse o eliminarse antes de que se pueda archivar la cuenta de usuario.';
lang_es['UIMessages.User.CannotArchiveOpenTimers'] = 'El usuario tiene actualmente uno o más temporizadores de proyecto abiertos. Los temporizadores deben detenerse antes de que se pueda archivar la cuenta de usuario.';
lang_es['UIMessages.User.CannotArchiveOwn'] = 'La cuenta propia no se puede archivar.';
lang_es['UIMessages.User.CannotArchivePrimary'] = 'El administrador de la cuenta principal y no se puede archivar.';
lang_es['UIMessages.User.CannotArchiveUnpaidTime'] = 'Este usuario tiene horas sin pagar en su hoja de horas. Los registros deben cerrarse en un reporte final de nómina o eliminarse antes de que se pueda archivar la cuenta de usuario.';
lang_es['UIMessages.User.CannotBeArchivedCriteriaNotMet'] = 'El usuario no se puede archivar debido a que no todos los requerimientos se han cumplido.';
lang_es['UIMessages.User.ChangingLoginStatusExceedsLicenses'] = 'El estado de inicio de sesión no se puede cambiar debido a que excederá la cantidad de licencias de usuario disponibles.';
lang_es['UIMessages.User.CredentialTypeRequired'] = 'Se requiere el tipo de credencial a generar.';
lang_es['UIMessages.User.CredentialsGenerated'] = 'Credenciales generadas con éxito.';
lang_es['UIMessages.User.DemoteSuperEmpFirst'] = 'Para bajar un usuario de administrador a supervisor, primero debe configurarse como empleado y luego como supervisor.';
lang_es['UIMessages.User.ESignNotEnabled'] = 'La firma de usuario no está habilitada para su cuenta.';
lang_es['UIMessages.User.EmailAddressRequiredAccessLevel'] = 'Se requiere una dirección de correo electrónico para este nivel de acceso.';
lang_es['UIMessages.User.ErrorActivating'] = 'Hubo un error al activar al usuario. Comuníquese con soporte.';
lang_es['UIMessages.User.ExpenseDisabledError'] = 'El registro de gastos no se puede deshabilitar para este usuario porque tiene registros abiertos.';
lang_es['UIMessages.User.FileImported'] = 'Usuarios importados.';
lang_es['UIMessages.User.FileValidated'] = 'Archivo validado y se puede importar.';
lang_es['UIMessages.User.HourlyDisabledError'] = 'El registro de Tiempo por Hora no se puede deshabilitar para este usuario porque está fichado o tiene tiempo no pagado.';
lang_es['UIMessages.User.IDNumberDeptRequired'] = 'Se requiere un número de empleado de cuatro dígitos y un departamento válido.';
lang_es['UIMessages.User.ImageError.ImageTooSmall'] = 'La imagen era demasiado pequeña. Recorte una imagen más grande.';
lang_es['UIMessages.User.ImageErrorJPEGGIFOnly'] = 'Hubo un error al cargar la imagen. Solo se aceptan los tipos de archivo JPEG y GIF.';
lang_es['UIMessages.User.Invalid'] = 'Usuario inválido';
lang_es['UIMessages.User.LoginDisabled'] = 'Inicio de sesión de usuario deshabilitado';
lang_es['UIMessages.User.LoginEnabled'] = 'Inicio de sesión de usuario habilitado';
lang_es['UIMessages.User.NewUserCreated'] = '¡Nueva cuenta de usuario creada! Puede hacer cambios en los ajustes de la cuenta en esta página, o agregar otro usuario.';
lang_es['UIMessages.User.NewUserCreated.LoginInfoNotSent'] = 'Puede enviarle al usuario un correo de bienvenida con sus datos para ingresar en el momento que lo necesite.';
lang_es['UIMessages.User.NewUserCreated.LoginInfoSent'] = 'Las credenciales de ingreso se le enviaron al usuario por correo electrónico.';
lang_es['UIMessages.User.NoPermissionToRecordToCustomerProject'] = 'Este usuario no tiene permisos para registrar tiempo para el cliente y proyecto seleccionados.';
lang_es['UIMessages.User.NoPermissionToRecordToProject'] = 'Este usuario no tiene permisos para registrar tiempo para el proyecto seleccionado.';
lang_es['UIMessages.User.PayTypeRequired'] = 'Tipo de pago requerido';
lang_es['UIMessages.User.PrimaryAdminGrantedAllPermissions'] = 'Por ser el administrador principal, esta cuenta ya tiene todos los permisos habilitados.';
lang_es['UIMessages.User.ProjectDisabledError'] = 'El registro de tiempo de proyecto no se puede deshabilitar para este usuario porque tiene un temporizador abierto o registros abiertos.';
lang_es['UIMessages.User.RateRangeHourly'] = 'La tarifa por hora debe estar entre 0,00 y 99999,99 o debe dejarse en blanco.';
lang_es['UIMessages.User.RateRangeSalary'] = 'La tarifa salarial debe estar entre 0.00 y 999999.99 o dejarse en blanco';
lang_es['UIMessages.User.Reactivated'] = 'Usuario reactivado';
lang_es['UIMessages.User.SuperCannotReportToSubordinate'] = 'Un supervisor no puede reportar a uno de sus subordinados.';
lang_es['UIMessages.User.SuperLessPermissionsError'] = 'Este supervisor no puede informar al supervisor seleccionado porque el supervisor seleccionado tiene permisos de acceso más restrictivos que este supervisor. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['UIMessages.User.SuperPermissionCorrupt'] = 'Los permisos de supervisor están corruptos y deben repararse. Por favor comuníquese con soporte.';
lang_es['UIMessages.User.SuperPermissionError'] = 'No se pudo asignar el supervisor debido a un error de permisos. Por favor comuníquese con soporte.';
lang_es['UIMessages.User.SuperUpdateExceptions'] = 'La configuración del supervisor se ha actualizado con excepciones.';
lang_es['UIMessages.User.SupervisorRequired'] = 'Debe seleccionar un supervisor al cual este usuario va a reportar.';
lang_es['UIMessages.User.TimeZoneNotUpdatedForX'] = 'No se pudo actualizar la zona horaria de {1}.';
lang_es['UIMessages.User.UserAlreadyArchived'] = 'El usuario ya está archivado.';
lang_es['UIMessages.User.UserIsCurrentlyActive'] = 'El usuario ya está activo actualmente.';
lang_es['UIMessages.User.UserTypeChangeAccrualChange'] = 'La tasa de acumulación del usuario se modificó como requisito para actualizar el tipo de pago. Revise la tasa de acumulación para ver si es correcta.';
lang_es['UIMessages.User.UserUpdatedWithErrors'] = 'La configuración del usuario se ha actualizado con los siguientes errores:';
lang_es['UIMessages.Validation.AccrualImport.FileImported'] = 'Archivo importado.';
lang_es['UIMessages.Validation.AccrualImport.RowMissingColumns'] = 'Faltaban columnas en una fila con ID de usuario {1,number,#}.';
lang_es['UIMessages.Validation.AccrualImport.TimeOffMismatch'] = 'El encabezado de la columna "{1}" no coincide con una categoría de tiempo libre existente.';
lang_es['UIMessages.Validation.AccrualImport.UserHourlyNotEnabled'] = 'El ID de usuario {1,number,#} en la línea {2,number,#} no tiene habilitado el tiempo por hora.';
lang_es['UIMessages.Validation.AccrualImport.UserInactive'] = 'ID de usuario {1,number,#} en la línea {2,number,#} no está activo en este momento.';
lang_es['UIMessages.Validation.AccrualImport.UserNotValid'] = 'El ID de usuario {1,number,#} en la línea {2,number,#} no era un usuario válido.';
lang_es['UIMessages.Validation.ActionWillExceedUserLicenses'] = 'Esta acción excederá el número de licencias de usuario disponibles.';
lang_es['UIMessages.Validation.ActionWillExceedUserLicensesAdminInstruction'] = 'Para agregar nuevos usuarios, desactive los usuarios existentes o comuníquese con el administrador principal para agregar más licencias a la cuenta.';
lang_es['UIMessages.Validation.ActionWillExceedUserLicensesPrimaryAdminInstruction'] = 'Para agregar nuevos usuarios, desactive los usuarios existentes o agregue más licencias a la cuenta.';
lang_es['UIMessages.Validation.ActionWillExceedUserLicensesSupervisorInstruction'] = 'Comuníquese con el administrador de la cuenta para obtener información sobre cómo liberar o agregar licencias adicionales.';
lang_es['UIMessages.Validation.AgreeToTermsOfUse'] = 'Debe aceptar el acuerdo de \'Términos de Uso\'.';
lang_es['UIMessages.Validation.AgreeToTermsOfUseWithLink'] = 'Debe aceptar el acuerdo de <a href="{1}" target="_blank" rel="noopener">\'Términos de Uso\'</a>.';
lang_es['UIMessages.Validation.ApplicationTypeRequired'] = 'No se especificó el tipo de aplicación adecuado.';
lang_es['UIMessages.Validation.Billing.Address'] = 'Por favor ingrese su dirección.';
lang_es['UIMessages.Validation.Billing.Address2'] = 'Por favor ingrese su dirección extendida.';
lang_es['UIMessages.Validation.Billing.City'] = 'Por favor ingrese su ciudad.';
lang_es['UIMessages.Validation.Billing.Country'] = 'Por favor seleccione su país.';
lang_es['UIMessages.Validation.Billing.CreditCardExpirationMonth'] = 'Ingrese el mes de vencimiento de su tarjeta.';
lang_es['UIMessages.Validation.Billing.CreditCardExpirationYear'] = 'Ingrese el año de vencimiento de su tarjeta.';
lang_es['UIMessages.Validation.Billing.CreditCardNumber'] = 'Por favor introduzca un número de tarjeta de crédito válida.';
lang_es['UIMessages.Validation.Billing.CreditCardType'] = 'Seleccione un tipo de tarjeta de crédito.';
lang_es['UIMessages.Validation.Billing.FirstName'] = 'Por favor ingrese su primer nombre.';
lang_es['UIMessages.Validation.Billing.LastName'] = 'Por favor ingrese su apellido.';
lang_es['UIMessages.Validation.Billing.ShippingAddress'] = 'Ingrese una dirección de envío.';
lang_es['UIMessages.Validation.Billing.ShippingAddress2'] = 'Ingrese una dirección de envío ampliada.';
lang_es['UIMessages.Validation.Billing.ShippingCity'] = 'Ingrese una ciudad de envío.';
lang_es['UIMessages.Validation.Billing.ShippingCountry'] = 'Seleccione su país de envío.';
lang_es['UIMessages.Validation.Billing.ShippingFirstName'] = 'Por favor ingrese su nombre de envío.';
lang_es['UIMessages.Validation.Billing.ShippingLastName'] = 'Ingrese su apellido de envío.';
lang_es['UIMessages.Validation.Billing.ShippingState'] = 'Seleccione su estado de envío.';
lang_es['UIMessages.Validation.Billing.ShippingZip'] = 'Ingrese su código postal de envío.';
lang_es['UIMessages.Validation.Billing.State'] = 'Seleccione su estado.';
lang_es['UIMessages.Validation.Billing.SubscriptionLevel'] = 'Seleccione el nivel de suscripción.';
lang_es['UIMessages.Validation.Billing.Zip'] = 'Por favor ingrese su código postal.';
lang_es['UIMessages.Validation.CompanyIDInvalid'] = 'El ID de Compañía no es válido';
lang_es['UIMessages.Validation.CompanyNameRequired'] = 'Se requiere el nombre de la compañía.';
lang_es['UIMessages.Validation.CompanyNotFound'] = 'Compañía no encontrada.';
lang_es['UIMessages.Validation.CompanyNotValidForSetting'] = 'La compañía no es válida para esta configuración.';
lang_es['UIMessages.Validation.CompleteHighlightedTryAgain'] = 'Por favor complete los campos resaltados en el formulario e intente nuevamente.';
lang_es['UIMessages.Validation.CountryRequired'] = 'El país es obligatorio.';
lang_es['UIMessages.Validation.DSTTimeDoesNotExist'] = 'Debido al horario de verano, no existe el tiempo entre las 2 a.M. y las 2:59 a.M.';
lang_es['UIMessages.Validation.DateFutureDisabled'] = 'El ingreso de fechas futuras está deshabilitado. Inténtelo de nuevo.';
lang_es['UIMessages.Validation.DateOutOfRange'] = 'La fecha está fuera de rango aceptable. Inténtelo de nuevo.';
lang_es['UIMessages.Validation.DateRequired'] = 'Se requiere una fecha válida.';
lang_es['UIMessages.Validation.DateTime'] = 'Se requiere una fecha y hora válidas.';
lang_es['UIMessages.Validation.EmailAddressNotFound'] = 'No se encontró la dirección de correo electrónico.';
lang_es['UIMessages.Validation.EmailAddressRequired'] = 'Se requiere una dirección de correo electrónico válida.';
lang_es['UIMessages.Validation.EmailAddressRequiredToResetCredentials'] = 'Un correo electrónico para el usuario es requerido para restablecer las credenciales de inicio de sesión.';
lang_es['UIMessages.Validation.EmailInUse'] = 'Esa dirección de correo electrónico ya se está utilizando. Pruebe con otra o póngase en contacto con soporte.';
lang_es['UIMessages.Validation.EndDateAfterStartDate'] = 'La fecha de finalización debe ser posterior a la fecha de inicio.';
lang_es['UIMessages.Validation.EndDateInvalid'] = 'La fecha de finalización inválido.';
lang_es['UIMessages.Validation.EndDateRequired'] = 'Se requiere la fecha de finalización.';
lang_es['UIMessages.Validation.EndTimeAfterStart'] = 'La hora de finalización es la misma o anterior a la hora de inicio. Por favor revise su entrada.';
lang_es['UIMessages.Validation.Enrollment.AccountCreated'] = '¡Nueva cuenta creada! Puede iniciar sesión ahora.';
lang_es['UIMessages.Validation.Enrollment.CoWebsiteRequired'] = 'Se requiere el sitio web de la compañía';
lang_es['UIMessages.Validation.Enrollment.EmployeeLevelRequired'] = 'Por favor seleccione el número de empleados de la compañía';
lang_es['UIMessages.Validation.Enrollment.ErrorAccountCreated'] = 'Hubo un error al crear su nueva cuenta. Por favor comuníquese con soporte.';
lang_es['UIMessages.Validation.Enrollment.FirstNameRequired'] = 'Primer nombre requerido';
lang_es['UIMessages.Validation.Enrollment.IndustryType'] = 'Tipo de industria requerido';
lang_es['UIMessages.Validation.Enrollment.LastNameRequired'] = 'El apellido es obligatorio';
lang_es['UIMessages.Validation.Enrollment.PleaseCorrectTheFollowing'] = 'Por favor corrija la siguiente:';
lang_es['UIMessages.Validation.Enrollment.ReferralRequired'] = 'Por favor seleccione un método de referencia.';
lang_es['UIMessages.Validation.Enrollment.TrialExtended'] = 'Cuenta extendida. Vuelve a iniciar sesión.';
lang_es['UIMessages.Validation.EntryOverlapsExisting'] = 'Este registro se superpondrá a un registro existente para la misma fecha y clasificación.';
lang_es['UIMessages.Validation.FileRequired'] = 'Se requiere un archivo válido.';
lang_es['UIMessages.Validation.FirstLastNameRequired'] = 'El nombre y apellido son campos obligatorios';
lang_es['UIMessages.Validation.HoursForTimeClassMustBeBetween'] = 'Este registro debe tener entre un minuto y veinticuatro horas.';
lang_es['UIMessages.Validation.HoursLessThanX'] = 'Las horas deben ser inferiores a {1,number,#}';
lang_es['UIMessages.Validation.HoursMinutesWholeNumbers'] = 'Las horas y los minutos deben ser números enteros';
lang_es['UIMessages.Validation.HoursNotLessThanZero'] = 'Las horas no pueden ser inferiores a cero';
lang_es['UIMessages.Validation.InvalidResetLink'] = 'Enlace de restablecimiento no válido';
lang_es['UIMessages.Validation.InvalidUserID'] = 'ID de usuario inválido';
lang_es['UIMessages.Validation.LoginFailed'] = 'Error de inicio de sesion.';
lang_es['UIMessages.Validation.LoginFailedCancelled'] = 'Error de inicio de sesion. Su cuenta ha expirado. Comuníquese con el administrador de su cuenta.';
lang_es['UIMessages.Validation.LoginFailedDelinquent'] = 'Error de inicio de sesión. Su cuenta es delictiva. Por favor comuníquese con soporte.';
lang_es['UIMessages.Validation.LoginFailedDisabled'] = '¡Acceso denegado! Inicio de sesión deshabilitado.';
lang_es['UIMessages.Validation.LoginFailedExpired'] = 'Error de inicio de sesion. Su cuenta ha expirado. Comuníquese con el administrador de su cuenta.';
lang_es['UIMessages.Validation.LoginFailedInvalidAccess'] = 'Error de inicio de sesion. Estado de acceso de usuario no válido.';
lang_es['UIMessages.Validation.LoginFailedKey'] = 'Error de incio de session. Cuenta no autorizada para acceder.';
lang_es['UIMessages.Validation.LoginFailedTooManyAttempts'] = 'Error de inicio de sesion. Ha habido demasiados intentos de iniciar sesión. La cuenta está deshabilitada temporalmente. Comuníquese con su supervisor o con soporte para obtener ayuda.';
lang_es['UIMessages.Validation.LoginFailedTooManyAttemptsInternal'] = '¡Acceso denegado! Demasiados intentos fallidos.';
lang_es['UIMessages.Validation.LoginFailedUserNamePasswordMismatch'] = 'Error de inicio de sesion. Credenciales no válidas.';
lang_es['UIMessages.Validation.MaintenanceLoginError'] = 'En este momento, el sistema no está disponible por mantenimiento. Vuelva a intentar pronto.';
lang_es['UIMessages.Validation.MaxHoursPerRecordThresholdInvalid'] = 'El umbral máximo de horas por registro no era válido.';
lang_es['UIMessages.Validation.MinutesNotLessThanZero'] = 'Los minutos no pueden ser menos de cero';
lang_es['UIMessages.Validation.NoDataInFile'] = 'No se encontraron datos válidos en el archivo.';
lang_es['UIMessages.Validation.NonStandardTimeMidnight'] = 'Tiempo no estándar debe comenzar a la medianoche.';
lang_es['UIMessages.Validation.NotARobot'] = 'Confirma que no eres un robot.';
lang_es['UIMessages.Validation.NoteCannotBeEmpty'] = 'La nota no puede estar vacía.';
lang_es['UIMessages.Validation.NoteStatusInvalid'] = 'El Estado de la Nota no es válido';
lang_es['UIMessages.Validation.NoteTypeInvalid'] = 'Tipo de Nota no válido';
lang_es['UIMessages.Validation.NoteUserInvalid'] = 'El usario/usario de administrador no es válido';
lang_es['UIMessages.Validation.NumberRequired'] = 'Por favor introduzca un número.';
lang_es['UIMessages.Validation.OneOrMoreParametersWereInvalid'] = 'Uno o mas parámetros son inválidos.';
lang_es['UIMessages.Validation.PaidBreakDurationInvalid'] = 'El duración de descanso pagado no es válido.';
lang_es['UIMessages.Validation.PasswordFormatRequirement'] = 'Las contraseñas deben tener entre 8 y 64 caracteres. La contraseña debe contener al menos un caracter en mayúscula; al menos un caracter en minúscula; al menos un número; al menos un caracter especial (\'!#$%^&*()_+}{"":;?/>.<\'); no puede repetir un caracter más de cinco veces; y no puede contener el nombre de usuario de la cuenta.';
lang_es['UIMessages.Validation.PasswordIncorrect'] = 'La contraseña es incorrecta. Inténtelo de nuevo.';
lang_es['UIMessages.Validation.PasswordMismatchError'] = 'Asegúrese de que las dos entradas de contraseña coincidan.';
lang_es['UIMessages.Validation.PasswordOldIncorrect'] = 'La contraseña anterior es incorrecta. Inténtelo de nuevo.';
lang_es['UIMessages.Validation.PasswordResetEmailSent'] = 'Se restablecieron las contraseñas de usuario y se reenviaron los correos electrónicos de bienvenida.';
lang_es['UIMessages.Validation.PasswordResetEmailedTo'] = 'Se restableció la contraseña y se envió por correo electrónico a: {1}';
lang_es['UIMessages.Validation.PasswordResetErrorList'] = 'No se pudieron restablecer las contraseñas de los siguientes usuarios:';
lang_es['UIMessages.Validation.PasswordUpdateRequired'] = 'Se requiere una actualización de contraseña.';
lang_es['UIMessages.Validation.PasswordUpdated'] = 'Contraseña actualizada';
lang_es['UIMessages.Validation.PaymentEmailAddressInvalid'] = 'La dirección de correo electrónico para el pago debe ser válida o dejarse en blanco.';
lang_es['UIMessages.Validation.PhoneNumberRequired'] = 'Un número de teléfono válido es requerido.';
lang_es['UIMessages.Validation.PhoneNumberValidOrBlank'] = 'El número de teléfono debe ser válido o estar en blanco.';
lang_es['UIMessages.Validation.QuestionAndAnswer'] = 'Seleccione una pregunta de seguridad y proporcione una respuesta.';
lang_es['UIMessages.Validation.RequestFailedAccountCancelled'] = 'Su cuenta ha expirado. Comuníquese con el administrador de su cuenta.';
lang_es['UIMessages.Validation.RequestFailedAccountExpired'] = 'Su cuenta ha expirado. Comuníquese con el administrador de su cuenta.';
lang_es['UIMessages.Validation.ResetPWInstructionsSent'] = 'Se enviarán instrucciones para terminar de restablecer la contraseña si los credenciales son válidas. Espere hasta diez minutos para que llegue el correo electrónico.';
lang_es['UIMessages.Validation.RoundToNearestIncrementInvalid'] = 'El valor del incremento de tiempo mínimo no era válido.';
lang_es['UIMessages.Validation.SMSNotValidated'] = 'No se pudo validar el número de SMS';
lang_es['UIMessages.Validation.SecurityQuestionUpdateRequired'] = 'Se requiere una actualización de la pregunta de seguridad.';
lang_es['UIMessages.Validation.SessionDurationInvalid'] = 'El valor de duración de la sesión no es válido';
lang_es['UIMessages.Validation.ShiftRestrictionInvalid'] = 'La restricción de turno no es válida.';
lang_es['UIMessages.Validation.StartDateAfterEndDate'] = 'La fecha de inicio es posterior a la fecha de finalización';
lang_es['UIMessages.Validation.StartDateInFuture'] = 'La fecha de inicio es una fecha futura';
lang_es['UIMessages.Validation.StartDateInvalid'] = 'La fecha de inicio inválido.';
lang_es['UIMessages.Validation.StartDateOlderThanAllowed'] = 'La fecha de inicio es anterior a la fecha permitida.';
lang_es['UIMessages.Validation.StartDateRequired'] = 'Se requiere una fecha de inicio';
lang_es['UIMessages.Validation.StartEndDateRequired'] = 'Se requieren una fecha de inicio y una de finalización';
lang_es['UIMessages.Validation.StartEndDatesExceedRange'] = 'Las fechas de inicio y finalización no pueden tener más de {1,number,#} días de diferencia.';
lang_es['UIMessages.Validation.StateOrProvince'] = 'Seleccione su estado o provincia.';
lang_es['UIMessages.Validation.StateOrProvinceRequired'] = 'El estado o provincia es obligatorio.';
lang_es['UIMessages.Validation.TimeCannotBeModifiedArchivedUser'] = 'El tiempo no se puede modificar para los usuarios archivados.';
lang_es['UIMessages.Validation.TimeRequired'] = 'Se requiere una hora válida.';
lang_es['UIMessages.Validation.TimeZoneCannotBeEdited'] = 'La zona horaria no se puede editar mientras el usuario tenga tiempo no pagado.';
lang_es['UIMessages.Validation.TimeZoneInvalid'] = 'La zona horaria no es válida.';
lang_es['UIMessages.Validation.TimeZoneRequired'] = 'Se requiere una zona horaria';
lang_es['UIMessages.Validation.Unsuccessful'] = 'No se pudo completar.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.100'] = 'Los permisos para importar usuarios no eran válidos.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.101'] = 'El archivo de importación no existe.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.102'] = 'El archivo de importación está corrupto.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.103'] = 'El archivo no tiene datos de usuario para importar.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.104'] = 'Las columnas de encabezado han sido alteradas. Verifique si el archivo tiene el formato adecuado.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.105'] = 'La columna en la posición {2} ha sido modificada. Verifique si el archivo tiene el formato adecuado.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.106'] = 'Al usuario de la línea {1,number,#} le faltaban columnas.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.107'] = 'No hay suficientes licencias disponibles para importar todos los usuarios.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.108'] = 'Se superará el número de usuarios importados a la vez.';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.200'] = 'Fila de usuario {1,number,#}: nombre no válido';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.201'] = 'Fila de usuario {1,number,#}: apellido no válido';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.202'] = 'Fila de usuario {1,number,#}: dirección de correo electrónico no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.203'] = 'Fila de usuario {1,number,#}: la dirección de correo electrónico ya se usó o ya está en el sistema';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.204'] = 'Fila de usuario {1,number,#}: clasificación de empleado no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.205'] = 'Fila de usuario {1,number,#}: tipo de pago no válido';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.206'] = 'Fila de usuario {1,number,#}: tarifa de pago por hora no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.207'] = 'Fila de usuario {1,number,#}: tarifa de pago salarial no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.208'] = 'Fila de usuario {1,number,#}: número de teléfono no válido';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.209'] = 'Fila de usuario {1,number,#}: fecha de contratación no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.210'] = 'Fila de usuario {1,number,#}: fecha de revisión no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.211'] = 'Fila de usuario {1,number,#}: fecha de nacimiento no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.212'] = 'Fila de usuario {1,number,#}: ID de supervisor no válido';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.213'] = 'Fila de usuario {1,number,#}: configuración de función no válida';
lang_es['UIMessages.Validation.UserImportFile.ErrorCode.300'] = 'Fila de usuario {1,number,#}: la creación del usuario falló';
lang_es['UIMessages.Validation.UserImportFile.ErrorXOnLine'] = 'ID de usuario {1,number,#} en la línea {2,number,#}: {3}';
lang_es['UIMessages.Validation.UserLoginDisabledByAdministrator'] = 'El ingreso a esta cuenta ha sido deshabilitado por un admonistrador.';
lang_es['UIMessages.Validation.UserNotFound'] = 'Usario no encontrada.';
lang_es['UIMessages.Validation.UserSelectionIsRequired'] = 'Se requiere seleccionar un usuario.';
lang_es['UIMessages.Validation.ValidAccountCodeIDRequiredForAction'] = 'Se requiere un ID de código de cuenta válido para esta acción.';
lang_es['UIMessages.Validation.ValidBillUnbillValueRequired'] = 'Se requiere un valor facturable/no facturable valido.';
lang_es['UIMessages.Validation.ValidCustomerIDRequiredForAction'] = 'Se requiere un ID de cliente válido para esta acción.';
lang_es['UIMessages.Validation.ValidIP'] = 'Ingrese una dirección IP válida.';
lang_es['UIMessages.Validation.ValidProjectIDRequiredForAction'] = 'Se requiere un ID de proyecto válido para esta acción.';
lang_es['UIMessages.Validation.ValidRecordIDRequired'] = 'Se requiere una identificación de registro válida.';
lang_es['UIMessages.Validation.ValidUserIDRequiredForAction'] = 'Se requiere un ID de usuario válido para esta acción.';
lang_es['UIMessages.Validation.ViolateTimeEntryCutoff'] = 'Esta acción no se puede completar porque violará el corte de ingreso de tiempo.';
lang_es['UIMessages.Validation.ViolateTimeFutureDates'] = 'Esta acción no se puede completar porque violará el tiempo de fechas futuras.';
lang_es['JavaScript.Account.CancelConfirmation'] = 'Está a punto de cancelar su cuenta de Timesheets.com. Está seguro que desea continuar?';
lang_es['JavaScript.Account.DeleteConfirmation'] = 'Está a punto de eliminar su cuenta de Timesheets.com. Estás seguro que desea continuar?';
lang_es['JavaScript.AccountIsBeingCreated'] = 'Se está creando la cuenta.';
lang_es['JavaScript.AccrualLabelChange'] = 'Está a punto de cambiar la etiqueta de una categoría de tiempo libre.\n\nEsto afectará todo el tiempo previamente guardado en esta categoría, incluido el tiempo de periodos de nómina anteriores.';
lang_es['JavaScript.AcknowledgeModifiedRecords'] = 'Esto va a validar y a limpiar los indicadores de registros recientemente modificados de los registros visibles.';
lang_es['JavaScript.AlertsAllowAndApproveSelectedRecords'] = 'Esto autorizará todos los registros seleccionados y los marcará como aprobados.';
lang_es['JavaScript.AlertsAllowSelectedRecords'] = 'Esto autorizará todos los registros seleccionados.';
lang_es['JavaScript.AlertsDeleteSelectedRecords'] = 'Esto eliminará todos los registros seleccionados.';
lang_es['JavaScript.AlertsDenyDeleteRequest'] = '¿Está seguro de que desea rechazar y eliminar esta solicitud?';
lang_es['JavaScript.AlertsDenyDeleteSelectedRecords'] = 'Esto negará y eliminará todos los registros seleccionados.';
lang_es['JavaScript.AlertsDenyRecordRemains'] = '¿Está seguro que desea rechazar esta solicitud? (El registro actual permanecerá como está).';
lang_es['JavaScript.AllAccrualBalancesWillBeUpdated'] = 'Se actualizarán todos los saldos acumulados de los usuarios. Asegúrese de que todos los montos acumulados sean correctos antes de continuar.';
lang_es['JavaScript.ArchiveThisRecord'] = '¿Está seguro que desea archivar este registro?';
lang_es['JavaScript.ArizonaNoDST'] = 'Arizona no utiliza el Horario de Verano.';
lang_es['JavaScript.Btn.CopyLink'] = 'Copiar Enlace';
lang_es['JavaScript.Btn.EmailLink'] = 'Enviar Enlace';
lang_es['JavaScript.Btn.No'] = 'No';
lang_es['JavaScript.Btn.NoThanks'] = 'No Gracias';
lang_es['JavaScript.Btn.RemindMeLater'] = 'Recordarme Después';
lang_es['JavaScript.Btn.Yes'] = 'Si';
lang_es['JavaScript.Camera.CameraUnavailable'] = 'La cámara parece no estar disponible.';
lang_es['JavaScript.Camera.ClockPhotoCameraProblem'] = 'Hay un problema con la cámara y el video. Si anteriormente no se permitió compartir videos, es posible que deba permitirse para este sitio web usando la configuración del navegador.';
lang_es['JavaScript.Camera.ConfirmSubmissionOfPhoto'] = '¿Estás seguro que desea enviar esta foto?';
lang_es['JavaScript.Camera.ProcessingClockPhoto'] = 'Procesando foto.';
lang_es['JavaScript.ChangesNotSaved'] = '¡No se han guardado los cambios!';
lang_es['JavaScript.ClearAllRecentlyModifiedIndicators'] = 'Limpiar indicadores de registros recientemente modificados de todos los registros.';
lang_es['JavaScript.ClockOutLink.EmailDisabled.Description'] = 'Esta cuenta no tiene una dirección de correo electrónico válida. Agregue una dirección de correo electrónico para habilitar esta función.';
lang_es['JavaScript.ClockOutLink.EmailDisabled.Title'] = 'Enlace de Marcar Salida por Correo Electrónico deshabilitado';
lang_es['JavaScript.ClockOutLink.FailedToSend.Description'] = 'No se pudo enviar el enlace de marcar salida. Esto podría deberse a que se habilitaron una o más funciones de seguridad que impiden los enlaces de marcar salida. Por favor marque su salida desde su cuenta.';
lang_es['JavaScript.ClockOutLink.FailedToSend.Title'] = 'No se pudo enviar el enlace de Marcar Salida';
lang_es['JavaScript.ClockOutLink.SMSDisabled.Description'] = 'Esta cuenta no tiene un número de teléfono SMS válido. Agregue un número de teléfono SMS para habilitar esta función.';
lang_es['JavaScript.ClockOutLink.SMSDisabled.Title'] = 'Enlace de Marcar Salida por SMS desactivado';
lang_es['JavaScript.ClockOutLink.SentSuccessfully.Description'] = 'El enlace de Marcar Salida se envió correctamente.';
lang_es['JavaScript.ClockOutLink.SentSuccessfully.Title'] = 'Enlace de Marcar Salida';
lang_es['JavaScript.CompanyWall'] = 'Muro de la Empresa';
lang_es['JavaScript.ContactSupport'] = 'Comuníquese con soporte para obtener asistencia.';
lang_es['JavaScript.ContinueAnyway'] = '¿Continuar de todos modos?';
lang_es['JavaScript.ContinueWithThisAction'] = '¿Continuar con esta acción?';
lang_es['JavaScript.ContinueWithoutSaving'] = '¿Continuar sin guardar?';
lang_es['JavaScript.Copied'] = 'Copiado';
lang_es['JavaScript.CopyCustomerFirst'] = 'Primero, copie el cliente.';
lang_es['JavaScript.Day.FRI'] = 'VIE';
lang_es['JavaScript.Day.FRIDAY'] = 'VIERNES';
lang_es['JavaScript.Day.MON'] = 'LUN';
lang_es['JavaScript.Day.MONDAY'] = 'LUNES';
lang_es['JavaScript.Day.SAT'] = 'SÁB';
lang_es['JavaScript.Day.SATURDAY'] = 'SÁBADO';
lang_es['JavaScript.Day.SUN'] = 'DOM';
lang_es['JavaScript.Day.SUNDAY'] = 'DOMINGO';
lang_es['JavaScript.Day.THU'] = 'JUE';
lang_es['JavaScript.Day.THURSDAY'] = 'JUEVES';
lang_es['JavaScript.Day.TUE'] = 'MAR';
lang_es['JavaScript.Day.TUESDAY'] = 'MARTES';
lang_es['JavaScript.Day.WED'] = 'MIÉ';
lang_es['JavaScript.Day.WEDNESDAY'] = 'MIÉRCOLES';
lang_es['JavaScript.DeletePost'] = '¿Borrar esta entrada?';
lang_es['JavaScript.DeleteQBOExport'] = 'La eliminación de exportaciones anteriores también elimina el historial de exportaciones y no se puede deshacer. Esta acción le permitirá importar los mismos datos más de una vez, lo que provocará entradas duplicadas en QuickBooks Online. En su lugar, se recomienda corregir los errores en QuickBooks Online y volver a intentar la importación hasta que todos los registros sean correctos.';
lang_es['JavaScript.DisconnectFromQBO'] = 'Esto desconectará su cuenta de QuickBooks Online.';
lang_es['JavaScript.ErrorOccured'] = 'Ocurrió un error.';
lang_es['JavaScript.ErrorSendingStatusReport'] = 'Error al enviar el reporte de estado. Vuelva a intentarlo o póngase en contacto con soporte.';
lang_es['JavaScript.FileBeingUploaded'] = 'Se está cargando el archivo. Por favor espere un momento.';
lang_es['JavaScript.FileDownload.EmailBody.FileAvailability'] = 'Los archivos están disponibles para descargar durante 30 días.';
lang_es['JavaScript.FileDownload.EmailBody.LinkToFile'] = 'Aqui está el enlace para descargar el archivo:';
lang_es['JavaScript.FileDownload.EmailSubject'] = 'Su Enlace de Descarga de Timesheets.com';
lang_es['JavaScript.FileError'] = 'Hubo un error descargando el archivo.';
lang_es['JavaScript.FileGeneratingPleaseBePatient'] = 'Generando el archivo. Por favor espere un momento.';
lang_es['JavaScript.FileIsBeingGenerated'] = 'Se está generando el archivo.';
lang_es['JavaScript.FileReadyForDownloadClickHere'] = 'Su archivo está listo para descargar. Por favor haga <strong>click aquí</strong>.';
lang_es['JavaScript.FutureRecords'] = 'Registros Futuros';
lang_es['JavaScript.GeneratingExportFile'] = 'Generando archivo de exportación...';
lang_es['JavaScript.Geolocation.Error.Addendum'] = 'Asegúrese que su navegador esté configurado para compartir la ubicación de este sitio y que su navegador y dispositivo permitan que el sitio acceda a las coordenadas GPS.';
lang_es['JavaScript.Geolocation.Error.LocationInfoUnavailable'] = 'La información de ubicación no está disponible.';
lang_es['JavaScript.Geolocation.Error.RequestTimedOut'] = 'Se agotó el tiempo de espera de la solicitud para obtener su ubicación.';
lang_es['JavaScript.Geolocation.Error.RequestToShareDenied'] = 'Se rechazó la solicitud para compartir la ubicación.';
lang_es['JavaScript.Geolocation.Error.UnknownError'] = 'Se ha producido un error de geolocalización desconocido.';
lang_es['JavaScript.Geolocation.GeocodeFailed'] = 'El geocódigo no se completó por las siguientes razones:';
lang_es['JavaScript.Geolocation.GeocodeFailedNoResults'] = 'El geocódigo no se completó correctamente. No se encontraron resultados.';
lang_es['JavaScript.Geolocation.Instructions.AccuracyIsPoor'] = 'La precisión de la geolocalización es baja.';
lang_es['JavaScript.Geolocation.Instructions.AccuracyRefinementClose'] = 'segundos.';
lang_es['JavaScript.Geolocation.Instructions.AccuracyRefinementOpen'] = 'El refinamiento de la precisión termina en';
lang_es['JavaScript.Geolocation.Instructions.AcquiringLocation'] = 'Adquiriendo Geolocalización';
lang_es['JavaScript.Geolocation.Instructions.ClickToCancel'] = 'Haga clic en Cancelar para cancelar esta Entrada/Salida.';
lang_es['JavaScript.Geolocation.Instructions.ClickToPerformWithoutGeo'] = 'Haga clic en Aceptar para marcar esta Entrada/Salida sin su geolocalización.';
lang_es['JavaScript.Geolocation.Instructions.ForFasterCapture'] = 'Para una geolocalización más rápida, configure su navegador para que &quot;Siempre&quot; se comparta su ubicación con este sitio.';
lang_es['JavaScript.Geolocation.Instructions.GeoTimeout'] = 'Si no ve un aviso para compartir su ubicación, o está esperando mucho tiempo sin un ícono de ubicación en su dispositivo, es posible que haya denegado el acceso a la ubicación para este sitio. Configure su navegador para permitir compartir la ubicación de este sitio, luego vuelva a cargar la página y vuelva a intentarlo.';
lang_es['JavaScript.Geolocation.Instructions.NoBrowserSupport'] = 'Su navegador no soporta la opción de geolocalización.';
lang_es['JavaScript.Geolocation.Instructions.ProceedAnyway'] = '¿Proceder de todas maneras?';
lang_es['JavaScript.Geolocation.Instructions.ReceivingData'] = 'Recibiendo datos de geolocalización ...';
lang_es['JavaScript.Geolocation.Instructions.WaitingForData'] = 'Esperando datos de geolocalización ...';
lang_es['JavaScript.HR.CreatingNewDocument'] = 'Creando nuevo documento...';
lang_es['JavaScript.HawaiiNoDST'] = 'Hawai no utiliza el Horario de Verano.';
lang_es['JavaScript.ImportItemsWait'] = 'Importando elementos.\n\nEsto puede tardar un minuto en completarse.\nPor favor espere un momento.';
lang_es['JavaScript.Information.AccessToSecurityMakesExempt'] = 'Tiene acceso a la configuración de seguridad, lo que lo hace exento.';
lang_es['JavaScript.Information.AccountIsExemptFromIPLock'] = 'Su cuenta está exenta de este bloqueo de IP.';
lang_es['JavaScript.Information.AddIPToWhitelist'] = 'Agregue esta dirección IP a la lista blanca de la empresa para que esté disponible para todos los usuarios.';
lang_es['JavaScript.Information.BooleanSearchFormatted'] = '<strong>{1} Búsqueda Booleana Completa</strong>\n\nLos siguientes ejemplos demuestran algunas cadenas de búsqueda que utilizan operadores booleanos de texto completo:\n<ul class=&quot;spaced&quot;>\n<li>Busque registros que contengan al menos una de las dos palabras:\n<strong>manzana banana</strong></li>\n<li>Busque registros que contengan ambas palabras:\n<strong><strong>+manzana +jugo</strong></strong></li>\n<li>Busque registros que contengan la palabra &quot;apple&quot; pero no &quot;macintosh&quot;:\n<strong> +apple -macintosh</strong></li>\n<li>Busque registros que contengan palabras como &quot;manzana&quot;, &quot;manzanas&quot;, &quot;puré de manzana&quot; o &quot;subprograma&quot;:<br ><strong>manzana*<strong></li>\n<li> Busque registros que contengan la frase exacta &quot;algunas palabras&quot;:<br /><strong>&quot;algunas palabras &quot;<strong></li></ul>';
lang_es['JavaScript.Information.ContactSuperToAddIPToWhitelist'] = 'Comuníquese con su supervisor o administrador para agregar la dirección IP de su ubicación al sistema.';
lang_es['JavaScript.Information.CustomNameReport'] = 'Este reporte se puede nombrar, para poder identificarlo y correrlo fácilmente desde los Reportes Generados Previamente, accesibles desde la página de Reportes. El reporte se mantendrá disponible durante cuatro horas, o hasta cuando usted cierre sesión, lo que ocurra primero.\n\nSolo se permiten letras, números, y los siguientes caracteres especiales: . _ - ( ) [ ] { }';
lang_es['JavaScript.Information.LikeTextSearchFormatted'] = '<strong>{1} Búsqueda Por Similitud</strong>\n\nLa búsqueda por similitud tratará de encontrar cualquier texto que contenga o que coincida con el texto buscado exactamente.\n\nVeamos algunos ejemplos:\n\nSi tenemos los siguientes registros:\n\n<ol><li>La ballena habita en el mar.</li><li>El delfín está en el mar, y le gusta tener compañía.</li><li>El mar está habitado de muchos animales; el delfín y la ballena son ejemplos.</li></ol>\n\nSi se busca el término &quot;en el mar&quot; el resultado es la primera y la segunda opción.\n\nSi se busca el término &quot;mar&quot; el resultado es la primera, la segunda y la tercera opción.\n\nSi se busca el término &quot;el mar&quot; el resultado es la primera, la segunda y la tercera opción.\n\nSi se busca el têrmino &quot;ballena&quot; el resultado es la primera y la tercera opción.';
lang_es['JavaScript.Information.ProjectDescriptionsNotViewableByEmps'] = 'En este momento las descripciones de los proyectos no son visibles para todos los usuarios cuando ingresan o editan el tiempo. Visite los Ajustes de Compañía para cambiar la configuración de permisos.';
lang_es['JavaScript.Information.ProjectDescriptionsViewableByEmps'] = 'Actualmente, todos los usuarios pueden ver las descripciones de los Proyectos cuando ingresan o editan el tiempo. Visite los Ajustes de Compañía para cambiar la configuración de permisos.';
lang_es['JavaScript.Information.RunInBackgroundInfo'] = 'Cuando se corre un reporte en segundo plano, puede continuar utilizando su cuenta mientras que el reporte se procesa. Usted recibirá una notificación cuando el reporte esté listo.';
lang_es['JavaScript.Information.SearchRecordDescriptions'] = 'Las notas de descripción de trabajo en los registros se pueden buscar con operadores booleanos de texto completo.';
lang_es['JavaScript.Information.TimeClassHasBeenDisabled'] = 'El Ingreso de tiempo Por Hora y Asalariado han sido deshabilitados por un administrador.';
lang_es['JavaScript.Information.TimeClassNotPermittedForLocation'] = 'El Ingreso de tiempo Por Hora y Asalariado no está permitido desde esta ubicación.';
lang_es['JavaScript.Information.TimeClassNotUsuallyPermitted'] = 'El Ingreso de tiempo Por Hora y Asalariado no es usualmente permitido desde esta ubicación. Sin embargo:';
lang_es['JavaScript.InsertGlobalEntry'] = 'Está a punto de guardar un ingreso de tiempo para los usuarios seleccionados.\nEsta acción solo se puede deshacer una hoja de horas de usuario a la vez.\n\nContinúe solo si está seguro de que la nueva entrada es completamente correcta.';
lang_es['JavaScript.InvalidCreditCard'] = 'Verifique el número de tarjeta de crédito. Debe tener 15 o 16 dígitos sin espacios.';
lang_es['JavaScript.Item.ArchiveAllItems'] = '¿Archivar todos los elementos?';
lang_es['JavaScript.Item.ArchiveAllItemsDetail'] = 'Los elementos de la lista se pueden archivar cuando no se utilizan en ningún registro abierto. Esta acción archivará todos los elementos de la lista elegibles en la cuenta. Para archivar elementos de lista específicos, use las casillas de verificación.';
lang_es['JavaScript.Item.DeleteAllItems'] = '¿Eliminar todos los elementos?';
lang_es['JavaScript.Item.DeleteAllItemsDetail'] = '<span style=\'color:red;font-weight:bold;font-size:18px\'>¡Atención!</span>\nEsta opción elimina TODOS los elementos de lista disponibles (o borrables). Para seleccionar elementos específicos, utilice el botón <span style=\'font-weight:bold\'>\'Eliminar Seleccionados\'</span> en la parte inferior de la lista.\n\nSólo elementos que nunca han sido utilizados pueden ser eliminados.';
lang_es['JavaScript.Item.RestoreAllItems'] = '¿Restaurar todos los elementos?';
lang_es['JavaScript.Item.RestoreAllItemsDetail'] = 'Esta acción restaurará todos los elementos de la lista archivados. Para restaurar elementos de lista específicos, use las casillas de verificación.';
lang_es['JavaScript.Label.Decrease'] = 'Disminuir';
lang_es['JavaScript.Label.Increase'] = 'Aumentar';
lang_es['JavaScript.LastUpdated'] = 'Última actualización';
lang_es['JavaScript.Link.LessEllipsis'] = 'menos...';
lang_es['JavaScript.Link.MoreEllipsis'] = 'más...';
lang_es['JavaScript.LoadingRecords'] = 'Cargando registros ...';
lang_es['JavaScript.LoadingRecordsForWeekStarting'] = 'Cargando registros de la semana que comienza el {1}';
lang_es['JavaScript.MergeItemsWarning'] = 'Esta acción fusionará los registros asignados de un elemento a otro. Esta acción no se puede deshacer.';
lang_es['JavaScript.MessageCenter.Admin.DeleteAccountAlertConfirm'] = 'Está intentando eliminar una alerta de cuenta personalizada. Esto eliminará la alerta para TODOS los usuarios a los que se envió antes de eliminar la alerta en sí. Por lo general, las alertas de cuentas personalizadas que se han enviado previamente no deben eliminarse a menos que exista una razón específica.';
lang_es['JavaScript.MessageCenter.Admin.DeleteSentAccountAlertConfirm'] = 'Esto eliminará permanentemente este mensaje de todos los usuarios a los que se envió.';
lang_es['JavaScript.MessageCenter.Admin.SendAccountAlertConfirm'] = 'Esto enviará la alerta seleccionada a todos los usuarios seleccionados desde el envío seleccionado. Proceda con precaución.';
lang_es['JavaScript.MessageCenter.Admin.SendUserAccountAlertConfirm'] = 'Está a punto de enviar un mensaje directamente a un usuario como una alerta de cuenta no leída. Este mensaje no se puede eliminar ni deshacer una vez que se envía. Proceda con precaución al enviar alertas directamente a los usuarios.';
lang_es['JavaScript.NewUpdates'] = '¡Nuevas actualizaciones!';
lang_es['JavaScript.NoChangesFound'] = 'No se encontraron ediciones ni cambios. Realice una edición para enviar el formulario.';
lang_es['JavaScript.NoMatches'] = 'No hay coincidencias...';
lang_es['JavaScript.NotifySupervisorOfPayrollClose'] = 'Esto notificará a su supervisor directo que ha cerrado un periodo de nómina.';
lang_es['JavaScript.NotifySupervisorOfRecordsApproved'] = 'Esto le notificará a su supervisor directo que tiene registros aprobados.';
lang_es['JavaScript.NotifySupervisorOfRecordsArchived'] = 'Esto notificará a su supervisor directo que ha archivado los registros.';
lang_es['JavaScript.NotifySupervisorOfRecordsOpened'] = 'Esto notificará a su supervisor directo que ha abierto registros.';
lang_es['JavaScript.NotifySupervisorOfRecordsReconciled'] = 'Esto le notificará a su supervisor directo que ha conciliado los registros.';
lang_es['JavaScript.NotifySupervisorTimesheetIsReady'] = 'Esto enviará un mensaje a su supervisor directo para informarle que su hoja de horas está lista.';
lang_es['JavaScript.Or'] = 'o';
lang_es['JavaScript.Payroll.ArchivedUsersWillBeRecactivated'] = 'Todos los usuarios archivados en el reporte de nómina se reactivarán.';
lang_es['JavaScript.Payroll.RecordsWillBeReturnedToTimesheet'] = 'Todos los registros se regresarán a la hoja de horas del usuario.';
lang_es['JavaScript.Payroll.ReopeningPayrollPeriod'] = 'Reabriendo periodo de nómina ...';
lang_es['JavaScript.Payroll.ReopeningWillDeleteReport'] = 'Reabrir este periodo de nómina eliminará el reporte cerrado.';
lang_es['JavaScript.PermanentlyDeleteRecordHistoryWarning'] = 'Esto va a borrar el historial de los registros seleccionados permanentemente. \n\nProceda con precaución.';
lang_es['JavaScript.PleaseTryAgain'] = 'Vuelva a intentarlo o comuníquese con atención al cliente si aún tiene problemas.';
lang_es['JavaScript.ProblemCommunicatingWithServer'] = '¡Problema al comunicarse con el servidor!';
lang_es['JavaScript.ProcessingPleaseBePatient'] = 'Procesando... Por favor espere un momento.';
lang_es['JavaScript.ProcessingPleaseWait'] = 'Procesando. Espere un momento...';
lang_es['JavaScript.RecordEditsNotSaved'] = 'Las ediciones del registro no se han guardado.';
lang_es['JavaScript.RecordNotSaved'] = 'El registro no se ha guardado.';
lang_es['JavaScript.RefreshingFileList'] = 'Actualizando la lista de archivos ...';
lang_es['JavaScript.ReportIsBeingGenerated'] = 'Se está generando el reporte.';
lang_es['JavaScript.Saving'] = 'Guardando...';
lang_es['JavaScript.SavingExpenseEntry'] = 'Guardando ingreso de gastos...';
lang_es['JavaScript.SelectFileFormat'] = 'Seleccionar Formato de Archivo';
lang_es['JavaScript.SelectedRecordWillBeDeleted'] = 'Se eliminarán todos los registros seleccionados. Esta acción es permanente y no se puede deshacer.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsApproved'] = 'Todos los registros seleccionados se marcarán como aprobados.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsArchived'] = 'Todos los registros seleccionados se marcarán como archivados.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsBillable'] = 'Todos los registros seleccionados se marcarán como facturables.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsOpen'] = 'Todos los registros seleccionados se marcarán como abiertos.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsReconciled'] = 'Todos los registros seleccionados se marcarán como conciliados.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsUnapproved'] = 'Todos los registros seleccionados se marcarán como no aprobados.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsUnarchived'] = 'Todos los registros seleccionados se marcarán como no archivados.';
lang_es['JavaScript.SelectedRecordWillBeMarkedAsUnbillable'] = 'Todos los registros seleccionados se marcarán como no facturables.';
lang_es['JavaScript.SelectionArchived'] = 'Este registro se puede guardar con esta selección, pero esta selección se ha archivado y ya no es válida para su uso en registros nuevos.';
lang_es['JavaScript.SelectionNotValid'] = 'Este registro se puede guardar con esta selección, pero esta selección ya no es válida según los permisos de acceso de los usuarios.';
lang_es['JavaScript.Session.SessionAboutToExpire'] = 'Su sesión está a punto de caducar.';
lang_es['JavaScript.Session.SessionExpiringTitle'] = 'Sesión Expirando...';
lang_es['JavaScript.Session.SessionHasExpired'] = 'Su sesión ha caducado. Ha sido desconectado.';
lang_es['JavaScript.Support.GuidedTourIconDesc'] = 'Utilice el {1} para un tutorial con guía.';
lang_es['JavaScript.Support.HelpfulInformation'] = 'Puede encontrar información útil en cualquier momento en la esquina superior derecha en la mayoría de las páginas.';
lang_es['JavaScript.Support.PageSupportIconDesc'] = 'Utilice el {1} para ver las páginas de soporte.';
lang_es['JavaScript.Support.WouldYouLikeQuickTourOfAccount'] = '¿Le gustaría ver un tour rápido de su cuenta antes de empezar?';
lang_es['JavaScript.Timer.Pausing'] = 'Pausando el temporizador...';
lang_es['JavaScript.Timer.PausingAll'] = 'Pausando todos los temporizadores...';
lang_es['JavaScript.Timer.Restarting'] = 'Reiniciando el temporizador...';
lang_es['JavaScript.Timer.RestartingAll'] = 'Reiniciando todos los temporizadores...';
lang_es['JavaScript.Timer.Starting'] = 'Iniciando temporizador...';
lang_es['JavaScript.Timer.StartingAll'] = 'Iniciando todos los temporizadores...';
lang_es['JavaScript.Timer.Stopping'] = 'Deteniendo temporizador...';
lang_es['JavaScript.Timer.StoppingAll'] = 'Deteniendo todos los temporizadores...';
lang_es['JavaScript.Title.Attention'] = 'Atención';
lang_es['JavaScript.Title.ConfirmationRequired'] = 'Confirmación Requerida';
lang_es['JavaScript.Title.FileError'] = 'Error de Archivo';
lang_es['JavaScript.Title.FileReady'] = 'Archivo Listo';
lang_es['JavaScript.Title.Geolocation'] = 'Geolocalización';
lang_es['JavaScript.Title.GeolocationNotSupported'] = 'Geolocalización no soportada';
lang_es['JavaScript.Title.Information'] = 'Información';
lang_es['JavaScript.Title.MakeASelection'] = 'Has una elección';
lang_es['JavaScript.Title.MileageFormat'] = 'Formato de Millaje';
lang_es['JavaScript.Title.PageOrientation'] = 'Orientación de página';
lang_es['JavaScript.Title.ProTip'] = '¡Consejo profesional!';
lang_es['JavaScript.Title.ProcessingPleaseBePatient'] = 'Procesando... Por favor espere un momento.';
lang_es['JavaScript.Title.ProcessingPleaseWait'] = 'Procesando. Por favor espere...';
lang_es['JavaScript.Title.ThisIsTheDashboard'] = 'Este es el Panel';
lang_es['JavaScript.Title.Unsupported'] = 'No Soportado';
lang_es['JavaScript.ToolTip.TableSortLabel'] = 'Clasificación de Tabla';
lang_es['JavaScript.ToolTip.TableSortListOne'] = 'Haga clic en el título de una de las columnas para ordenar todas las tablas en esta página. Si solo quiere ordenar una tabla, haga clic en el ícono de usuario en la esquina superior derecha > Opciones de Visualización > Ordenar Tabla > Individual.';
lang_es['JavaScript.ToolTip.TableSortListTwo'] = 'Para ordenar más de una columna a la vez, haga clic en la primera columna que quiere ordenar, deje la tecla Shift presionada, luego haga clic en la siguiente columna.';
lang_es['JavaScript.TryAgain'] = 'Inténtelo de nuevo.';
lang_es['JavaScript.UnarchiveThisRecord'] = '¿Está seguro de que desea desarchivar este registro?';
lang_es['JavaScript.UpdateItem'] = 'La actualización del nombre o la descripción del artículo actualizará todos los registros que han utilizado este artículo.';
lang_es['JavaScript.UpdateItemRate'] = 'Si actualiza la tasa predeterminada, los registros existentes no se actualizarán a la nueva tasa.';
lang_es['JavaScript.Updating'] = 'Actualizando...';
lang_es['JavaScript.UsePrinterIconError'] = 'Utilice el icono de impresora para imprimir horarios.';
lang_es['JavaScript.UserPhoto.DeleteThisPhoto'] = '¿Borrar esta foto?';
lang_es['JavaScript.UserPhoto.PhotoCameraProblem'] = 'Hay un problema con la cámara y el video. Si anteriormente no se permitió compartir videos, es posible que deba permitirse para este sitio web desde la configuración del navegador.';
lang_es['JavaScript.UserPhoto.ProblemDeletingPhoto'] = 'Hubo un problema al eliminar la foto. Vuelva a intentarlo o póngase en contacto con nosotros para obtener ayuda.';
lang_es['JavaScript.UserPhoto.ProblemUploadingPhoto'] = 'Hubo un problema al cargar la foto. Vuelva a intentarlo o póngase en contacto con nosotros para obtener ayuda.';
lang_es['JavaScript.UserPhoto.UseThisPhoto'] = '¿Usar esta foto?';
lang_es['JavaScript.Validation.ErrorValidatingNumber'] = 'Error al intentar validar el número de teléfono. Vuelva a intentarlo o póngase en contacto con atención al cliente.';
lang_es['JavaScript.Validation.HoursMustBeBetween0and24'] = 'Las horas deben estar entre 0 y 24.';
lang_es['JavaScript.Validation.HoursMustBeBetween1and12'] = 'Las horas deben ser entre 1 y 12.';
lang_es['JavaScript.Validation.HoursMustBeNumeric'] = 'Las horas deben ser numéricas.';
lang_es['JavaScript.Validation.MileageFormat'] = 'El millaje se debe ingresar solo como números enteros.';
lang_es['JavaScript.Validation.MinutesMustBeBetween0and59'] = 'Los minutos deben estar entre 0 y 59.';
lang_es['JavaScript.Validation.MinutesMustBeNumeric'] = 'Los minutos deben ser numéricos.';
lang_es['JavaScript.Validation.NoPermissionForThisRequest'] = 'No cuenta con permisos para esta solicitud.';
lang_es['JavaScript.Validation.PhoneIsNotValid'] = 'El número de teléfono NO es válido.';
lang_es['JavaScript.Validation.PhoneIsValid'] = 'El numero es válido.';
lang_es['JavaScript.Validation.ValidEarlierStartTime'] = 'Seleccione una hora de Finalización de Registro Anterior válida.';
lang_es['JavaScript.Validation.ValidLaterStartTime'] = 'Seleccione una hora de Inicio de Registro Posterior válida.';
lang_es['JavaScript.Validation.ValueHasBeenReset'] = 'El valor se ha restablecido.';
lang_es['JavaScript.XMinuteIncrements'] = 'Incrementos de {1} minutos';
lang_es['Calendar.Action.add'] = 'Agregar Evento';
lang_es['Calendar.Action.allCalendars'] = 'Seleccionar Todos';
lang_es['Calendar.Action.cancel'] = 'Cancelar';
lang_es['Calendar.Action.click'] = 'Clic';
lang_es['Calendar.Action.close'] = 'Cerrar';
lang_es['Calendar.Action.copy'] = 'Copiar';
lang_es['Calendar.Action.copyTo'] = 'Copiar A';
lang_es['Calendar.Action.copyToDate'] = 'Copiar hasta la fecha';
lang_es['Calendar.Action.delete'] = 'Borrar';
lang_es['Calendar.Action.edit'] = 'Editar';
lang_es['Calendar.Action.login'] = 'Acceder';
lang_es['Calendar.Action.moveTo'] = 'Mover A';
lang_es['Calendar.Action.moveToDate'] = 'Mover a la fecha';
lang_es['Calendar.Action.paste'] = 'Pegar';
lang_es['Calendar.Action.publish'] = 'Publicar';
lang_es['Calendar.Action.refresh'] = 'Refrescar';
lang_es['Calendar.Action.reset'] = 'Reiniciar';
lang_es['Calendar.Action.save'] = 'Guardar';
lang_es['Calendar.Action.selectFullWeek'] = 'Seleccionar Semana';
lang_es['Calendar.Action.unpublish'] = 'Despublicar';
lang_es['Calendar.Action.update'] = 'Actualizar';
lang_es['Calendar.CalendarMetaData.Birthdays.AlternateName'] = 'Cumpleaños';
lang_es['Calendar.CalendarMetaData.Birthdays.Description'] = 'Un calendario con todos los cumpleaños de los usuarios';
lang_es['Calendar.CalendarMetaData.Birthdays.Name'] = 'Cumpleaños';
lang_es['Calendar.CalendarMetaData.HireDates.AlternateName'] = 'Contrataciones y Aniversarios';
lang_es['Calendar.CalendarMetaData.HireDates.Description'] = 'Un calendario con todas las fechas de contratación y aniversarios de los usuarios';
lang_es['Calendar.CalendarMetaData.HireDates.Name'] = 'Contrataciones y Aniversarios';
lang_es['Calendar.CalendarMetaData.PayrollPeriods.AlternateName'] = 'Periodos de Nómina';
lang_es['Calendar.CalendarMetaData.PayrollPeriods.Description'] = 'Un calendario con las fechas de los periodos de nómina';
lang_es['Calendar.CalendarMetaData.PayrollPeriods.Name'] = 'Periodos de Nómina';
lang_es['Calendar.CalendarMetaData.ReviewDates.AlternateName'] = 'Evaluaciones Anuales';
lang_es['Calendar.CalendarMetaData.ReviewDates.Description'] = 'Un calendario con las fechas de las evaluaciones anuales de los usuarios';
lang_es['Calendar.CalendarMetaData.ReviewDates.Name'] = 'Evaluaciones Anuales';
lang_es['Calendar.CalendarMetaData.Terminations.AlternateName'] = 'Terminaciones';
lang_es['Calendar.CalendarMetaData.Terminations.Description'] = 'Un calendario con las fechas de terminación de contrato de los usuarios archivados';
lang_es['Calendar.CalendarMetaData.Terminations.Name'] = 'Terminaciones';
lang_es['Calendar.CalendarMetaData.USHolidays.AlternateName'] = 'Días Feriados de EEUU';
lang_es['Calendar.CalendarMetaData.USHolidays.Description'] = 'Un calendario con todos los días feriados de EEUU oficiales';
lang_es['Calendar.CalendarMetaData.USHolidays.Name'] = 'Días Feriados de EEUU';
lang_es['Calendar.Header.attention'] = 'Atención';
lang_es['Calendar.Header.copyHeader'] = 'Copiar eventos seleccionados:';
lang_es['Calendar.Header.delete'] = 'Eliminar eventos seleccionados:';
lang_es['Calendar.Header.noneSelected'] = 'No hay eventos seleccionados';
lang_es['Calendar.Header.pleaseWait'] = 'Por favor espere...';
lang_es['Calendar.Header.publish'] = 'Publicar eventos seleccionados:';
lang_es['Calendar.Header.targetDate'] = 'Fecha Objetivo:';
lang_es['Calendar.Header.unpublish'] = 'Despublicar eventos seleccionados:';
lang_es['Calendar.Label.Birthday'] = ' (Cumpleaños)';
lang_es['Calendar.Label.Day'] = 'Día';
lang_es['Calendar.Label.DragItemsToSelect'] = 'Arrastre para Seleccionar';
lang_es['Calendar.Label.DragToSelectEnabled'] = 'Arrastre Para Seleccionar Habilitados';
lang_es['Calendar.Label.EmployeeReview'] = ' (Evaluación de Empleado)';
lang_es['Calendar.Label.ExportEvents'] = 'Exportar Eventos';
lang_es['Calendar.Label.Hired'] = ' (Contratado)';
lang_es['Calendar.Label.Info'] = 'Información';
lang_es['Calendar.Label.Landscape'] = 'Paisaje';
lang_es['Calendar.Label.Loading'] = 'Cargando...';
lang_es['Calendar.Label.LoadingComplete'] = 'Todos los Eventos Desplegados';
lang_es['Calendar.Label.NoResultsFound'] = 'No se han encontrado resultados';
lang_es['Calendar.Label.Payroll'] = ' (Nómina)';
lang_es['Calendar.Label.PayrollEnd'] = 'Fecha de Finalización de la Nómina';
lang_es['Calendar.Label.PayrollStart'] = 'Fecha de Inicio de la Nómina';
lang_es['Calendar.Label.Portrait'] = 'Retrato';
lang_es['Calendar.Label.Processing'] = 'Procesando';
lang_es['Calendar.Label.SortBySupervisor'] = 'Agrupar por Supervisor';
lang_es['Calendar.Label.StartOn'] = 'Inicio';
lang_es['Calendar.Label.Termination'] = ' (Terminación)';
lang_es['Calendar.Label.ThisDay'] = 'Día Actual';
lang_es['Calendar.Label.USHoliday'] = ' (Día Feriado en EEUU)';
lang_es['Calendar.Label.addEvent'] = 'Calendarios';
lang_es['Calendar.Label.allDay'] = 'Todo El Día';
lang_es['Calendar.Label.copyFrom'] = 'Copiar desde:';
lang_es['Calendar.Label.details'] = 'Detalles';
lang_es['Calendar.Label.ends'] = 'Termina';
lang_es['Calendar.Label.exitDarkModeBeforePrinting'] = 'Salga del modo oscuro para imprimir.';
lang_es['Calendar.Label.filterBy'] = 'Filtrar Por';
lang_es['Calendar.Label.month'] = 'Mes';
lang_es['Calendar.Label.moreEvents'] = 'más eventos';
lang_es['Calendar.Label.noFilter'] = 'Sin Filtro';
lang_es['Calendar.Label.printMonth'] = 'Imprimir Mes';
lang_es['Calendar.Label.printSchedule'] = 'Imprimir Calendario';
lang_es['Calendar.Label.printWeek'] = 'Imprimir Semana';
lang_es['Calendar.Label.published'] = 'Publicado';
lang_es['Calendar.Label.requested'] = 'Solicitado';
lang_es['Calendar.Label.schedule'] = 'Calendario';
lang_es['Calendar.Label.search'] = 'Buscar';
lang_es['Calendar.Label.selectCalendar'] = 'Seleccionar Calendario';
lang_es['Calendar.Label.showDescription'] = 'Mostrar Descripción';
lang_es['Calendar.Label.showNames'] = 'Mostrar Nombres';
lang_es['Calendar.Label.starts'] = 'Inicia';
lang_es['Calendar.Label.thisMonth'] = 'Este Mes';
lang_es['Calendar.Label.thisWeek'] = 'Esta Semana';
lang_es['Calendar.Label.title'] = 'Título';
lang_es['Calendar.Label.updating'] = 'Actualizando...';
lang_es['Calendar.Label.week'] = 'Semana';
lang_es['Calendar.Legend.allDay'] = 'Todo El Día';
lang_es['Calendar.Legend.legendTitle'] = 'Leyenda';
lang_es['Calendar.Legend.multiDay'] = 'Múltiples Días';
lang_es['Calendar.Legend.ptoRequests'] = 'Solicitudes de Tiempo Libre';
lang_es['Calendar.Legend.published'] = 'Publicado';
lang_es['Calendar.Legend.publishedAvailable'] = 'Publicado Disponible';
lang_es['Calendar.Legend.publishedUnavailable'] = 'Publicado No Disponible';
lang_es['Calendar.Legend.timeOff'] = 'Tiempo Libre (todas las categorías)';
lang_es['Calendar.Legend.timeOffLegend'] = 'Tiempo Libre';
lang_es['Calendar.Legend.timeOffOrUnavail'] = 'Tiempo Libre o No Disponible';
lang_es['Calendar.Legend.title'] = 'Leyenda';
lang_es['Calendar.Legend.unavailable'] = 'No Disponible';
lang_es['Calendar.Legend.unpublished'] = 'No Publicado';
lang_es['Calendar.Legend.unpublishedAvailable'] = 'No Publicado Disponible';
lang_es['Calendar.Legend.unpublishedUnavailable'] = 'No Publicado No Disponible';
lang_es['Calendar.Menu.title'] = 'Menú';
lang_es['Calendar.Msg.AddPeopleAndCalendarsToSchedule'] = 'Clic para añadir usuarios y calendarios a los horarios';
lang_es['Calendar.Msg.CalendarExportReady'] = 'Exportación de Calendario Lista';
lang_es['Calendar.Msg.NoEventsToExport'] = 'No hay eventos para exportar.';
lang_es['Calendar.Msg.PleaseTryAgain'] = 'Por favor intente nuevamente';
lang_es['Calendar.Msg.UpdateFailed'] = 'Error en la Actualización:';
lang_es['Calendar.Msg.bulkCopyError'] = 'los eventos no se pudieron copiar.';
lang_es['Calendar.Msg.bulkCopySuccess'] = 'los eventos se copiaron con éxito.';
lang_es['Calendar.Msg.bulkDeleteError'] = 'los eventos no se pudieron eliminar.';
lang_es['Calendar.Msg.bulkDeleteSuccess'] = 'eventos eliminados con éxito.';
lang_es['Calendar.Msg.bulkPublishError'] = 'los eventos no se pudieron publicar.';
lang_es['Calendar.Msg.bulkPublishSuccess'] = 'eventos publicados con éxito.';
lang_es['Calendar.Msg.bulkUnpublishError'] = 'los eventos no se pudieron cancelar.';
lang_es['Calendar.Msg.bulkUnpublishSuccess'] = 'eventos despublicados con éxito.';
lang_es['Calendar.Msg.calendarsSelected'] = 'calendarios seleccionados';
lang_es['Calendar.Msg.confirmWait'] = 'Esto podría tomar varios minutos. ¿Desea continuar?';
lang_es['Calendar.Msg.continueQuestion'] = '¿Le gustaría continuar?';
lang_es['Calendar.Msg.copySuccess'] = 'evento copiado con éxito.';
lang_es['Calendar.Msg.copyingEvents'] = 'Copiando eventos';
lang_es['Calendar.Msg.deleteError'] = 'el evento no se pudo eliminar.';
lang_es['Calendar.Msg.deleteSuccess'] = 'evento eliminado con éxito.';
lang_es['Calendar.Msg.deletingEvents'] = 'Eliminando eventos';
lang_es['Calendar.Msg.duplicateEventsNotAllowed'] = 'No se permiten eventos duplicados el mismo día.';
lang_es['Calendar.Msg.error'] = 'Error';
lang_es['Calendar.Msg.eventsCannotBeCopiedAcrossCalendars'] = 'Los eventos no se pueden copiar entre calendarios';
lang_es['Calendar.Msg.eventsCopied'] = 'Eventos copiados';
lang_es['Calendar.Msg.eventsDeleted'] = 'Eventos borrados';
lang_es['Calendar.Msg.eventsFailedCopy'] = 'Eventos fallaron en copiarse';
lang_es['Calendar.Msg.eventsFailedDelete'] = 'Eventos fallaron en borrarse';
lang_es['Calendar.Msg.eventsFailedPublish'] = 'Eventos fallaron en publicarse';
lang_es['Calendar.Msg.eventsFailedUnpublish'] = 'Eventos fallaron en despublicarse';
lang_es['Calendar.Msg.eventsPublished'] = 'Eventos publicados';
lang_es['Calendar.Msg.eventsToCopy'] = 'Eventos a copiar';
lang_es['Calendar.Msg.eventsToDelete'] = 'Eventos a borrar';
lang_es['Calendar.Msg.eventsToPublish'] = 'Eventos a publicar';
lang_es['Calendar.Msg.eventsToUnpublish'] = 'Eventos a despublicar';
lang_es['Calendar.Msg.eventsUnpublished'] = 'Eventos despublicados';
lang_es['Calendar.Msg.gettingAllCalendaras'] = 'Cargando todos los calendarios';
lang_es['Calendar.Msg.gettingAllEvents'] = 'Cargando todos los eventos';
lang_es['Calendar.Msg.noEditableCalendarsSelected'] = 'No hay calendarios editables seleccionados.';
lang_es['Calendar.Msg.noEventsForPeriod'] = 'No hay eventos para mostrar para este periodo de fechas.';
lang_es['Calendar.Msg.okay'] = 'Ok';
lang_es['Calendar.Msg.publishSuccess'] = 'evento publicado con éxito.';
lang_es['Calendar.Msg.publishingEvents'] = 'Publicando eventos';
lang_es['Calendar.Msg.refreshToLoginAgain'] = 'Refresque la página para volver a iniciar sesión.';
lang_es['Calendar.Msg.selectTargetDate'] = 'Seleccione la fecha destino a la que desea copiar';
lang_es['Calendar.Msg.sessionTimedCount'] = 'Su sesión ha expirado.';
lang_es['Calendar.Msg.severalMinutes'] = 'Esto podría tomar varios minutos.';
lang_es['Calendar.Msg.somethingWrongTryAgain'] = 'Algo salió mal.<br/>Por favor, inténtelo de nuevo.';
lang_es['Calendar.Msg.success'] = 'Completado';
lang_es['Calendar.Msg.titleRequiredForAllDayEvents'] = 'Requerido para eventos de todo el día.';
lang_es['Calendar.Msg.unpublishSuccess'] = 'evento despublicado con éxito.';
lang_es['Calendar.Msg.unpublishingEvents'] = 'Despublicando eventos';
lang_es['Calendar.Validation.ApprovalError'] = 'Error de Aprobación:';
lang_es['Calendar.Validation.CopiedEventOverlapsExistingEvent'] = 'El evento copiado se superpone con un evento existente el día:';
lang_es['Calendar.Validation.duplicateEventMsg'] = 'No se permiten eventos duplicados en el mismo día.';
lang_es['Calendar.Validation.endDateRequired'] = 'La fecha de finalización es obligatoria';
lang_es['Calendar.Validation.endDateTimeRequired'] = 'La fecha/hora de finalización es obligatoria';
lang_es['Calendar.Validation.startDateRequired'] = 'La fecha de inicio es obligatoria';
lang_es['Calendar.Validation.startDateTimeRequired'] = 'Se requiere fecha/hora de inicio';
